import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';

class BatteryLogSettings extends React.Component {

	constructor(props) {

		super(props);

		this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
		this.handleDataIntervalChange = this.handleDataIntervalChange.bind(this);
		this.renderCheckbox = this.renderCheckbox.bind(this);
	}
	
	handleCheckboxClick(variable, event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.props.setBatteryLogSettingsValue(variable, value);
	}
	
	handleDataIntervalChange(event) {
		const target = event.target;
		const value = parseInt(target.value);
		this.props.setBatteryLogSettingsValue("dataInterval", value);
	}

	renderCheckbox(title, variable, value) {
		return (
			<div className="BatteryLogSettingsContainer2">
				<input 
					type="checkbox" 
					className="BatteryLogSettingsCheckbox" 
					id={variable} 
					name={variable} 
					checked={value}
					onChange={(event) => this.handleCheckboxClick(variable, event)}
				/>
				<label htmlFor={variable} className="BatteryLogSettingsText">{title}</label>
			</div>
		)
	}
	
	render() {

		var operation = this.props.operation;

		var totalVoltageText = this.props.getLang(BatteryLang, "totalVoltage");
		var currentText = this.props.getLang(BatteryLang, "current");
		var socStateOfChargeText = this.props.getLang(BatteryLang, "socStateOfCharge");
		var residualCapacityText = this.props.getLang(BatteryLang, "residualCapacity");
		var minCellVoltageText = this.props.getLang(BatteryLang, "minCellVoltage");
		
		var showDataIntervalYN = false;
		if (operation === "discharge") {
			showDataIntervalYN = true;
		}
		
		return (
			<div className="BatteryLogSettingsContainer1">
				<div className="BatteryLogSettingsContainer2">&nbsp;</div>
				{showDataIntervalYN && (
					<div className="BatteryLogSettingsContainer2">
						<label className="BatteryLogSettingsText">{this.props.getLang(BatteryLang, "dataInterval")}: </label>
						<div className="BatteryLogSettingsContainer2">&nbsp;&nbsp;</div>
						<select name="dataInterval" value={this.props.dataInterval} onChange={this.handleDataIntervalChange} className="BatteryLogSettingsSelect">
						  <option value="10">10 sec</option>
						  <option value="30">30 sec</option>
						  <option value="60">60 sec</option>
						</select>
					</div>
				)}

				{this.renderCheckbox(totalVoltageText, "batteryTotalVoltageYN", this.props.batteryTotalVoltageYN)}
				{this.renderCheckbox(currentText, "batteryCurrentYN", this.props.batteryCurrentYN)}
				{this.renderCheckbox(socStateOfChargeText, "batterySocYN", this.props.batterySocYN)}
				{this.renderCheckbox(residualCapacityText, "batteryResidualCapacityYN", this.props.batteryResidualCapacityYN)}
				{this.renderCheckbox(minCellVoltageText, "batteryMinCellVoltageValueYN", this.props.batteryMinCellVoltageValueYN)}
				{this.renderCheckbox("Temp 1", "batteryTemp1YN", this.props.batteryTemp1YN)}
				{this.renderCheckbox("Temp 2", "batteryTemp2YN", this.props.batteryTemp2YN)}
				{this.renderCheckbox("Temp 3", "batteryTemp3YN", this.props.batteryTemp3YN)}
				{this.renderCheckbox("Temp 4", "batteryTemp4YN", this.props.batteryTemp4YN)}
				{this.renderCheckbox("Temp 5", "batteryTemp5YN", this.props.batteryTemp5YN)}
				{this.renderCheckbox("Temp 6", "batteryTemp6YN", this.props.batteryTemp6YN)}
				{this.renderCheckbox("Temp Max", "batteryTempMaxYN", this.props.batteryTempMaxYN)}
			</div>
		);
	}
}

export default BatteryLogSettings;

