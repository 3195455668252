import React  from 'react';
import './App.css';
import './Ride.css';
import RideLang from './RideLang.json';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import RideChartCustomTooltip from './RideChartCustomTooltip.js';

class RideChart extends React.Component {

	constructor(props) {
		
		super(props);
		
		this.state = {
			width: 0,
			height: 0,
		}

		this.updateDimensions = this.updateDimensions.bind(this);
		this.getMaxValue = this.getMaxValue.bind(this);
		this.getData = this.getData.bind(this);
		this.renderLine = this.renderLine.bind(this);
	}
	
	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};
	
	getMaxValue(dataArray, count) {

		var i;
		var value;
		var maxValue = 0;

		for(i=0; i<count; i++) {
			value = dataArray[i];
			if (value > maxValue) {
				maxValue = value;
			}
		}

		return maxValue;
	}

	getData() {
		
		var i;
		var dataCountCurrent;
		var data = [];
		var oneRow;
		var sensorThrottleMax;
		var throttleMax;
		var escTempMax;
		var escCurrentMax;
		var batteryPercentageMax;
		var batteryVoltageMax;
		var batteryCurrentMax;
		var minCellVoltageMax;
		var bmsTempMax;
		var gpsSpeedMax;
		var whPerKmMax;
		var labelText;

		dataCountCurrent = this.props.dataCountCurrent;

		sensorThrottleMax = this.getMaxValue(this.props.sensorThrottleArrayCurrent, dataCountCurrent);
		throttleMax = this.getMaxValue(this.props.throttleArrayCurrent, dataCountCurrent);
		escTempMax = this.getMaxValue(this.props.escTempArrayCurrent, dataCountCurrent);
		escCurrentMax = this.getMaxValue(this.props.escCurrentArrayCurrent, dataCountCurrent);
		batteryPercentageMax = this.getMaxValue(this.props.batteryPercentageArrayCurrent, dataCountCurrent);
		batteryVoltageMax = this.getMaxValue(this.props.batteryVoltageArrayCurrent, dataCountCurrent);
		batteryCurrentMax = this.getMaxValue(this.props.batteryCurrentArrayCurrent, dataCountCurrent);
		minCellVoltageMax = this.getMaxValue(this.props.minCellVoltageArrayCurrent, dataCountCurrent);
		bmsTempMax = this.getMaxValue(this.props.bmsTempArrayCurrent, dataCountCurrent);
		gpsSpeedMax = this.getMaxValue(this.props.gpsSpeedArrayCurrent, dataCountCurrent);
		whPerKmMax = this.getMaxValue(this.props.whPerKmArrayCurrent, dataCountCurrent);

		for(i=0; i<dataCountCurrent; i++) {

			oneRow = {};

			// time
			oneRow.time = this.props.timeArrayCurrent[i];

			if (this.props.sensorThrottleYN) {
				labelText = this.props.getLang(RideLang, "sensorThrottle");
				if (sensorThrottleMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.sensorThrottleArrayCurrent[i] / sensorThrottleMax;
				}
			}

			if (this.props.throttleYN) {
				labelText = this.props.getLang(RideLang, "throttle");
				if (throttleMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.throttleArrayCurrent[i] / throttleMax;
				}
			}

			if (this.props.escTempYN) {
				labelText = this.props.getLang(RideLang, "escTemperature");
				if (escTempMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.escTempArrayCurrent[i] / escTempMax;
				}
			}

			if (this.props.escCurrentYN) {
				labelText = this.props.getLang(RideLang, "escCurrent");
				if (escCurrentMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.escCurrentArrayCurrent[i] / escCurrentMax;
				}
			}

			if (this.props.batteryPercentageYN) {
				labelText = this.props.getLang(RideLang, "batteryPercentage");
				if (batteryPercentageMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.batteryPercentageArrayCurrent[i] / batteryPercentageMax;
				}
			}

			if (this.props.batteryVoltageYN) {
				labelText = this.props.getLang(RideLang, "batteryVoltage");
				if (batteryVoltageMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.batteryVoltageArrayCurrent[i] / batteryVoltageMax;
				}
			}

			if (this.props.batteryCurrentYN) {
				labelText = this.props.getLang(RideLang, "batteryCurrent");
				if (batteryCurrentMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.batteryCurrentArrayCurrent[i] / batteryCurrentMax;
				}
			}

			if (this.props.minCellVoltageYN) {
				labelText = this.props.getLang(RideLang, "minCellVoltage");
				if (minCellVoltageMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.minCellVoltageArrayCurrent[i] / minCellVoltageMax;
				}
			}

			if (this.props.bmsTempYN) {
				labelText = this.props.getLang(RideLang, "batteryTemperature");
				if (bmsTempMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.bmsTempArrayCurrent[i] / bmsTempMax;
				}
			}
		
			if (this.props.gpsSpeedYN) {
				labelText = this.props.getLang(RideLang, "gpsSpeed");
				if (gpsSpeedMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.gpsSpeedArrayCurrent[i] / gpsSpeedMax;
				}
			}
			
			if (this.props.whPerKmYN) {
				labelText = this.props.getLang(RideLang, "whPerDistance");
				if (whPerKmMax === 0) {
					oneRow[labelText] = 0;
				} else {
					oneRow[labelText] = 100 * this.props.whPerKmArrayCurrent[i] / whPerKmMax;
				}
			}
			
			data.push(oneRow);
		}
		
		return data;
	}

	renderLine(renderYN, variableName, color) {
		if (!renderYN) {
			return null;
		}
		var variableName2 = this.props.getLang(RideLang, variableName);
		return (
			<Line type="monotone" dataKey={variableName2} stroke={color}/>
		);
	}

	render() {
		
		var width1 = window.innerWidth - 10;
		var height1 = window.innerHeight - 100;
		
		var data = this.getData();
		
		return (
			<LineChart
				width={width1}
				height={height1}
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="time" />
				<Tooltip content={
						<RideChartCustomTooltip 
							dataInterval={this.props.dataInterval}
							sensorThrottleArrayCurrent={this.props.sensorThrottleArrayCurrent}
							throttleArrayCurrent={this.props.throttleArrayCurrent}
							escTempArrayCurrent={this.props.escTempArrayCurrent}
							escCurrentArrayCurrent={this.props.escCurrentArrayCurrent}
							batteryPercentageArrayCurrent={this.props.batteryPercentageArrayCurrent}
							batteryVoltageArrayCurrent={this.props.batteryVoltageArrayCurrent}
							batteryCurrentArrayCurrent={this.props.batteryCurrentArrayCurrent}
							minCellVoltageArrayCurrent={this.props.minCellVoltageArrayCurrent}
							bmsTempArrayCurrent={this.props.bmsTempArrayCurrent}
							gpsSpeedArrayCurrent={this.props.gpsSpeedArrayCurrent}
							whPerKmArrayCurrent={this.props.whPerKmArrayCurrent}
							sensorThrottleArray={this.props.sensorThrottleArray}
							throttleArray={this.props.throttleArray}
							escTempArray={this.props.escTempArray}
							escCurrentArray={this.props.escCurrentArray}
							batteryPercentageArray={this.props.batteryPercentageArray}
							batteryVoltageArray={this.props.batteryVoltageArray}
							batteryCurrentArray={this.props.batteryCurrentArray}
							minCellVoltageArray={this.props.minCellVoltageArray}
							bmsTempArray={this.props.bmsTempArray}
							gpsSpeedArray={this.props.gpsSpeedArray}
							whPerKmArray={this.props.whPerKmArray}
							getTimeStringFromSeconds={this.props.getTimeStringFromSeconds}
							getLang={this.props.getLang}
						/>} 
						cursor={false} 
				/>
				<Legend />
				{this.renderLine(this.props.sensorThrottleYN, "sensorThrottle", "red")}
				{this.renderLine(this.props.throttleYN, "throttle", "green")}
				{this.renderLine(this.props.escTempYN, "escTemperature", "blue")}
				{this.renderLine(this.props.escCurrentYN, "escCurrent", "orange")}
				{this.renderLine(this.props.batteryPercentageYN, "batteryPercentage", "grey")}
				{this.renderLine(this.props.batteryVoltageYN, "batteryVoltage", "#fff000")}
				{this.renderLine(this.props.batteryCurrentYN, "batteryCurrent", "#30ff30")}
				{this.renderLine(this.props.minCellVoltageYN, "minCellVoltage", "#3090ff")}
				{this.renderLine(this.props.bmsTempYN, "batteryTemperature", "#82ca9d")}
				{this.renderLine(this.props.gpsSpeedYN, "gpsSpeed", "#529f6d")}
				{this.renderLine(this.props.whPerKmYN, "whPerDistance", "#9f6d52")}
			</LineChart>
		);
	}
}

export default RideChart;

//				
