import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';

class BatteryLogChartCustomTooltip extends React.Component {

	constructor(props) {

		super(props);

		this.getValue = this.getValue.bind(this);
		this.renderOneLine = this.renderOneLine.bind(this);
		this.renderAllLines = this.renderAllLines.bind(this);		
	}
	
	getValue(time, name) {

		var operation = this.props.operation;
		var dataInterval = this.props.dataInterval;
		var time2 = time / dataInterval;
		
		if (operation === "charge") {
			time2 = time / 300;
		}

		if (name === this.props.getLang(BatteryLang, "totalVoltage")) {
			return this.props.totalVoltageArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "current")) {
			return this.props.currentArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "residualCapacity")) {
			return this.props.residualCapacityArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "socStateOfCharge")) {
			return this.props.socArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "minCellVoltage")) {
			return this.props.minCellVoltageValueArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "temp1")) {
			return this.props.temp1ArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "temp2")) {
			return this.props.temp2ArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "temp3")) {
			return this.props.temp3ArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "temp4")) {
			return this.props.temp4ArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "temp5")) {
			return this.props.temp5ArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "temp6")) {
			return this.props.temp6ArrayCurrent[time2];
		}
		if (name === this.props.getLang(BatteryLang, "tempMax")) {
			return this.props.tempMaxArrayCurrent[time2];
		}

		return 0;
	}
	
	renderOneLine(index, time, line) {
		//console.log("time = " + time);
		//console.log(line);
		var name = line.name;
		var color = line.color;
		var value = this.getValue(time, name);
		const divStyle = { color: color };
		return (<div key={index} style={divStyle}>{name}: {value}</div>);
	}
	
	renderAllLines(payload) {

		var i;
		var time;
		var line;
		var length = payload.length;
		var jsxArray = [];
		
		//console.log("length = " + length);
		//console.log(payload);

		time = payload[0].payload.time;
		jsxArray.push((<div key="time">{time} sec</div>));
		for(i=0; i<length; i++) {
			line = payload[i];
			jsxArray.push(this.renderOneLine(i, time, line));
		}

		return (<div>{jsxArray}</div>);
	}

	render() {
		
		var active = this.props.active;
		var payload = this.props.payload;
		
		if (!active) {
			return null;
		}

		if (payload === null) {
			return null;
		}
		
		return (
			<div className="BatteryLogChartCustomTooltip">
				{this.renderAllLines(payload)}
			</div>
		);
	}
}

export default BatteryLogChartCustomTooltip;

