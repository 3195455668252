import React from 'react';
import './App.css';
import GeofenceLang from './GeofenceLang.json';

import { useNavigate } from "react-router-dom";

import GeofenceListToolbar from './GeofenceListToolbar.js';

class GeofenceList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			index: 0,
			count: 10,
			geofenceJsonObj: null,
		};

		this.handleSelectGeofence = this.handleSelectGeofence.bind(this);
		this.serverGetGeofenceList = this.serverGetGeofenceList.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);	
		this.renderOneGeofence = this.renderOneGeofence.bind(this);
		this.renderGeofenceList = this.renderGeofenceList.bind(this);
	}
	
	componentDidMount() {
		this.serverGetGeofenceList(this.state.index, this.state.count);
	}
	
	handleSelectGeofence(geofenceID) {
		this.props.setGeofenceID(geofenceID);
		const navigate = this.props.navigate;
  		navigate("/geofences/map");
	}

	handlePrev() {
		var index = this.state.index - 10;
		this.setState({index: index});
		this.serverGetGeofenceList(index, this.state.count);
	}

	handleNext() {
		var index = this.state.index + 10;
		this.setState({index: index});
		this.serverGetGeofenceList(index, this.state.count);
	}
	
	async serverGetGeofenceList(index, count) {
		
		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getgeofencelist?companyid=" + companyID;
		url = url + "&sortby=geofenceName&sortasc=1";
		url = url + "&index=" + index + "&count=" + count;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			geofenceJsonObj: jsonObj,
		});
	}
	
	renderOneGeofence(geofenceID, geofenceName, geofenceMacAddress) {
		return (
			<tr key={geofenceID}>
				<td
					className="GeofenceListItemGeofenceName"
					onClick={() => this.handleSelectGeofence(geofenceID)}
				>
					{geofenceName}
				</td>
				<td
					className="GeofenceListItemGeofenceMacAddress"
					onClick={() => this.handleSelectGeofence(geofenceID)}
				>
					{geofenceMacAddress}
				</td>
			</tr>
		);
	}

	renderAllGeofences() {

		var i;
		var geofenceJsonObj = this.state.geofenceJsonObj;
		var itemObj;
		var geofenceID;
		var geofenceName;
		var geofenceMacAddress;
		var jsxArray = [];

		for(i=0; i<geofenceJsonObj.length; i++) {
			itemObj = geofenceJsonObj[i];
			geofenceID = itemObj.geofenceID;
			geofenceName = itemObj.geofenceName;
			geofenceMacAddress = itemObj.geofenceMacAddress;
			jsxArray.push(this.renderOneGeofence(geofenceID, geofenceName, geofenceMacAddress));
		}

		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="GeofenceListHeaderGeofenceName">{this.props.getLang(GeofenceLang, "geofenceName")}</td>
						<td className="GeofenceListHeaderGeofenceMacAddress">{this.props.getLang(GeofenceLang, "macAddress")}</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	renderGeofenceList() {

		var geofenceCount = 0;
		var geofenceJsonObj = this.state.geofenceJsonObj;
		
		if (geofenceJsonObj !== null) {
			geofenceCount = geofenceJsonObj.length;
		}
		if (geofenceCount === 0) {
			return (<div>{this.props.getLang(GeofenceLang, "noGeofencesFound")}</div>);
		}

		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (geofenceCount === 10) {
			nextYN = true;
		}

		return (
			<div>
				{this.renderAllGeofences()}
				<div style={{height:"30px"}}></div>
				<div className="ListContainer3">
					{prevYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handlePrev()}
					>
						&lt;&lt; Previous
					</div>)}
					{!prevYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						&lt;&lt; Previous
					</div>)}
					<div className="ListPrevNextLinkDisabled">|</div>
					{nextYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handleNext()}
					>
						Next &gt;&gt;
					</div>)}
					{!nextYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						Next &gt;&gt;
					</div>)}
				</div>
			</div>
		);
	}
	
	render() {

		return (
			<div>
				<GeofenceListToolbar
					baseURLAPI={this.props.baseURLAPI}
					companyName={this.props.companyName}
					getLang={this.props.getLang}
				/>
				<div style={{height:"20px"}}></div>
				{this.renderGeofenceList()}
				<div style={{height:"30px"}}></div>
			</div>
		);
	}
}

export default function GeofenceListFunction(props) {
	const navigate = useNavigate();
	return <GeofenceList {...props} navigate={navigate} />;
}
