import React  from 'react';
import './App.css';
import './Ride.css';
import RideLang from './RideLang.json';
import { CSVLink } from "react-csv";
import moment from 'moment';

class RideDownload extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			filename: "",
			csvData: [],
		};

		this.getFilename= this.getFilename.bind(this);
		this.saveCSVFile = this.saveCSVFile.bind(this);
	}
	
	componentDidMount() {
		this.saveCSVFile();
	}

	getFilename(boardNumber, unixTimeSec) {

		var i;
		var ch;
		var filename = "";

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			filename = "Board " + boardNumber + ".csv";
			return filename;
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var dateString = moment(dateObj).format('YYYY-MM-DD');
		var timeString = moment(dateObj).format('HH-mm');

		filename = "Board " + boardNumber + " " + dateString + " " + timeString + ".csv";

		return filename;
	}

	saveCSVFile() {
		
		//var rideID = this.props.rideID;
		var boardNumber = this.props.boardNumber;
		var unixTimeSec = this.props.unixTimeSec;
		var uploadedUnixTimeSec = this.props.uploadedUnixTimeSec;
		var dataCount = this.props.dataCount;
		var timeArray = this.props.timeArray;
		var sensorThrottleArray = this.props.sensorThrottleArray;
		var throttleArray = this.props.throttleArray;
		var escTempArray = this.props.escTempArray;
		var escCurrentArray = this.props.escCurrentArray;
		var batteryPercentageArray = this.props.batteryPercentageArray;
		var batteryVoltageArray = this.props.batteryVoltageArray;
		var batteryCurrentArray = this.props.batteryCurrentArray;
		var minCellVoltageArray = this.props.minCellVoltageArray;
		var bmsTempArray = this.props.bmsTempArray;
		var batteryConnectedArray = this.props.batteryConnectedArray;
		var residualCapacityArray = this.props.residualCapacityArray;
		var minCellVoltageIndexArray = this.props.minCellVoltageIndexArray;
		var gpsLngArray = this.props.gpsLngArray;
		var gpsLatArray = this.props.gpsLatArray;
		var gpsSpeedArray = this.props.gpsSpeedArray;
		var whPerKmArray = this.props.whPerKmArray;
		var temp1Array = this.props.temp1Array;
		var temp2Array = this.props.temp2Array;
		var temp3Array = this.props.temp3Array;
		var temp4Array = this.props.temp4Array;
		var temp5Array = this.props.temp5Array;
		var temp6Array = this.props.temp6Array;

		var unixTimeSec2 = unixTimeSec;
		if (unixTimeSec === 0) {
			unixTimeSec2 = uploadedUnixTimeSec;
		}
		var filename = this.getFilename(boardNumber, unixTimeSec2);
				
		var i;
		var oneRow;
		var csvData = [];
		
		oneRow = [];
		oneRow.push("time");
		oneRow.push("sensorThrottle");
		oneRow.push("throttle");
		oneRow.push("escTemp");
		oneRow.push("escCurrent");
		oneRow.push("batteryPercentage");
		oneRow.push("batteryVoltage");
		oneRow.push("batteryCurrent");
		oneRow.push("minCellVoltage");
		oneRow.push("bmsTemp");
		oneRow.push("batteryConnected");
		oneRow.push("residualCapacity");
		oneRow.push("minCellVoltageIndex");
		oneRow.push("gpsLng");
		oneRow.push("gpsLat");
		oneRow.push("speedMetersPerSecond");
		oneRow.push("speedKnots");
		oneRow.push("speedKmPerHour");
		oneRow.push("whPerNauticalMile");
		oneRow.push("whPerKm");
		oneRow.push("batteryTempMax");
		oneRow.push("batteryTemp1");
		oneRow.push("batteryTemp2");
		oneRow.push("batteryTemp3");
		oneRow.push("batteryTemp4");
		oneRow.push("batteryTemp5");
		oneRow.push("batteryTemp6");
		csvData.push(oneRow);
		
		for(i=0; i<dataCount; i++) {
		
			oneRow = [];
			oneRow.push(timeArray[i]);
			oneRow.push(sensorThrottleArray[i]);
			oneRow.push(throttleArray[i]);
			oneRow.push(escTempArray[i]);
			oneRow.push(escCurrentArray[i]);
			oneRow.push(batteryPercentageArray[i]);
			oneRow.push(batteryVoltageArray[i]);
			oneRow.push(batteryCurrentArray[i]);
			oneRow.push(minCellVoltageArray[i]);
			oneRow.push(bmsTempArray[i]);
			oneRow.push(batteryConnectedArray[i]);
			oneRow.push(residualCapacityArray[i]);
			oneRow.push(minCellVoltageIndexArray[i]);
			oneRow.push(gpsLngArray[i]);
			oneRow.push(gpsLatArray[i]);

			var meterPerSecond = gpsSpeedArray[i];
			var knots = 1.9438452 * meterPerSecond;
			var kmPerHour = 3.6 * meterPerSecond;
			oneRow.push(meterPerSecond.toFixed(2));
			oneRow.push(knots.toFixed(2));
			oneRow.push(kmPerHour.toFixed(2));
			
			var whPerKm = whPerKmArray[i];
			var whPerNauticalMile = 1.852 * whPerKm;
			oneRow.push(whPerNauticalMile.toFixed(2));
			oneRow.push(whPerKm.toFixed(2));

			oneRow.push(bmsTempArray[i]);
			oneRow.push(temp1Array[i]);
			oneRow.push(temp2Array[i]);
			oneRow.push(temp3Array[i]);
			oneRow.push(temp4Array[i]);
			oneRow.push(temp5Array[i]);
			oneRow.push(temp6Array[i]);

			csvData.push(oneRow);
		}

		this.setState({
			filename: filename,
			csvData: csvData,
		});
	}
	
	render() {

		return (
			<div>
				<p className="RideDownloadText">{this.props.getLang(RideLang, "downloadCSVFile")}:</p>
				<p>&nbsp;</p>
				<CSVLink
					data={this.state.csvData}
					filename={this.state.filename}
  					className="RideDownloadLink"
				>
					{this.state.filename}
				</CSVLink>
			</div>
		);
	}
}

export default RideDownload;


