import React  from 'react';
import './App.css';
import GeofenceLang from './GeofenceLang.json';

import { useNavigate } from "react-router-dom";

class GeofenceNew extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			geofenceName: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onGeofenceNameChanged = this.onGeofenceNameChanged.bind(this);
		this.serverInsertGeofenceRecord = this.serverInsertGeofenceRecord.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onGeofenceNameChanged(value) {
		this.setState({geofenceName: value});
	}

	async serverInsertGeofenceRecord(geofenceName) {

		var userID = this.props.userID;
		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertgeofencerecord?geofencename=" + encodeURIComponent(geofenceName);
		url = url + "&userid=" + userID;
		url = url + "&companyid=" + companyID;
		
		const res = await fetch(url);
		await res.text();
	}
		
	async onClickSaveButton() {
		
		var geofenceName = this.state.geofenceName;
		geofenceName = geofenceName.trim();
		if (geofenceName === "") {
			this.props.showToast(this.props.getLang(GeofenceLang, "geofenceNameEmpty"));
			return;
		}
		
		this.serverInsertGeofenceRecord(geofenceName);
		this.props.showToast(this.props.getLang(GeofenceLang, "newGeofenceCreated") + ": " + geofenceName);
		const navigate = this.props.navigate;
  		navigate("/geofences");
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/geofences");
	}
	
	render() {		
		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(GeofenceLang, "newGeofence")}</div>
				<div className="ViewTopText">{this.props.getLang(GeofenceLang, "newGeofenceInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(GeofenceLang, "geofenceInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<div style={{height: "20px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(GeofenceLang, "geofenceName")}</legend> 
							<input
								className="ViewItemInput" 
								id="geofenceName" 
								type="text" 
								autoFocus={true}
								onChange={(event) => this.onGeofenceNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(GeofenceLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(GeofenceLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function GeofenceNewFunction(props) {
	const navigate = useNavigate();
	return <GeofenceNew {...props} navigate={navigate} />;
}

