import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';
import { useNavigate } from "react-router-dom";

class BatteryLogList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			logListJsonObj: null,
			index: 0,
			count: 10,
		};

		this.serverGetLogList = this.serverGetLogList.bind(this);
		this.handleSelectLog = this.handleSelectLog.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);	
		this.renderOneLog = this.renderOneLog.bind(this);
		this.renderAllLogs = this.renderAllLogs.bind(this);
		this.renderResults = this.renderResults.bind(this);
	}
	
	componentDidMount() {
		var batteryID = this.props.batteryID;
		this.serverGetLogList(batteryID, 0);
	}

	async serverGetLogList(batteryID, index) {
		
		if (batteryID <= 0) {
			return;
		}

		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbatteryloglist?batteryid=" + batteryID;
		url = url + "&companyid=" + companyID;
		url = url + "&sortby=batteryLogID&sortasc=0";
		url = url + "&index=" + index + "&count=" + this.state.count;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			logListJsonObj: jsonObj,
		});
	}
		
	handleSelectLog(batteryLogID) {
		this.props.setBatteryLogID(batteryLogID);
		const navigate = this.props.navigate;
  		navigate("/batterylog");
	}

	handlePrev() {
		var index = this.state.index - 10;
		this.setState({index: index});
		var batteryID = this.props.batteryID;
		this.serverGetLogList(batteryID, index);
	}

	handleNext() {
		var index = this.state.index + 10;
		this.setState({index: index});
		var batteryID = this.props.batteryID;
		this.serverGetLogList(batteryID, index);
	}

	renderOneLog(itemObj) {

		var batteryLogID = itemObj.batteryLogID;
		var unixTimeSec = itemObj.unixTimeSec;
		var uploadedUnixTimeSec = itemObj.uploadedUnixTimeSec;
		var durationSec = itemObj.durationSec;
		
		var unixTimeString = this.props.getDateTimeStringFromUnixTimeSec(unixTimeSec);
		var uploadedDateTimeString = this.props.getDateTimeStringFromUnixTimeSec(uploadedUnixTimeSec);
		var durationSecString = this.props.getTimeStringFromSeconds(durationSec);

		return (
			<tr key={batteryLogID}>
				<td
					className="BatteryLogListItemBatteryLogID"
					onClick={() => this.handleSelectLog(batteryLogID)}
				>
					{batteryLogID}
				</td>
				<td
					className="BatteryLogListItemUploadedTimestamp"
					onClick={() => this.handleSelectLog(batteryLogID)}
				>
					{uploadedDateTimeString}
				</td>
				<td
					className="BatteryLogListItemDuration"
					onClick={() => this.handleSelectLog(batteryLogID)}
				>
					{durationSecString}
				</td>
			</tr>
		);
	}

	renderAllLogs() {

		var i;
		var logListJsonObj = this.state.logListJsonObj;
		var itemObj;
		var jsxArray = [];

		for(i=0; i<logListJsonObj.length; i++) {
			itemObj = logListJsonObj[i];
			jsxArray.push(this.renderOneLog(itemObj));
		}

		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="BatteryLogListHeaderBatteryLogID">{this.props.getLang(BatteryLang, "logID")}Log ID</td>
						<td className="BatteryLogListHeaderUploadedTime">{this.props.getLang(BatteryLang, "uploadedTime")}</td>
						<td className="BatteryLogListHeaderDuration">{this.props.getLang(BatteryLang, "duration")}</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	renderResults() {

		var logCount = 0;
		var logListJsonObj = this.state.logListJsonObj;
		
		if (logListJsonObj !== null) {
			logCount = logListJsonObj.length;
		}
		if (logCount === 0) {
			return (<div>No logs found</div>);
		}

		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (logCount === this.state.count) {
			nextYN = true;
		}

		return (
			<div>
				{this.renderAllLogs()}
				<div style={{height:"30px"}}></div>
				<div className="ListContainer3">
					{prevYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handlePrev()}
					>
						&lt;&lt; Previous
					</div>)}
					{!prevYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						&lt;&lt; Previous
					</div>)}
					<div className="ListPrevNextLinkDisabled">|</div>
					{nextYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handleNext()}
					>
						Next &gt;&gt;
					</div>)}
					{!nextYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						Next &gt;&gt;
					</div>)}
				</div>
			</div>
		);
	}

	render() {		

		var batteryName = this.props.batteryName;
		
		return (
			<div>
				<p><b className="BatteryInfoTitle">{batteryName} - {this.props.getLang(BatteryLang, "batteryLogs")}</b></p>
				<div style={{height: "30px"}}></div>
				{this.renderResults()}
			</div>
		);
	}
}

export default function BatteryLogListFunction(props) {
	const navigate = useNavigate();
	return <BatteryLogList {...props} navigate={navigate} />;
}

