import React  from 'react';
import './App.css';
import './Geofence.css';
import GeofenceLang from './GeofenceLang.json';

import { useNavigate } from "react-router-dom";

class GeofenceMapToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderStartGeofenceLink = this.renderStartGeofenceLink.bind(this);
		this.renderMeasureDistanceLink = this.renderMeasureDistanceLink.bind(this);
	}
	
	handleSelectLink(page) {
		
		if (page === "startGeofence") {
			this.props.startGeofence();
		}
		
		if (page === "distance") {
			var measureDistanceYN = this.props.measureDistanceYN;
			this.props.setMeasureDistanceYN(!measureDistanceYN);
		}

		if (page === "editInfo") {
			var navigate = this.props.navigate;
			navigate("/geofences/edit");
		}
	}
	
	renderStartGeofenceLink() {

		var displayStartGeofenceYN = this.props.displayStartGeofenceYN;
		if (!displayStartGeofenceYN) {
			return;
		}
		
		var startGeofenceText = this.props.getLang(GeofenceLang, "startGeofence");

		return (
			<div 
				className="GeofenceMapToolbarLink"
				onClick={() => this.handleSelectLink("startGeofence")}
			>
				{startGeofenceText}
			</div>
		);
	}
	
	renderMeasureDistanceLink() {

		var classNameString = "GeofenceMapToolbarLink";
		var measureDistanceYN = this.props.measureDistanceYN;
		var distanceText = this.props.getLang(GeofenceLang, "distance");
		
		if (measureDistanceYN) {
			classNameString = "GeofenceMapToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink("distance")}
			>
				{distanceText}
			</div>
		);
	}

	renderEditInfoLink() {

		var editInfoText = this.props.getLang(GeofenceLang, "edit");

		return (
			<div 
				className="GeofenceMapToolbarLink"
				onClick={() => this.handleSelectLink("editInfo")}
			>
				{editInfoText}
			</div>
		);
	}
	
	render() {

		var geofenceName = this.props.geofenceName;
		var geofenceText = this.props.getLang(GeofenceLang, "geofence");
		var displayStartGeofenceYN = this.props.displayStartGeofenceYN;

		return (
			<div className="GeofenceMapToolbarContainer1">
				<div className="GeofenceMapToolbarLeft">{geofenceText}: {geofenceName}</div>
				<div className="GeofenceMapToolbarRight">
					<div className="GeofenceMapToolbarContainer2">
						{this.renderStartGeofenceLink()}
						{displayStartGeofenceYN && (<div className="GeofenceMainToolbarText">|</div>)}
						{this.renderMeasureDistanceLink()}
						<div className="GeofenceMainToolbarText">|</div>
						{this.renderEditInfoLink()}
					</div>
				</div>
			</div>
		);
	}
}

export default function GeofenceMapToolbarFunction(props) {
	const navigate = useNavigate();
	return <GeofenceMapToolbar {...props} navigate={navigate} />;
}

