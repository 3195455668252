import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';
import MaterialTable from 'material-table';

class BatteryLogTable extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			width: 0,
			height: 0,
		}

		this.updateDimensions = this.updateDimensions.bind(this);
		this.loadHeader = this.loadHeader.bind(this);
		this.getOneRow = this.getOneRow.bind(this);
		this.getRows = this.getRows.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};

	loadHeader() {
		
		var oneHeaderCell;
		var columns = [];

		// time
		oneHeaderCell = {};
		oneHeaderCell.field = "time";
		oneHeaderCell.title = this.props.getLang(BatteryLang, "time");
		oneHeaderCell.type = 'numeric';
		columns.push(oneHeaderCell);

		if (this.props.batteryTotalVoltageYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "totalVoltage";
			oneHeaderCell.title = this.props.getLang(BatteryLang, "totalVoltage");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryCurrentYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "current";
			oneHeaderCell.title = this.props.getLang(BatteryLang, "current");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batterySocYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "soc";
			oneHeaderCell.title = this.props.getLang(BatteryLang, "socStateOfCharge");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryResidualCapacityYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "residualCapacity";
			oneHeaderCell.title = this.props.getLang(BatteryLang, "residualCapacity");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryMinCellVoltageValueYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "minCellVoltageValue";
			oneHeaderCell.title = this.props.getLang(BatteryLang, "minCellVoltage");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryTemp1YN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "temp1";
			oneHeaderCell.title = "Temp 1";
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryTemp2YN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "temp2";
			oneHeaderCell.title = "Temp 2";
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryTemp3YN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "temp3";
			oneHeaderCell.title = "Temp 3";
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryTemp4YN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "temp4";
			oneHeaderCell.title = "Temp 4";
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryTemp5YN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "temp5";
			oneHeaderCell.title = "Temp 5";
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryTemp6YN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "temp6";
			oneHeaderCell.title = "Temp 6";
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryTempMaxYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "tempMax";
			oneHeaderCell.title = "Temp Max";
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		return columns;
	}

	getOneRow(i) {

		var oneRow = {};

		oneRow.time = this.props.timeArrayCurrent[i];
		oneRow.totalVoltage = this.props.totalVoltageArrayCurrent[i];
		oneRow.current = this.props.currentArrayCurrent[i];
		oneRow.residualCapacity = this.props.residualCapacityArrayCurrent[i];
		oneRow.soc = this.props.socArrayCurrent[i];
		oneRow.cycleTimes = this.props.cycleTimesArrayCurrent[i];
		oneRow.minCellVoltageValue = this.props.minCellVoltageValueArrayCurrent[i];
		oneRow.minCellVoltageIndex = this.props.minCellVoltageIndexArrayCurrent[i];
		oneRow.temp1 = this.props.temp1ArrayCurrent[i];
		oneRow.temp2 = this.props.temp2ArrayCurrent[i];
		oneRow.temp3 = this.props.temp3ArrayCurrent[i];
		oneRow.temp4 = this.props.temp4ArrayCurrent[i];
		oneRow.temp5 = this.props.temp5ArrayCurrent[i];
		oneRow.temp6 = this.props.temp6ArrayCurrent[i];
		oneRow.tempMax = this.props.tempMaxArrayCurrent[i];

		return oneRow;
	}
	
	getRows() {
		
		var i;
		var rows = [];
		var oneRow;
		
		for(i=0; i<this.props.dataCountCurrent; i++) {
			oneRow = this.getOneRow(i);
			rows.push(oneRow);
		}

		return rows;		
	}

	render() {
		
		var columns = this.loadHeader();
		//var height = window.innerHeight - 300;
		var rows = this.getRows();
	
		return (
			<div className="BatteryLogTableContainer">
				<MaterialTable
					options={{
						showTitle: false,
						search: false,
						paging: false,
						sorting: false,
						exportButton: false,
						maxBodyHeight: "85vh",
						headerStyle: {
							fontWeight: 'bold',
							zIndex: 1,
						},
						rowStyle: {
							borderBottom: '2px solid white',
							margin: "0px 0px 0px 0px"
						},
					}}
					columns={columns}
					data={rows}
					components={{
						Toolbar: () => null,
					}}						
				/>
			</div>
		);
	}
}

export default BatteryLogTable;

