import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';

import { useNavigate } from "react-router-dom";

class BatteryNew extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			batteryName: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onBatteryNameChanged = this.onBatteryNameChanged.bind(this);
		this.serverCheckIfBatteryExistsYN = this.serverCheckIfBatteryExistsYN.bind(this);
		this.serverInsertBatteryRecord = this.serverInsertBatteryRecord.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onBatteryNameChanged(value) {
		this.setState({batteryName: value});
	}

	async serverInsertBatteryRecord(batteryName) {

		var userID = this.props.userID;
		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertbatteryrecord?batteryname=" + encodeURIComponent(batteryName);
		url = url + "&userid=" + userID;
		url = url + "&companyid=" + companyID;
		
		const res = await fetch(url);
		await res.text();
	}
		
	async serverCheckIfBatteryExistsYN(batteryName) {
		
		// temp
		return false;

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbatterydatafrombatteryname?batteryname=" + encodeURIComponent(batteryName);
		
		const res = await fetch(url);
		const textResponse = await res.text();

		if (textResponse === "[]") {
			return false;
		} else {
			return true;
		}
	}
	
	async onClickSaveButton() {
		
		var batteryName = this.state.batteryName;
		batteryName = batteryName.trim();
		if (batteryName === "") {
			this.props.showToast(this.props.getLang(BatteryLang, "batteryNameEmpty"));
			return;
		}
		
		var existsYN = await this.serverCheckIfBatteryExistsYN(batteryName);
		if (existsYN) {
			this.props.showToast(this.props.getLang(BatteryLang, "batteryNameExists"));
			return;
		}

		this.serverInsertBatteryRecord(batteryName);
		this.props.showToast(this.props.getLang(BatteryLang, "newBatteryCreated") + ": " + batteryName);
		const navigate = this.props.navigate;
  		navigate("/batteries");
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/batteries");
	}
	
	render() {		
		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(BatteryLang, "newBattery")}</div>
				<div className="ViewTopText">{this.props.getLang(BatteryLang, "newBatteryInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(BatteryLang, "batteryInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<div style={{height: "20px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "batteryName")}</legend> 
							<input
								className="ViewItemInput" 
								id="batteryName" 
								type="text" 
								autoFocus={true}
								onChange={(event) => this.onBatteryNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(BatteryLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(BatteryLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function BatteryNewFunction(props) {
	const navigate = useNavigate();
	return <BatteryNew {...props} navigate={navigate} />;
}

