import React  from 'react';
import './CompanyPicker.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class CompanyPicker extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			companyID: 0,
			companyName: null,
			companyListJsonObj: null,
			renderPopupYN: false,
		};

		this.serverGetCompanyData = this.serverGetCompanyData.bind(this);
		this.serverGetCompanyList = this.serverGetCompanyList.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handleSelectCompany = this.handleSelectCompany.bind(this);
		this.renderOneCompany = this.renderOneCompany.bind(this);
		this.renderAllCompanies = this.renderAllCompanies.bind(this);
		this.popUpClosed = this.popUpClosed.bind(this);
		this.renderPopup = this.renderPopup.bind(this);
	}
	
	componentDidMount() {
		var companyID = this.props.companyID;
		this.setState({companyID: companyID});
		this.serverGetCompanyData(companyID);
	}

	componentDidUpdate() {
		var companyID1 = this.props.companyID;
		var companyID2 = this.state.companyID;
		
		if (companyID1 === companyID2) {
			return;
		}

		this.setState({companyID: companyID1});
		this.serverGetCompanyData(companyID1);
	}
	
	async serverGetCompanyData(companyID) {

		if (companyID <= 0) {
			this.setState({companyName: "n/a"});
			return;
		}

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getcompanydata?companyid=" + companyID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var companyName = jsonObj.companyName;
		
		this.setState({
			companyName: companyName,
		});
	}
	
	async serverGetCompanyList() {

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getcompanylist?sortby=companyname&sortasc=1&index=0&count=1000";
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			companyListJsonObj: jsonObj,
		});
	}
	
	handleClick() {
		if (this.state.companyListJsonObj === null) {
			this.serverGetCompanyList();
		}
		this.setState({renderPopupYN: true});
	}
	
	popUpClosed() {
		this.setState({renderPopupYN: false});
	}
	
	handleSelectCompany(companyID) {
		this.props.onChange(companyID);
		this.setState({renderPopupYN: false});
	}
	
	renderOneCompany(companyID, companyName) {
		return (
			<div key={companyID}
				className="CompanyPickerCompanyName"
				onClick={() => this.handleSelectCompany(companyID)}
			>
				{companyName}
			</div>
		);
	}

	renderAllCompanies() {
		
		var companyListJsonObj = this.state.companyListJsonObj;
		
		if (companyListJsonObj === null) {
			return null;
		}
		
		var i;
		var itemObj;
		var companyID;
		var companyName;
		var jsxArray = [];
		
		for(i=0; i<companyListJsonObj.length; i++) {
			itemObj = companyListJsonObj[i];
			companyID = itemObj.companyID;
			companyName = itemObj.companyName;
			jsxArray.push(this.renderOneCompany(companyID, companyName));
		}		

		return (
			<div>{jsxArray}</div>
		);
	}
	
	renderPopup(renderPopupYN) {
		
		return (
			<Popup
				open={renderPopupYN}
				//modal={true}
				closeOnDocumentClick={true}
				//position="right center"
				onClose={this.popUpClosed}
			>
				<div>{this.renderAllCompanies()}</div>
			</Popup>
		);
	}

	render() {
		
		if (this.state.companyName === null) {
			return null;
		}
		
		var renderPopupYN = this.state.renderPopupYN;
		
		return (
			<div className={this.props.className} onClick={this.handleClick}>
				{this.state.companyName}
				{this.renderPopup(renderPopupYN)}
			</div>
		);
	}
}

export default CompanyPicker;

