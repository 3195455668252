import React  from 'react';
import './App.css';
import './Board.css';
import BoardLang from './BoardLang.json';

import { useNavigate } from "react-router-dom";

class BoardListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(linkTitle, linkValue) {

		var classNameString = "BoardListToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {
		
		var companyIDInit = this.props.companyIDInit;
		var companyName = this.props.companyName;
		var newBoardString = this.props.getLang(BoardLang, "newBoard");
		
		var showNewYN = false;
		if (companyIDInit === 1) {
			showNewYN = true;
		}

		return (
			<div className="BoardListToolbarContainer1">
				<div className="BoardListToolbarLeft">{companyName}</div>
				<div className="BoardListToolbarRight">
					{showNewYN && (<div className="BoardListToolbarContainer2">
						{this.renderLink(newBoardString, "/boards/new")} 
					</div>)}
				</div>
			</div>
		);
	}
}

export default function BoardListToolbarFunction(props) {
	const navigate = useNavigate();
	return <BoardListToolbar {...props} navigate={navigate} />;
}

