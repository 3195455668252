import React  from 'react';
import './App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import GeofenceLang from './GeofenceLang.json';

import CompanyPicker from './CompanyPicker.js';
import UserPicker from './UserPicker.js';

import { useNavigate } from "react-router-dom";

class GeofenceEdit extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			geofenceID: 0,
			geofenceName: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onGeofenceNameChanged = this.onGeofenceNameChanged.bind(this);
		this.serverGetGeofenceData = this.serverGetGeofenceData.bind(this);
		this.serverSetGeofenceData = this.serverSetGeofenceData.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.handleClickDeleteButton = this.handleClickDeleteButton.bind(this);
		this.serverDeleteGeofenceRecord = this.serverDeleteGeofenceRecord.bind(this);
	}
	
	componentDidMount() {
		var geofenceID = this.props.geofenceID;
		this.setState({
			geofenceID: geofenceID,
		});
		this.serverGetGeofenceData(geofenceID);
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onGeofenceNameChanged(value) {
		this.setState({geofenceName: value});
	}
	
	async serverGetGeofenceData(geofenceID) {

		if (geofenceID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getgeofencedata?geofenceid=" + geofenceID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var geofenceName = jsonObj.geofenceName;
		
		this.setState({
			geofenceName: geofenceName,
		});
	}

	async serverSetGeofenceData(geofenceID, geofenceName) {

		var userID = this.props.userID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setgeofencedata?geofenceid=" + geofenceID;
		url = url + "&geofencename=" + encodeURIComponent(geofenceName);
		url = url + "&editeduserid=" + userID;
		
		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(GeofenceLang, "geofenceDataSaved"));
		var navigate = this.props.navigate;
		navigate("/geofences/map");
	}
		
	onClickSaveButton() {
		
		var geofenceName = this.state.geofenceName;
		geofenceName = geofenceName.trim();

		if (geofenceName === "") {
			this.props.showToast(this.props.getLang(GeofenceLang, "geofenceNameEmpty"));
			return;
		}

		var geofenceID = this.state.geofenceID;
		this.serverSetGeofenceData(geofenceID, geofenceName);
	}
	
	onClickCancelButton() {
		var navigate = this.props.navigate;
		navigate("/geofences/map");
	}
	
	handleClickDeleteButton() {
		
		var geofenceID = this.props.geofenceID;

		confirmAlert({
			title: this.props.getLang(GeofenceLang, "deleteGeofence"),
			message: this.props.getLang(GeofenceLang, "deleteGeofenceAreYouSure"),
			buttons: [
				{
					label: this.props.getLang(GeofenceLang, "yes"),
					onClick: () => this.serverDeleteGeofenceRecord(geofenceID)
				},
				{
					label: this.props.getLang(GeofenceLang, "no"),
				}
			]
		});
		
	}

	async serverDeleteGeofenceRecord(geofenceID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletegeofencerecord?geofenceid=" + geofenceID;

		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(GeofenceLang, "geofenceDeleted"));
		const navigate = this.props.navigate;
  		navigate("/geofences");
	}

	render() {		
	
		if (this.state.geofenceID === 0) {
			return null;
		}

		var sailfinAppUserType = this.props.sailfinAppUserType;
		var showDeleteButtonYN = false;
		if (sailfinAppUserType >= 100) {
			showDeleteButtonYN = true;
		}

		return (
		<div>
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(GeofenceLang, "editGeofenceInfo")}</div>
				<div className="ViewTopText">{this.props.getLang(GeofenceLang, "editGeofenceInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(GeofenceLang, "geofenceInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(GeofenceLang, "geofenceID")}</legend> 
							<input
								className="ViewItemInput" 
								id="geofenceID" 
								type="text" 
								defaultValue={this.state.geofenceID}
								disabled={true}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(GeofenceLang, "geofenceName")}</legend> 
							<input
								className="ViewItemInput" 
								id="geofenceName" 
								type="text" 
								defaultValue={this.state.geofenceName}
								onChange={(event) => this.onGeofenceNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
								autoFocus={true}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(GeofenceLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(GeofenceLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "40px"}}></div>

				{showDeleteButtonYN && (
					<div>
						<div style={{height: "30px"}}></div>
						<div className="GeofenceEditContainerDeleteButton">
							<button className="ViewSaveButton" onClick={this.handleClickDeleteButton}>{this.props.getLang(GeofenceLang, "deleteThisGeofence")}</button>
						</div>
					</div>)}
				
				<div style={{height: "40px"}}></div>
			</div>
		</div>
		);
	}
}

export default function GeofenceEditFunction(props) {
	const navigate = useNavigate();
	return <GeofenceEdit {...props} navigate={navigate} />;
}

