import * as React from 'react';
import './App.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {BrowserRouter as Router, Routes, Route } from "react-router-dom";
import moment from 'moment';
import {storeEmailPassword} from './LoginLocalStorage.js';

import Topbar from './common/Topbar.js';
import Login from './Login.js';
import RideLogList from './RideLogList.js';
import BoardList from './BoardList.js';
import BoardMain from './BoardMain.js';
import BoardNew from './BoardNew.js';
import Ride from './Ride.js';
import BatteryList from './BatteryList.js';
import BatteryMain from './BatteryMain.js';
import BatteryNew from './BatteryNew.js';
import BatteryLogAll from './BatteryLogAll.js';
import BatteryLog from './BatteryLog.js';
import GeofenceList from './GeofenceList.js';
import GeofenceNew from './GeofenceNew.js';
import GeofenceEdit from './GeofenceEdit.js';
import GeofenceMap from './GeofenceMap.js';
import BatteryTester from './BatteryTester.js';
import SelectCompany from './SelectCompany.js';
import About from './About.js';

class App extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			baseURLServer: "https://sailfinapp.plurato.com",
			baseURLAPI: "https://sailfinapi.plurato.com",
			managementBaseURLAPI: "https://managementapi.plurato.com",
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			phone: "",
			language: "en",
			sailfinAppUserType: 0,
			companyID: 0,
			companyIDInit: 0,
			companyName: "",
			firstLoadYN: true,
			rideID: 0,
			batteryLogID: 0,
			dataInterval: 10,
			sensorThrottleYN: true,
			throttleYN: false,
			escTempYN: false,
			escCurrentYN: true,
			batteryPercentageYN: true,
			batteryVoltageYN: false,
			batteryCurrentYN: false,
			minCellVoltageYN: false,
			bmsTempYN: false,
			gpsSpeedYN: true,
			whPerKmYN: false,
			selectedBoardID: 0,
			selectedBatteryID: 0,
			batteryTotalVoltageYN: true,
			batteryShowCurrentYN: true,
			batterySocYN: true,
			batteryResidualCapacityYN: false,
			batteryMinCellVoltageValueYN: true,
			batteryTemp1YN: false,
			batteryTemp2YN: false,
			batteryTemp3YN: false,
			batteryTemp4YN: false,
			batteryTemp5YN: false,
			batteryTemp6YN: false,
			batteryTempMaxYN: true,
			geofenceID: 0,
		}

		this.serverGetUserDataFromSessionID = this.serverGetUserDataFromSessionID.bind(this);
		this.getCompanyName = this.getCompanyName.bind(this);
		this.getSessionIDFromURLString = this.getSessionIDFromURLString.bind(this);
		this.showToast = this.showToast.bind(this);
		this.logoutClearVariables = this.logoutClearVariables.bind(this);
		this.serverLogout = this.serverLogout.bind(this);
		this.serverGetUserData = this.serverGetUserData.bind(this);
		this.setSessionID = this.setSessionID.bind(this);
		this.setUserID = this.setUserID.bind(this);
		this.setRideSettingsValue = this.setRideSettingsValue.bind(this);		
		this.setRideID = this.setRideID.bind(this);
		this.setGeofenceID = this.setGeofenceID.bind(this);
		this.setBatteryLogSettingsValue = this.setBatteryLogSettingsValue.bind(this);		
		this.setBatteryLogID = this.setBatteryLogID.bind(this);
		this.setSelectedBoardID = this.setSelectedBoardID.bind(this);
		this.setSelectedBatteryID = this.setSelectedBatteryID.bind(this);
		this.setCompany = this.setCompany.bind(this);
		this.separateTextIntoArrayOfLines = this.separateTextIntoArrayOfLines.bind(this);
		this.convertTextNewlineToBr = this.convertTextNewlineToBr.bind(this);
		this.getDateStringFromUnixTimeSec = this.getDateStringFromUnixTimeSec.bind(this);
		this.getTimeStringFromUnixTimeSec = this.getTimeStringFromUnixTimeSec.bind(this);
		this.getDateTimeStringFromUnixTimeSec = this.getDateTimeStringFromUnixTimeSec.bind(this);
		this.getTimeStringFromSeconds = this.getTimeStringFromSeconds.bind(this);
		this.getMonthString = this.getMonthString.bind(this);
		this.getMonthStringShort = this.getMonthStringShort.bind(this);
		this.setLanguage = this.setLanguage.bind(this);
		this.getLang = this.getLang.bind(this);		
		this.checkIfLoggedIn = this.checkIfLoggedIn.bind(this);
	}
	
	componentDidMount(){

		document.title = "Plurato Sailfin Analytics"

		// temp
		//this.setState({selectedBoardID: 1});

		var windowLocation = window.location;
		var sessionID = this.getSessionIDFromURLString(windowLocation.toString());

		if (sessionID === "") {
			this.setState({firstLoadYN: false});
			return;
		}
		
		this.serverGetUserDataFromSessionID(sessionID);
		this.setState({
			sessionID: sessionID,
		});
	}
	
	async getCompanyName(companyID) {

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getcompanydata?companyid=" + companyID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var companyName = jsonObj.companyName;
		
		return companyName;
	}

	async serverGetUserDataFromSessionID(sessionID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdatafromsessionid?sid=" + sessionID;

		try {
			const res = await fetch(url);
			const jsonObj = await res.json();

			var userID = jsonObj.userID;
			var email = jsonObj.email;
			var firstName = jsonObj.firstName;
			var lastName = jsonObj.lastName;
			var phone = jsonObj.phone;
			var language = jsonObj.language;			
			var managementAppUserType = jsonObj.managementAppUserType;
			var sailfinAppUserType = jsonObj.sailfinAppUserType;
			var companyID = jsonObj.companyID;
			var createdTimestamp = jsonObj.createdTimestamp;

			var companyName = await this.getCompanyName(companyID);
			
			this.setState({
				userID: userID,
				email: email,
				firstName: firstName,
				lastName: lastName,
				phone: phone,
				language: language,
				managementAppUserType: managementAppUserType,
				sailfinAppUserType: sailfinAppUserType,
				companyID: companyID,
				companyIDInit: companyID,
				companyName: companyName,
				createdTimestamp: createdTimestamp,
				firstLoadYN: false,
			});

		} catch (e) {
			this.setState({firstLoadYN: false});
		}
	}
	
	getSessionIDFromURLString(urlString) {

		var i;
		var index;
		var length;
		var foundYN;
		var sessionID = "";
		
		length = urlString.length;
		foundYN = false;
		index = 0;
		
		// find sid=
		for(i=0; (i<length) && (!foundYN); i++) {
			if (urlString.substr(i, 4) === "sid=") {
				foundYN = true;
				index = i+4;
			}
		}
		
		if (!foundYN) {
			return "";
		}
		
		sessionID = urlString.substr(index, length-index);
		return sessionID;
	}

	showToast(messageText) {
		toast(messageText, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}
	
	logoutClearVariables() {

		var sessionID = this.state.sessionID;
		this.serverLogout(sessionID);

		this.setState({
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			phone: "",
			managementAppUserType: 0,
			sailfinAppUserType: 0,
			companyID: 0,
			createdTimestamp: "",
			selectedCompanyID: 0,
			selectedCompanyName: "",
		});

		storeEmailPassword("", "");
	}

	async serverLogout(sessionID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/logout?sid=" + sessionID;

		const res = await fetch(url);
		await res.text();
	}

	async serverGetUserData(userID) {

		if (userID <= 0) {
			return;
		}

		var sessionID = this.state.sessionID;
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdata?sid=" + sessionID + "&userid=" + userID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var email = jsonObj.email;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;
		var phone = jsonObj.phone;
		var language = jsonObj.language;			
		var managementAppUserType = jsonObj.managementAppUserType;
		var sailfinAppUserType = jsonObj.sailfinAppUserType;
		var companyID = jsonObj.companyID;
		var createdTimestamp = jsonObj.createdTimestamp;

		var companyName = await this.getCompanyName(companyID);

		this.setState({
			email: email,
			firstName: firstName,
			lastName: lastName,
			phone: phone,
			language: language,
			managementAppUserType: managementAppUserType,
			sailfinAppUserType: sailfinAppUserType,
			companyID: companyID,
			companyIDInit: companyID,
			companyName: companyName,
			createdTimestamp: createdTimestamp,
		});
	}
	
	setSessionID(sessionID) {
		this.setState({sessionID: sessionID});
	}
	
	setUserID(userID) {
		this.setState({userID: userID});
	}
	
	setRideID(rideID) {
		this.setState({rideID: rideID});
	}
	
	setGeofenceID(geofenceID) {
		this.setState({geofenceID: geofenceID});
	}
	
	setBatteryLogID(batteryLogID) {
		this.setState({batteryLogID: batteryLogID});
	}

	setRideSettingsValue(key, value) {
		if (key === "dataInterval") {
			this.setState({dataInterval: value});
		}		
		if (key === "sensorThrottleYN") {
			this.setState({sensorThrottleYN: value});
		}		
		if (key === "sensorThrottleYN") {
			this.setState({sensorThrottleYN: value});
		}		
		if (key === "throttleYN") {
			this.setState({throttleYN: value});
		}		
		if (key === "escTempYN") {
			this.setState({escTempYN: value});
		}		
		if (key === "escCurrentYN") {
			this.setState({escCurrentYN: value});
		}		
		if (key === "batteryPercentageYN") {
			this.setState({batteryPercentageYN: value});
		}		
		if (key === "batteryVoltageYN") {
			this.setState({batteryVoltageYN: value});
		}		
		if (key === "batteryCurrentYN") {
			this.setState({batteryCurrentYN: value});
		}		
		if (key === "minCellVoltageYN") {
			this.setState({minCellVoltageYN: value});
		}		
		if (key === "bmsTempYN") {
			this.setState({bmsTempYN: value});
		}
		if (key === "gpsSpeedYN") {
			this.setState({gpsSpeedYN: value});
		}
		if (key === "whPerKmYN") {
			this.setState({whPerKmYN: value});
		}		
	}
	
	setBatteryLogSettingsValue(key, value) {
		if (key === "dataInterval") {
			this.setState({dataInterval: value});
		}		
		if (key === "batteryTotalVoltageYN") {
			this.setState({batteryTotalVoltageYN: value});
		}
		if (key === "batteryCurrentYN") {
			this.setState({batteryShowCurrentYN: value});
		}
		if (key === "batterySocYN") {
			this.setState({batterySocYN: value});
		}
		if (key === "batteryResidualCapacityYN") {
			this.setState({batteryResidualCapacityYN: value});
		}
		if (key === "batteryMinCellVoltageValueYN") {
			this.setState({batteryMinCellVoltageValueYN: value});
		}
		if (key === "batteryTemp1YN") {
			this.setState({batteryTemp1YN: value});
		}
		if (key === "batteryTemp2YN") {
			this.setState({batteryTemp2YN: value});
		}
		if (key === "batteryTemp3YN") {
			this.setState({batteryTemp3YN: value});
		}
		if (key === "batteryTemp4YN") {
			this.setState({batteryTemp4YN: value});
		}
		if (key === "batteryTemp5YN") {
			this.setState({batteryTemp5YN: value});
		}
		if (key === "batteryTemp6YN") {
			this.setState({batteryTemp6YN: value});
		}
		if (key === "batteryTempMaxYN") {
			this.setState({batteryTempMaxYN: value});
		}
	}
		
	setSelectedBoardID(selectedBoardID) {
		this.setState({selectedBoardID: selectedBoardID});
	}

	setSelectedBatteryID(selectedBatteryID) {
		this.setState({selectedBatteryID: selectedBatteryID});
	}
	
	setCompany(companyID, companyName) {
		this.setState({
			companyID: companyID,
			companyName: companyName,
		});
	}

	separateTextIntoArrayOfLines(inputText) {

		var lineArray = [];

		if (inputText === null) {
			inputText = "";
		}

		if (inputText === "") {
			return lineArray;
		}

		var i;
		var ch;
		var len = inputText.length;
		var oneLineText = "";
		
		for(i=0; i<len; i++) {
			ch = inputText[i];
			if (ch === "\n") {
				lineArray.push(oneLineText);
				oneLineText = "";
			} else {
				oneLineText = oneLineText + ch;
			}
		}
		
		if (oneLineText !== "") {
			lineArray.push(oneLineText);
		}

		return lineArray;
	}

	convertTextNewlineToBr(inputText, variable, className) {
		
		var lineArray = this.separateTextIntoArrayOfLines(inputText);
		var jsxArray = [];
		
		var i;
		var len = lineArray.length;
		var oneLineText;
		var key;
		
		for(i=0; i<len; i++) {
			key = variable + "-" + i;
			oneLineText = lineArray[i];
			jsxArray.push(<div key={key} className={className}>{oneLineText}</div>);
		}
		
		return (<div>{jsxArray}</div>);
	}

	getDateStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var dateString = moment(dateObj).format('DD.MM.YYYY');
		return dateString;
	}

	getTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var timeString = moment(dateObj).format('HH:mm');
		return timeString;
	}

	getDateTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var currentDateObj = new Date();
		var dateObj = new Date(unixTimeSec * 1000);
		
		var year1 = currentDateObj.getFullYear();
		var year2 = dateObj.getFullYear();
		
		var dateTimeString;
		if (year1 === year2) {
			dateTimeString = moment(dateObj).format('DD/MM HH:mm');
		} else {
			dateTimeString = moment(dateObj).format('DD.MM.YYYY HH:mm');
		}

		return dateTimeString;
	}

	getTimeStringFromSeconds(seconds) {
		
		if (seconds === null) {
			return "";
		}

		var secondsLeft = 0;
		var hours = 0;
		var minutes = 0;
		var timeString = "";
		
		secondsLeft = seconds;
		
		hours = Math.floor(secondsLeft / 3600);
		secondsLeft = secondsLeft - hours * 3600;
		
		minutes = Math.floor(secondsLeft / 60);
		secondsLeft = secondsLeft - minutes * 60;
		
		if (hours > 0) {
			timeString = hours + "h ";
		}
		
		if (minutes > 0) {
			timeString = timeString + minutes + "m ";
		}
		
		timeString = timeString + secondsLeft + "s";
		//if ((hours === 0) && (minutes === 0)) {
		//}

		return timeString;
	}
	
	getMonthString(month) {
		var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
		var monthString = monthNames[month-1];
		return monthString;
	}
	
	getMonthStringShort(month) {
		var monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
		var monthString = monthNames[month-1];
		return monthString;
	}
	
	setLanguage(language) {
		this.setState({language: language});
	}
	
	getLang(lang, id) {
		var language = this.state.language;
		var id2 = id + "." + language;
		var i;
		var id3;
		var length = lang.length;
		var itemObj;
		var keys;
		var returnString;
		for(i=0; i<length; i++) {
			itemObj = lang[i];
			keys = Object.keys(itemObj);
			id3 = keys[0];
			if (id2 === id3) {
				returnString = itemObj[id2];
				return returnString;
			}
		}
		return "";
	}
	
	checkIfLoggedIn() {
				
		var pathname = window.location.pathname;
		//console.log("pathname = " + pathname);
		
		if (pathname === "/") {
			return;
		}

		var userID = this.state.userID;
		//console.log("userID = " + userID);
		if (userID !== 0) {
			return;
		}
		
		var url = "/";
		window.location.href = url;
	}
	
	render() {

		var pathname = document.location.pathname;
		if (pathname === "/directreports/nfcbattery") {
			return (
				<div className="App">
					<BatteryMain
						baseURLAPI={this.state.baseURLAPI}
						managementBaseURLAPI={this.state.managementBaseURLAPI}
						selectedBatteryID={0}
						userID={this.state.userID}
						email={this.state.email}
						firstName={this.state.firstName}
						lastName={this.state.lastName}
						setBatteryLogID={this.setBatteryLogID}
						showToast={this.showToast}
						convertTextNewlineToBr={this.convertTextNewlineToBr}
						getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
						getTimeStringFromSeconds={this.getTimeStringFromSeconds}
						directLinkYN={true}
						getLang={this.getLang}
					/>
				</div>
			);
		}

		this.checkIfLoggedIn();

		var firstLoadYN = this.state.firstLoadYN;
		if (firstLoadYN) {
			return null;
		}

		var loggedInYN;
		var userID = this.state.userID;
		
		if (userID === 0) {
			loggedInYN = false;
		} else {
			loggedInYN = true;
		}
				
		return (
		<Router>
			<div className="App">
				<ToastContainer />
				<Topbar 
					managementBaseURLAPI={this.state.managementBaseURLAPI}
					userID={this.state.userID}
					email={this.state.email}
					firstName={this.state.firstName}
					lastName={this.state.lastName}
					sessionID={this.state.sessionID}
					appName="SAILFIN"
					bkColor1="#3a73dd"
					bkColor2="#f0f0f0"
					sailfinAppUserType={this.state.sailfinAppUserType}
					companyIDInit={this.state.companyIDInit}
					logoutClearVariables={this.logoutClearVariables}
					getLang={this.getLang}
					setLanguage={this.setLanguage}
				/>
        		<Routes>
          			<Route 
						path="/"
						element={
							<div>
							{!loggedInYN && (
								<Login
									baseURLAPI={this.state.baseURLAPI}
									managementBaseURLAPI={this.state.managementBaseURLAPI}
									setSessionID={this.setSessionID}
									setUserID={this.setUserID}
									serverGetUserData={this.serverGetUserData}
									getLang={this.getLang}
								/>
							)}
							{loggedInYN && (
								<RideLogList
									baseURLAPI={this.state.baseURLAPI}
									companyID={this.state.companyID}
									companyName={this.state.companyName}
									setRideID={this.setRideID}
									getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
									getLang={this.getLang}
								/>
							)}
							</div>
						}
					/>
          			<Route 
						path="/login"
						element={
							<Login
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								setSessionID={this.setSessionID}
								setUserID={this.setUserID}
								serverGetUserData={this.serverGetUserData}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/dashboard"
						element={
							<RideLogList
								baseURLAPI={this.state.baseURLAPI}
								companyID={this.state.companyID}
								companyName={this.state.companyName}
								setRideID={this.setRideID}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getLang={this.getLang}
							/>
						}
					/>
          			<Route 
						path="/boards"
						element={
							<BoardList
								baseURLAPI={this.state.baseURLAPI}
								companyID={this.state.companyID}
								companyIDInit={this.state.companyIDInit}
								companyName={this.state.companyName}
								setSelectedBoardID={this.setSelectedBoardID}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/board"
						element={
							<BoardMain
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								selectedBoardID={this.state.selectedBoardID}
								userID={this.state.userID}
								email={this.state.email}
								firstName={this.state.firstName}
								lastName={this.state.lastName}
								companyIDInit={this.state.companyIDInit}
								setRideID={this.setRideID}
								showToast={this.showToast}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/boards/new"
						element={
							<BoardNew
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								userID={this.state.userID}
								companyID={this.state.companyID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/ride"
						element={
							<Ride
								rideID={this.state.rideID}
								baseURLServer={this.state.baseURLServer}
								baseURLAPI={this.state.baseURLAPI}
								sailfinAppUserType={this.state.sailfinAppUserType}
								dataInterval={this.state.dataInterval}
								sensorThrottleYN={this.state.sensorThrottleYN}
								throttleYN={this.state.throttleYN}
								escTempYN={this.state.escTempYN}
								escCurrentYN={this.state.escCurrentYN}
								batteryPercentageYN={this.state.batteryPercentageYN}
								batteryVoltageYN={this.state.batteryVoltageYN}
								batteryCurrentYN={this.state.batteryCurrentYN}
								minCellVoltageYN={this.state.minCellVoltageYN}
								bmsTempYN={this.state.bmsTempYN}
								gpsSpeedYN={this.state.gpsSpeedYN}
								whPerKmYN={this.state.whPerKmYN}
								setRideSettingsValue={this.setRideSettingsValue}
								getTimeStringFromSeconds={this.getTimeStringFromSeconds}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/batteries"
						element={
							<BatteryList
								baseURLAPI={this.state.baseURLAPI}
								companyID={this.state.companyID}
								companyIDInit={this.state.companyIDInit}
								companyName={this.state.companyName}
								setSelectedBatteryID={this.setSelectedBatteryID}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/battery"
						element={
							<BatteryMain
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								selectedBatteryID={this.state.selectedBatteryID}
								userID={this.state.userID}
								email={this.state.email}
								firstName={this.state.firstName}
								lastName={this.state.lastName}
								companyIDInit={this.state.companyIDInit}
								setBatteryLogID={this.setBatteryLogID}
								showToast={this.showToast}
								convertTextNewlineToBr={this.convertTextNewlineToBr}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getTimeStringFromSeconds={this.getTimeStringFromSeconds}
								directLinkYN={false}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/batteries/new"
						element={
							<BatteryNew
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								userID={this.state.userID}
								companyID={this.state.companyID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
          			/>
         			<Route 
						path="/batterylogall"
						element={
							<BatteryLogAll
								baseURLAPI={this.state.baseURLAPI}
								companyID={this.state.companyID}
								companyIDInit={this.state.companyIDInit}
								companyName={this.state.companyName}
								setBatteryLogID={this.setBatteryLogID}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getTimeStringFromSeconds={this.getTimeStringFromSeconds}
								getLang={this.getLang}
							/>
						}
					/>
					
          			<Route 
						path="/batterylog"
						element={
							<BatteryLog
								batteryLogID={this.state.batteryLogID}
								baseURLServer={this.state.baseURLServer}
								baseURLAPI={this.state.baseURLAPI}
								sailfinAppUserType={this.state.sailfinAppUserType}
								dataInterval={this.state.dataInterval}
								batteryTotalVoltageYN={this.state.batteryTotalVoltageYN}
								batteryCurrentYN={this.state.batteryShowCurrentYN}
								batterySocYN={this.state.batterySocYN}
								batteryResidualCapacityYN={this.state.batteryResidualCapacityYN}
								batteryMinCellVoltageValueYN={this.state.batteryMinCellVoltageValueYN}
								batteryTemp1YN={this.state.batteryTemp1YN}
								batteryTemp2YN={this.state.batteryTemp2YN}
								batteryTemp3YN={this.state.batteryTemp3YN}
								batteryTemp4YN={this.state.batteryTemp4YN}
								batteryTemp5YN={this.state.batteryTemp5YN}
								batteryTemp6YN={this.state.batteryTemp6YN}
								batteryTempMaxYN={this.state.batteryTempMaxYN}
								setBatteryLogSettingsValue={this.setBatteryLogSettingsValue}
								showToast={this.showToast}
								getLang={this.getLang}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
							/>
						}
          			/>

         			<Route 
						path="/geofences"
						element={
							<GeofenceList
								baseURLAPI={this.state.baseURLAPI}
								companyID={this.state.companyID}
								companyName={this.state.companyName}
								setGeofenceID={this.setGeofenceID}
								getDateTimeStringFromUnixTimeSec={this.getDateTimeStringFromUnixTimeSec}
								getTimeStringFromSeconds={this.getTimeStringFromSeconds}
								getLang={this.getLang}
							/>
						}
					/>
          			<Route 
						path="/geofences/new"
						element={
							<GeofenceNew
								baseURLAPI={this.state.baseURLAPI}
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								userID={this.state.userID}
								companyID={this.state.companyID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/geofences/edit"
						element={
							<GeofenceEdit
								baseURLAPI={this.state.baseURLAPI}
								userID={this.state.userID}
								sailfinAppUserType={this.state.sailfinAppUserType}
								geofenceID={this.state.geofenceID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/geofences/map"
						element={
							<GeofenceMap
								baseURLAPI={this.state.baseURLAPI}
								userID={this.state.userID}
								companyID={this.state.companyID}
								geofenceID={this.state.geofenceID}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
          			/>

          			<Route 
						path="/batterytester"
						element={
							<BatteryTester
								baseURLAPI={this.state.baseURLAPI}
								getLang={this.getLang}
							/>
						}
          			/>
          			<Route 
						path="/selectcompany"
						element={
							<SelectCompany
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								companyIDInit={this.state.companyIDInit}
								setCompany={this.setCompany}
								getLang={this.getLang}
							/>
						}
          			/>

          			<Route 
						path="/about"
						element={
							<About
								baseURLAPI={this.state.baseURLAPI}
								getLang={this.getLang}
							/>
						}
          			/>

        		</Routes>
			</div>
    		</Router>
		);
	}
}

export default App;

/*
*/

