import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';

class BatteryLogToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(tab) {
		this.props.setSelectedTab(tab);
	}
	
	renderLink(linkTitle, linkValue) {

		var selectedTab = this.props.selectedTab;
		var classNameString = "BatteryLogToolbarLink";
		
		if (selectedTab === linkValue) {
			classNameString = "BatteryLogToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {
		
		var statsText = this.props.getLang(BatteryLang, "stats");
		var chartText = this.props.getLang(BatteryLang, "chart");
		var tableText = this.props.getLang(BatteryLang, "table");
		var downloadText = this.props.getLang(BatteryLang, "download");
		var settingsText = this.props.getLang(BatteryLang, "settings");
		
		return (
			<div className="BatteryLogToolbarContainer1">
				<div className="BatteryLogToolbarLeft">{this.props.getLang(BatteryLang, "batteryLogID")}: {this.props.batteryLogID}</div>
				<div className="BatteryLogToolbarRight">
					<div className="BatteryLogToolbarContainer2">
						{this.renderLink(statsText, "stats")} <div className="BatteryLogToolbarText">|</div>  
						{this.renderLink(chartText, "chart")} <div className="BatteryLogToolbarText">|</div>
						{this.renderLink(tableText, "table")} <div className="BatteryLogToolbarText">|</div>
						{this.renderLink(downloadText, "download")} <div className="BatteryLogToolbarText">|</div>
						{this.renderLink(settingsText, "settings")}
					</div>
				</div>
			</div>
		);
	}
}

export default BatteryLogToolbar;

