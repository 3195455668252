import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';

import { useNavigate } from "react-router-dom";

class BatteryNewComment extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			commentText: "",
		};

		this.onCommentTextChanged = this.onCommentTextChanged.bind(this);
		this.serverInsertBatteryComment = this.serverInsertBatteryComment.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	onCommentTextChanged(value) {
		this.setState({commentText: value});
	}

	async serverInsertBatteryComment(batteryID, userID, userName, commentText) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertbatterycommentrecord?batteryid=" + batteryID;
		url = url + "&userid=" + userID;
		url = url + "&username=" + encodeURIComponent(userName);
		url = url + "&commenttext=" + encodeURIComponent(commentText);
		
		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(BatteryLang, "newCommentSaved"));
		this.props.setSelectedTab("info");
	}
		
	async serverCheckIfBatteryExistsYN(batteryName) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbatterydatafrombatteryname?batteryname=" + batteryName;
		
		const res = await fetch(url);
		const textResponse = await res.text();

		if (textResponse === "[]") {
			return false;
		} else {
			return true;
		}
	}
	
	async onClickSaveButton() {
		
		var commentText = this.state.commentText;
		if (commentText === "") {
			this.props.showToast(this.props.getLang(BatteryLang, "commentTextEmpty"));
			return;
		}
		
		var userID = this.props.userID;
		var userName = this.props.firstName + " " + this.props.lastName;
		userName = userName.trim();
		if (userName === "") {
			userName = this.props.email;
		}	

		this.serverInsertBatteryComment(this.props.batteryID, userID, userName, commentText);
	}
	
	onClickCancelButton() {
		this.props.setSelectedTab("info");
	}
	
	render() {		
		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(BatteryLang, "newComment")}</div>
				<div className="ViewTopText">{this.props.getLang(BatteryLang, "newCommentInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(BatteryLang, "comment")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "batteryName")}</legend> 
							<input
								className="ViewItemInput" 
								id="batteryName" 
								type="text" 
								defaultValue={this.props.batteryName}
								disabled={true}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "comment")}</legend> 
							<textarea
								className="ViewItemInput" 
								id="commentText" 
								rows={15}
								autoFocus={true}
								onChange={(event) => this.onCommentTextChanged(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(BatteryLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(BatteryLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function BatteryNewCommentFunction(props) {
	const navigate = useNavigate();
	return <BatteryNewComment {...props} navigate={navigate} />;
}

