import React  from 'react';
import './App.css';
import './Ride.css';
import RideLang from './RideLang.json';

class RideSettings extends React.Component {

	constructor(props) {

		super(props);

		this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
		this.handleDataIntervalChange = this.handleDataIntervalChange.bind(this);
		this.renderCheckbox = this.renderCheckbox.bind(this);
	}
	
	handleCheckboxClick(variable, event) {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		this.props.setRideSettingsValue(variable, value);
	}
	
	handleDataIntervalChange(event) {
		const target = event.target;
		const value = parseInt(target.value);
		this.props.setRideSettingsValue("dataInterval", value);
	}

	renderCheckbox(title, variable, value) {
		return (
			<div className="RideSettingsContainer2">
				<input 
					type="checkbox" 
					className="RideSettingsCheckbox" 
					id={variable} 
					name={variable} 
					checked={value}
					onChange={(event) => this.handleCheckboxClick(variable, event)}
				/>
				<label htmlFor={variable} className="RideSettingsText">{title}</label>
			</div>
		)
	}
	
	render() {
		
		var sensorThrottleText = this.props.getLang(RideLang, "sensorThrottle");
		var throttleText = this.props.getLang(RideLang, "throttle");
		var escTemperatureText = this.props.getLang(RideLang, "escTemperature");
		var escCurrentText = this.props.getLang(RideLang, "escCurrent");
		var batteryPercentageText = this.props.getLang(RideLang, "batteryPercentage");
		var batteryVoltageText = this.props.getLang(RideLang, "batteryVoltage");
		var batteryCurrentText = this.props.getLang(RideLang, "batteryCurrent");
		var minCellVoltageText = this.props.getLang(RideLang, "minCellVoltage");
		var batteryTemperatureText = this.props.getLang(RideLang, "batteryTemperature");
		var gpsSpeedText = this.props.getLang(RideLang, "gpsSpeed");
		var whPerKmText = this.props.getLang(RideLang, "whPerDistance");

		return (
			<div className="RideSettingsContainer1">
				<div className="RideSettingsContainer2">&nbsp;</div>
				<div className="RideSettingsContainer2">
					<label className="RideSettingsText">{this.props.getLang(RideLang, "dataInterval")}: </label>
				<div className="RideSettingsContainer2">&nbsp;&nbsp;</div>
					<select name="dataInterval" value={this.props.dataInterval} onChange={this.handleDataIntervalChange} className="RideSettingsSelect">
					  <option value="10">10 sec</option>
					  <option value="30">30 sec</option>
					  <option value="60">60 sec</option>
					</select>
				</div>
				{this.renderCheckbox(sensorThrottleText, "sensorThrottleYN", this.props.sensorThrottleYN)}
				{this.renderCheckbox(throttleText, "throttleYN", this.props.throttleYN)}
				{this.renderCheckbox(escTemperatureText, "escTempYN", this.props.escTempYN)}
				{this.renderCheckbox(escCurrentText, "escCurrentYN", this.props.escCurrentYN)}
				{this.renderCheckbox(batteryPercentageText, "batteryPercentageYN", this.props.batteryPercentageYN)}
				{this.renderCheckbox(batteryVoltageText, "batteryVoltageYN", this.props.batteryVoltageYN)}
				{this.renderCheckbox(batteryCurrentText, "batteryCurrentYN", this.props.batteryCurrentYN)}
				{this.renderCheckbox(minCellVoltageText, "minCellVoltageYN", this.props.minCellVoltageYN)}
				{this.renderCheckbox(batteryTemperatureText, "bmsTempYN", this.props.bmsTempYN)}
				{this.renderCheckbox(gpsSpeedText, "gpsSpeedYN", this.props.gpsSpeedYN)}
				{this.renderCheckbox(whPerKmText, "whPerKmYN", this.props.whPerKmYN)}
			</div>
		);
	}
}

export default RideSettings;

