import React  from 'react';
import './App.css';
import BoardLang from './BoardLang.json';

import { useNavigate } from "react-router-dom";

class BoardNew extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			boardNumber: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onBoardNumberChanged = this.onBoardNumberChanged.bind(this);
		this.serverCheckIfBoardExistsYN = this.serverCheckIfBoardExistsYN.bind(this);
		this.serverInsertBoardRecord = this.serverInsertBoardRecord.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onBoardNumberChanged(value) {
		var boardNumber = parseInt(value);
		this.setState({boardNumber: boardNumber});
	}

	async serverInsertBoardRecord(boardNumber) {

		var userID = this.props.userID;
		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/insertboardrecord?boardnumber=" + boardNumber;
		url = url + "&userid=" + userID;
		url = url + "&companyid=" + companyID;
		
		const res = await fetch(url);
		await res.text();
	}
		
	async serverCheckIfBoardExistsYN(boardNumber) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getboarddatafromboardnumber?boardnumber=" + boardNumber;
		
		const res = await fetch(url);
		const textResponse = await res.text();

		if (textResponse === "[]") {
			return false;
		} else {
			return true;
		}
	}
	
	async onClickSaveButton() {
		
		var boardNumber = this.state.boardNumber;
		if (boardNumber === "") {
			boardNumber = 0;
		}
		
		if (boardNumber === 0) {
			this.props.showToast(this.props.getLang(BoardLang, "boardNumberEmpty"));
			return;
		}
		
		var existsYN = await this.serverCheckIfBoardExistsYN(boardNumber);
		if (existsYN) {
			this.props.showToast(this.props.getLang(BoardLang, "boardSameName"));
			return;
		}

		this.serverInsertBoardRecord(boardNumber);
		this.props.showToast(this.props.getLang(BoardLang, "newBoardCreated") + ": \"" + boardNumber + "\"");
		const navigate = this.props.navigate;
  		navigate("/boards");
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/boards");
	}
	
	render() {		
		return (
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(BoardLang, "newBoard")}</div>
				<div className="ViewTopText">{this.props.getLang(BoardLang, "newBoardInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(BoardLang, "boardInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<div style={{height: "20px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "boardNumber")}</legend> 
							<input
								className="ViewItemInput" 
								id="boardNumber" 
								type="number" 
								autoFocus={true}
								onChange={(event) => this.onBoardNumberChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(BoardLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(BoardLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function BoardNewFunction(props) {
	const navigate = useNavigate();
	return <BoardNew {...props} navigate={navigate} />;
}

