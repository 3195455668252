import React from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';
import { useNavigate } from "react-router-dom";

import BatteryListToolbar from './BatteryListToolbar.js';

class BatteryList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			searchString: "",
			index: 0,
			count: 10,
			batteryJsonObj: null,
		};

		this.handleChangeSearchString = this.handleChangeSearchString.bind(this);
		this.handleSelectBattery = this.handleSelectBattery.bind(this);
		this.getBatteryDataFromBatteryNameStart = this.getBatteryDataFromBatteryNameStart.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);	
		this.renderOneBattery = this.renderOneBattery.bind(this);
		this.renderSearchResults = this.renderSearchResults.bind(this);
	}
	
	componentDidMount() {
		this.getBatteryDataFromBatteryNameStart(this.state.searchString, this.state.index, this.state.count);
	}
	
	handleChangeSearchString(event) {
		var searchString = event.target.value;
		this.setState({
			searchString: searchString,
			index: 0,
		});
		this.getBatteryDataFromBatteryNameStart(searchString, this.state.index, this.state.count);
	}

	handleSelectBattery(batteryID) {
		this.props.setSelectedBatteryID(batteryID);
		const navigate = this.props.navigate;
  		navigate("/battery");
	}

	handlePrev() {
		var index = this.state.index - 10;
		this.setState({index: index});
		this.getBatteryDataFromBatteryNameStart(this.state.searchString, index, this.state.count);
	}

	handleNext() {
		var index = this.state.index + 10;
		this.setState({index: index});
		this.getBatteryDataFromBatteryNameStart(this.state.searchString, index, this.state.count);
	}
	
	async getBatteryDataFromBatteryNameStart(searchString, index, count) {
		
		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbatterydatafrombatterynamepart?batterynamepart=" + searchString;
		url = url + "&companyid=" + companyID;
		url = url + "&index=" + index;
		url = url + "&count=" + count;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			batteryJsonObj: jsonObj,
		});
	}
	
	renderOneBattery(batteryID, batteryName, batteryMacAddress) {
		return (
			<tr key={batteryID}>
				<td
					className="BatteryListItemBatteryName"
					onClick={() => this.handleSelectBattery(batteryID)}
				>
					{batteryName}
				</td>
				<td
					className="BatteryListItemBatteryMacAddress"
					onClick={() => this.handleSelectBattery(batteryID)}
				>
					{batteryMacAddress}
				</td>
			</tr>
		);
	}

	renderAllBatterys() {

		var i;
		var batteryJsonObj = this.state.batteryJsonObj;
		var itemObj;
		var batteryID;
		var batteryName;
		var batteryMacAddress;
		var jsxArray = [];

		for(i=0; i<batteryJsonObj.length; i++) {
			itemObj = batteryJsonObj[i];
			batteryID = itemObj.batteryID;
			batteryName = itemObj.batteryName;
			batteryMacAddress = itemObj.batteryMacAddress;
			jsxArray.push(this.renderOneBattery(batteryID, batteryName, batteryMacAddress));
		}

		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="BatteryListHeaderBatteryName">{this.props.getLang(BatteryLang, "batteryName")}</td>
						<td className="BatteryListHeaderBatteryMacAddress">{this.props.getLang(BatteryLang, "macAddress")}</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	renderSearchResults() {

		var batteryCount = 0;
		var batteryJsonObj = this.state.batteryJsonObj;
		
		if (batteryJsonObj !== null) {
			batteryCount = batteryJsonObj.length;
		}
		if (batteryCount === 0) {
			return (<div>{this.props.getLang(BatteryLang, "noBatteriesFound")}</div>);
		}

		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (batteryCount === 10) {
			nextYN = true;
		}

		return (
			<div>
				{this.renderAllBatterys()}
				<div style={{height:"30px"}}></div>
				<div className="ListContainer3">
					{prevYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handlePrev()}
					>
						&lt;&lt; Previous
					</div>)}
					{!prevYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						&lt;&lt; Previous
					</div>)}
					<div className="ListPrevNextLinkDisabled">|</div>
					{nextYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handleNext()}
					>
						Next &gt;&gt;
					</div>)}
					{!nextYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						Next &gt;&gt;
					</div>)}
				</div>
			</div>
		);
	}
	
	render() {

		return (
			<div>
				<BatteryListToolbar
					baseURLAPI={this.props.baseURLAPI}
					rentAppUserType={this.props.rentAppUserType}
					companyIDInit={this.props.companyIDInit}
					companyName={this.props.companyName}
					getLang={this.props.getLang}
				/>
				<div style={{height:"20px"}}></div>
				<form>
					<label style={{fontSize:"20px"}}>
						<b>{this.props.getLang(BatteryLang, "batterySearch")}:</b>
						&nbsp;&nbsp;&nbsp;
						<input type="text" style={{fontSize:"20px"}} value={this.state.searchString} onChange={this.handleChangeSearchString} autoFocus />
					</label>
				</form>
				<div style={{height:"30px"}}></div>
				{this.renderSearchResults()}
				<div style={{height:"30px"}}></div>
			</div>
		);
	}
}

export default function BatteryListFunction(props) {
	const navigate = useNavigate();
	return <BatteryList {...props} navigate={navigate} />;
}
