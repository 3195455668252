import React  from 'react';
import './App.css';
import './Battery.css';
import BatteryLang from './BatteryLang.json';

import { useNavigate } from "react-router-dom";

class BatteryLogAllToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(linkTitle, linkValue) {

		var classNameString = "BatteryLogAllToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var companyName = this.props.companyName;
		
		return (
			<div className="BatteryLogAllToolbarContainer1">
				<div className="BatteryLogAllToolbarLeft">{companyName}</div>
				<div className="BatteryLogAllToolbarRight">
				</div>
			</div>
		);
	}
}

export default function BatteryLogAllToolbarFunction(props) {
	const navigate = useNavigate();
	return <BatteryLogAllToolbar {...props} navigate={navigate} />;
}

