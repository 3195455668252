import React  from 'react';
import './App.css';
import './Battery.css';
import RideLang from './RideLang.json';

import { useNavigate } from "react-router-dom";

class RideListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(linkTitle, linkValue) {

		var classNameString = "RideListToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var companyName = this.props.companyName;
		
		return (
			<div className="RideListToolbarContainer1">
				<div className="RideListToolbarLeft">{companyName}</div>
				<div className="RideListToolbarRight">
				</div>
			</div>
		);
	}
}

export default function RideListToolbarFunction(props) {
	const navigate = useNavigate();
	return <RideListToolbar {...props} navigate={navigate} />;
}

