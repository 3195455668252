import React  from 'react';
import './App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BatteryLang from './BatteryLang.json';

import CompanyPicker from './CompanyPicker.js';
import UserPicker from './UserPicker.js';

import { useNavigate } from "react-router-dom";

class BatteryEdit extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			batteryID: 0,
			batteryName: "",
			batteryMacAddress: "",
			companyID: 0,
			softwareVersion: "",
			lastServiceUnixTimeSec: 0,
			notes: "",
			testedByUserID: 0,
			testedByFullName: "",
			batteryMadeUnixTimeSec: 0,
			batterySoldUnixTimeSec: 0,
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onBatteryNameChanged = this.onBatteryNameChanged.bind(this);
		this.onBatteryMacAddressChanged = this.onBatteryMacAddressChanged.bind(this);
		this.onNfcIDChanged = this.onNfcIDChanged.bind(this);
		this.onCompanyIDChanged = this.onCompanyIDChanged.bind(this);
		this.onTestedByUserIDChanged = this.onTestedByUserIDChanged.bind(this);
		this.onBatteryMadeByUserIDChanged = this.onBatteryMadeByUserIDChanged.bind(this);
		this.lastServiceSetDate = this.lastServiceSetDate.bind(this);
		this.batteryMadeSetDate = this.batteryMadeSetDate.bind(this);
		this.batterySoldSetDate = this.batterySoldSetDate.bind(this);
		this.onNotesChanged = this.onNotesChanged.bind(this);
		this.serverSetBatteryData = this.serverSetBatteryData.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.getDateObjFromUnixTimeSec = this.getDateObjFromUnixTimeSec.bind(this);
		this.handleRentClick = this.handleRentClick.bind(this);
		this.handleTestHighCurrentClick = this.handleTestHighCurrentClick.bind(this);
		this.handleTestCageClick = this.handleTestCageClick.bind(this);
		this.handleTestTemperatureClick = this.handleTestTemperatureClick.bind(this);
		this.handleTestPoolClick = this.handleTestPoolClick.bind(this);
		this.handleTestPressureClick = this.handleTestPressureClick.bind(this);
		this.onBmsVersionChanged = this.onBmsVersionChanged.bind(this);
		this.onDiffChanged = this.onDiffChanged.bind(this);
		this.handleFirestopClick = this.handleFirestopClick.bind(this);
		this.handleBimetalClick = this.handleBimetalClick.bind(this);
		this.handleEspClick = this.handleEspClick.bind(this);
		this.handleScrewTypeClick = this.handleScrewTypeClick.bind(this);
	}
	
	componentDidMount() {
		this.setState({
			batteryID: this.props.batteryID,
			batteryName: this.props.batteryName,
			batteryMacAddress: this.props.batteryMacAddress,
			companyID: this.props.companyID,
			softwareVersion: this.props.softwareVersion,
			lastServiceUnixTimeSec: this.props.lastServiceUnixTimeSec,
			notes: this.props.notes,
			testedByUserID: this.props.testedByUserID,
			testedByFullName: this.props.testedByFullName,
			batteryMadeUnixTimeSec: this.props.batteryMadeUnixTimeSec,
			batterySoldUnixTimeSec: this.props.batterySoldUnixTimeSec,
			batteryMadeByUserID: this.props.batteryMadeByUserID,
			batteryMadeByFullName: this.props.batteryMadeByFullName,
			nfcID: this.props.nfcID,
			screwType: this.props.screwType,
			firestop: this.props.firestop,
			bmsVersion: this.props.bmsVersion,
			esp: this.props.esp,
			diff: this.props.diff,
			bimetal: this.props.bimetal,
			testHighCurrent: this.props.testHighCurrent,
			testCage: this.props.testCage,
			testTemperature: this.props.testTemperature,
			testPool: this.props.testPool,
			testPressure: this.props.testPressure,
			rent: this.props.rent,
		});
	}

	// do we need this?
	componentDidUpdate(prevProps) {

		var batteryID1 = this.state.batteryID;
		var batteryID2 = this.props.batteryID;
		
		if (batteryID1 === batteryID2) {
			return;
		}

		this.setState({
			batteryID: this.props.batteryID,
		});
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	handleRentClick(event) {
		var value = event.target.checked;
		this.setState({rent: value});		
	}
	
	handleTestHighCurrentClick(event) {
		var value = event.target.checked;
		this.setState({testHighCurrent: value});		
	}
	
	handleTestCageClick(event) {
		var value = event.target.checked;
		this.setState({testCage: value});		
	}
	
	handleTestTemperatureClick(event) {
		var value = event.target.checked;
		this.setState({testTemperature: value});		
	}
	
	handleTestPoolClick(event) {
		var value = event.target.checked;
		this.setState({testPool: value});		
	}
	
	handleTestPressureClick(event) {
		var value = event.target.checked;
		this.setState({testPressure: value});		
	}
	
	handleFirestopClick(event) {
		var value = event.target.checked;
		this.setState({firestop: value});		
	}
	
	handleBimetalClick(event) {
		var value = event.target.checked;
		this.setState({bimetal: value});		
	}
	
	handleEspClick(event) {
		var value = event.target.checked;
		this.setState({esp: value});		
	}
	
	handleScrewTypeClick(value) {
		this.setState({screwType: value});		
	}
	
	onBatteryNameChanged(value) {
		this.setState({batteryName: value});
	}
	
	onBmsVersionChanged(value) {
		this.setState({bmsVersion: value});
	}

	onDiffChanged(value) {
		this.setState({diff: value});
	}
	
	onBatteryMacAddressChanged(value) {
		this.setState({batteryMacAddress: value});
	}
	
	onNfcIDChanged(value) {
		this.setState({nfcID: value});
	}
	
	onNotesChanged(value) {
		this.setState({notes: value});
	}
	
	onCompanyIDChanged(companyID) {
		this.setState({companyID: companyID});
	}
	
	onTestedByUserIDChanged(userID, FullName) {
		this.setState({
			testedByUserID: userID,
			testedByFullName: FullName,
		});
	}
	
	onBatteryMadeByUserIDChanged(userID, FullName) {
		this.setState({
			batteryMadeByUserID: userID,
			batteryMadeByFullName: FullName,
		});
	}

	lastServiceSetDate(dateObj) {
		var lastServiceUnixTimeSec = 0;		
		if (dateObj === null) {
			lastServiceUnixTimeSec = null;
		} else {
			lastServiceUnixTimeSec = dateObj.getTime() / 1000;
		}
		this.setState({lastServiceUnixTimeSec: lastServiceUnixTimeSec});
	}
		
	batteryMadeSetDate(dateObj) {
		var batteryMadeUnixTimeSec = 0;		
		if (dateObj === null) {
			batteryMadeUnixTimeSec = null;
		} else {
			batteryMadeUnixTimeSec = dateObj.getTime() / 1000;
		}
		this.setState({batteryMadeUnixTimeSec: batteryMadeUnixTimeSec});
	}
	
	batterySoldSetDate(dateObj) {
		var batterySoldUnixTimeSec = 0;		
		if (dateObj === null) {
			batterySoldUnixTimeSec = null;
		} else {
			batterySoldUnixTimeSec = dateObj.getTime() / 1000;
		}
		this.setState({batterySoldUnixTimeSec: batterySoldUnixTimeSec});
	}	
	
	async serverSetBatteryData(batteryID, batteryName, batteryMacAddress, companyID, lastServiceUnixTimeSec, 
		notes, testedByUserID, testedByFullName, batteryMadeUnixTimeSec, batterySoldUnixTimeSec,
		batteryMadeByUserID, batteryMadeByFullName, nfcID, screwType, firestop, bmsVersion, esp, diff, 
		bimetal, testHighCurrent, testCage, testTemperature, testPool, testPressure, rent) {
			
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setbatterydata?batteryid=" + batteryID;
		url = url + "&batteryname=" + encodeURIComponent(batteryName);
		url = url + "&batterymacaddress=" + encodeURIComponent(batteryMacAddress);
		url = url + "&companyid=" + companyID;
		url = url + "&lastserviceunixtimesec=" + lastServiceUnixTimeSec;
		url = url + "&notes=" + encodeURIComponent(notes);
		url = url + "&testedbyuserid=" + testedByUserID;
		url = url + "&testedbyfullname=" + encodeURIComponent(testedByFullName);
		url = url + "&batterymadeunixtimesec=" + batteryMadeUnixTimeSec;
		url = url + "&batterysoldunixtimesec=" + batterySoldUnixTimeSec;
		url = url + "&batterymadebyuserid=" + batteryMadeByUserID;
		url = url + "&batterymadebyfullname=" + encodeURIComponent(batteryMadeByFullName);
		url = url + "&nfcid=" + encodeURIComponent(nfcID);
		url = url + "&screwtype=" + screwType;
		url = url + "&firestop=" + firestop;
		url = url + "&bmsversion=" + bmsVersion;
		url = url + "&esp=" + esp;
		url = url + "&diff=" + diff;
		url = url + "&bimetal=" + bimetal;
		url = url + "&testhighcurrent=" + testHighCurrent;
		url = url + "&testcage=" + testCage;
		url = url + "&testtemperature=" + testTemperature;
		url = url + "&testpool=" + testPool;
		url = url + "&testpressure=" + testPressure;
		url = url + "&rent=" + rent;
		
		console.log(url);

		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(BatteryLang, "batteryDataSaved"));
		this.props.setSelectedTab("info")	;
		this.props.serverGetBatteryData(batteryID);	
	}
		
	onClickSaveButton() {
		
		var batteryName = this.state.batteryName;
		batteryName = batteryName.trim();

		if (batteryName === "") {
			this.props.showToast(this.props.getLang(BatteryLang, "batteryNameEmpty"));
			return;
		}

		var batteryID = this.state.batteryID;
		var batteryMacAddress = this.state.batteryMacAddress;
		var companyID = this.state.companyID;
		var lastServiceUnixTimeSec = this.state.lastServiceUnixTimeSec;
		var notes = this.state.notes;
		var testedByUserID = this.state.testedByUserID;
		var testedByFullName = this.state.testedByFullName;
		var batteryMadeUnixTimeSec = this.state.batteryMadeUnixTimeSec;
		var batterySoldUnixTimeSec = this.state.batterySoldUnixTimeSec;
		var batteryMadeByUserID = this.state.batteryMadeByUserID;
		var batteryMadeByFullName = this.state.batteryMadeByFullName;
		var nfcID = this.state.nfcID;
		var screwType = this.state.screwType;
		var firestop = this.state.firestop;
		var bmsVersion = this.state.bmsVersion;
		var esp = this.state.esp;
		var diff = this.state.diff;
		var bimetal = this.state.bimetal;
		var testHighCurrent = this.state.testHighCurrent;
		var testCage = this.state.testCage;
		var testTemperature = this.state.testTemperature;
		var testPool = this.state.testPool;
		var testPressure = this.state.testPressure;
		var rent = this.state.rent;

		this.serverSetBatteryData(batteryID, batteryName, batteryMacAddress, companyID, lastServiceUnixTimeSec, notes, testedByUserID, testedByFullName, batteryMadeUnixTimeSec, batterySoldUnixTimeSec, batteryMadeByUserID, batteryMadeByFullName, nfcID, screwType, firestop, bmsVersion, esp, diff, bimetal, testHighCurrent, testCage, testTemperature, testPool, testPressure, rent);
	}
	
	onClickCancelButton() {
		this.props.setSelectedTab("info");
	}
	
	getDateObjFromUnixTimeSec(unixTimeSec) {

		var dateObj;
		
		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			//dateObj = new Date();
			dateObj = null;
		} else {
			dateObj = new Date(unixTimeSec * 1000);
		}
		return dateObj;
	}

	render() {		
	
		if (this.state.batteryID === 0) {
			return null;
		}

		var lastServiceDateObj = this.getDateObjFromUnixTimeSec(this.state.lastServiceUnixTimeSec);
		var batteryMadeDateObj = this.getDateObjFromUnixTimeSec(this.state.batteryMadeUnixTimeSec);
		var batterySoldDateObj = this.getDateObjFromUnixTimeSec(this.state.batterySoldUnixTimeSec);		
		
		//console.log("render - rent = " + this.state.rent);
		
		return (
		<div>
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(BatteryLang, "editBatteryInfo")}</div>
				<div className="ViewTopText">{this.props.getLang(BatteryLang, "editBatteryInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(BatteryLang, "batteryInfo")}</div>
					<div style={{height: "10px"}}></div>
					<div className="ViewContainer2">
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "batteryID")}</legend> 
							<input
								className="ViewItemInput" 
								id="batteryID" 
								type="text" 
								defaultValue={this.state.batteryID}
								disabled={true}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "batteryName")}</legend> 
							<input
								className="ViewItemInput" 
								id="batteryName" 
								type="text" 
								defaultValue={this.state.batteryName}
								onChange={(event) => this.onBatteryNameChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
								autoFocus={true}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "company")}</legend> 
							<CompanyPicker
								className="ViewItemCompany" 
								managementBaseURLAPI={this.props.managementBaseURLAPI}
								companyID={this.state.companyID}
								onChange={this.onCompanyIDChanged}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "macAddress")}</legend> 
							<input
								className="ViewItemInput" 
								id="batteryMacAddress" 
								type="text"
								defaultValue={this.state.batteryMacAddress}
								onChange={(event) => this.onBatteryMacAddressChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "nfcID")}</legend> 
							<input
								className="ViewItemInput" 
								id="nfcID" 
								type="text"
								defaultValue={this.state.nfcID}
								onChange={(event) => this.onNfcIDChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "batteryMadeBy")}</legend> 
							<UserPicker
								className="ViewItemUser" 
								managementBaseURLAPI={this.props.managementBaseURLAPI}
								companyID={1}
								userID={this.state.batteryMadeByUserID}
								userName={this.state.batteryMadeByFullName}
								onChange={this.onBatteryMadeByUserIDChanged}
								getLang={this.props.getLang}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "batteryMade")}</legend> 
							<DatePicker 
								className="ViewItemDateString" 
								selected={batteryMadeDateObj} onChange={(date) => this.batteryMadeSetDate(date)} 
								dateFormat="dd.MM.yyyy."
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "testedBy")}</legend> 
							<UserPicker
								className="ViewItemUser" 
								managementBaseURLAPI={this.props.managementBaseURLAPI}
								companyID={1}
								userID={this.state.testedByUserID}
								userName={this.state.testedByFullName}
								onChange={this.onTestedByUserIDChanged}
								getLang={this.props.getLang}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "batteryTests")}</legend> 
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="testHighCurrent"
								name="testHighCurrent" 
								checked={this.state.testHighCurrent}
								onChange={(event) => this.handleTestHighCurrentClick(event)}
							/>
							<label htmlFor="testHighCurrent" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "testHighCurrent")}</label>
							<br/>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="testCage"
								name="testCage" 
								checked={this.state.testCage}
								onChange={(event) => this.handleTestCageClick(event)}
							/> 
							<label htmlFor="testCage" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "testCage")}</label>
							<br/>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="testTemperature"
								name="testTemperature" 
								checked={this.state.testTemperature}
								onChange={(event) => this.handleTestTemperatureClick(event)}
							/> 
							<label htmlFor="testTemperature" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "testTemperature")}</label>
							<br/>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="testPool"
								name="testPool" 
								checked={this.state.testPool}
								onChange={(event) => this.handleTestPoolClick(event)}
							/> 
							<label htmlFor="testPool" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "testPool")}</label>
							<br/>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="testPressure"
								name="testPressure" 
								checked={this.state.testPressure}
								onChange={(event) => this.handleTestPressureClick(event)}
							/> 
							<label htmlFor="testPressure" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "testPressure")}</label>
						</fieldset>						
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "batterySold")}</legend> 
							<DatePicker 
								className="ViewItemDateString" 
								selected={batterySoldDateObj} onChange={(date) => this.batterySoldSetDate(date)} 
								dateFormat="dd.MM.yyyy."
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "rent")}</legend> 
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="rent"
								name="rent" 
								checked={this.state.rent}
								onChange={(event) => this.handleRentClick(event)}
							/> 
							<label htmlFor="rent" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "rent")}</label>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "lastService")}</legend> 
							<DatePicker 
								className="ViewItemDateString" 
								selected={lastServiceDateObj} onChange={(date) => this.lastServiceSetDate(date)} 
								dateFormat="dd.MM.yyyy."
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "notes")}</legend> 
							<textarea
								className="ViewItemInput" 
								id="notes" 
								defaultValue={this.state.notes}
								rows={15}
								onChange={(event) => this.onNotesChanged(event.target.value)}
						/>
						</fieldset>
					</div>

					<div style={{height: "30px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(BatteryLang, "technicalDetails")}</div>
					<div style={{height: "10px"}}></div>
					<div className="ViewContainer2">
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "bmsVersion")}</legend> 
							<input
								className="ViewItemInput" 
								id="bmsVersion" 
								type="text" 
								defaultValue={this.state.bmsVersion}
								onChange={(event) => this.onBmsVersionChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "diff")}</legend> 
							<input
								className="ViewItemInput" 
								id="diff" 
								type="text" 
								defaultValue={this.state.diff}
								onChange={(event) => this.onDiffChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "screwType")}</legend> 
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="screwType0"
								name="screwType0" 
								checked={this.state.screwType===0}
								onChange={(event) => this.handleScrewTypeClick(0)}
							/>
							<label htmlFor="screwType0" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "screwType0")}</label>
							<br/>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="screwType1"
								name="screwType1" 
								checked={this.state.screwType===1}
								onChange={(event) => this.handleScrewTypeClick(1)}
							/> 
							<label htmlFor="screwType1" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "screwType1")}</label>
						</fieldset>						
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BatteryLang, "internals")}</legend> 
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="firestop"
								name="firestop" 
								checked={this.state.firestop}
								onChange={(event) => this.handleFirestopClick(event)}
							/>
							<label htmlFor="firestop" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "firestop")}</label>
							<br/>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="bimetal"
								name="bimetal" 
								checked={this.state.bimetal}
								onChange={(event) => this.handleBimetalClick(event)}
							/> 
							<label htmlFor="bimetal" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "bimetal")}</label>
							<br/>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="esp"
								name="esp" 
								checked={this.state.esp}
								onChange={(event) => this.handleEspClick(event)}
							/> 
							<label htmlFor="esp" className="ViewItemCheckboxLabel">{this.props.getLang(BatteryLang, "esp")}</label>
						</fieldset>						
					</div>
					<div style={{height: "30px"}}></div>

					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(BatteryLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(BatteryLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		</div>
		);
	}
}

export default function BatteryEditFunction(props) {
	const navigate = useNavigate();
	return <BatteryEdit {...props} navigate={navigate} />;
}

