import React  from 'react';
import './App.css';
import './Geofence.css';
import GeofenceLang from './GeofenceLang.json';

import { useNavigate } from "react-router-dom";

class GeofenceListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(linkTitle, linkValue) {

		var classNameString = "GeofenceListToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var companyName = this.props.companyName;
		var newGeofenceText = this.props.getLang(GeofenceLang, "newGeofence");

		return (
			<div className="GeofenceListToolbarContainer1">
				<div className="GeofenceListToolbarLeft">{companyName}</div>
				<div className="GeofenceListToolbarRight">
					<div className="GeofenceListToolbarContainer2">
						{this.renderLink(newGeofenceText, "/geofences/new")} 
					</div>
				</div>
			</div>
		);
	}
}

export default function GeofenceListToolbarFunction(props) {
	const navigate = useNavigate();
	return <GeofenceListToolbar {...props} navigate={navigate} />;
}

