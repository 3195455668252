import React  from 'react';
import './App.css';
import BoardLang from './BoardLang.json';

import { useNavigate } from "react-router-dom";

var moment = require('moment');

class BoardInfo extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			boardCommentjsonObj: null,
			index: 0,
			count: 10,
		};

		this.serverGetBoardCommentList = this.serverGetBoardCommentList.bind(this);
		this.getDateStringFromUnixTimeSec = this.getDateStringFromUnixTimeSec.bind(this);
		this.getDateTimeStringFromUnixTimeSec = this.getDateTimeStringFromUnixTimeSec.bind(this);
		this.renderOneComment = this.renderOneComment.bind(this);
		this.renderPrevNext = this.renderPrevNext.bind(this);
		this.renderAllComments = this.renderAllComments.bind(this);
	}

	componentDidMount() {
		this.serverGetBoardCommentList(this.props.boardID, this.state.index, this.state.count);
	}
	
	async serverGetBoardCommentList(boardID, index, count) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getboardcommentlist?boardid=" + boardID;
		url = url + "&sortby=boardCommentID&sortasc=0";
		url = url + "&index=" + index + "&count=" + this.state.count;

		const res = await fetch(url);
		const jsonObj = await res.json();
		
		this.setState({
			boardCommentjsonObj: jsonObj,
		});
	}
	
	getDateStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var dateString = moment(dateObj).format('DD.MM.YYYY');
		return dateString;
	}

	getDateTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		// 50 minutes ago

		var dateTimeString = moment(dateObj).format('DD.MM.YYYY h:mm:ss');
		return dateTimeString;
	}

	renderOneComment(boardCommentID, commentText, userName, createdUnixTimeSec, editedUnixTimeSec) {
		
		var commentJSX = this.props.convertTextNewlineToBr(commentText, "comment" + boardCommentID, "BoardInfoItem");
		var dateTimeString = this.getDateTimeStringFromUnixTimeSec(createdUnixTimeSec);

		return (
			<div key={boardCommentID}>
				<hr/>
				<div className="BoardInfoCommentUsername">{userName}</div>
				<div className="BoardInfoCommentText">{commentJSX}</div>
				<div className="BoardInfoCommentDateTime">{dateTimeString}</div>
			</div>
		);
	}
	
	renderPrevNext(prevYN, nextYN) {
		
		if ((!prevYN) && (!nextYN)) {
			return null;
		}
		
		return (
			<div className="ListContainer3">
				{prevYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handlePrev()}
				>
					&lt;&lt; Previous
				</div>)}
				{!prevYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					&lt;&lt; Previous
				</div>)}
				<div className="ListPrevNextLinkDisabled">|</div>
				{nextYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handleNext()}
				>
					Next &gt;&gt;
				</div>)}
				{!nextYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					Next &gt;&gt;
				</div>)}
			</div>
		);
	}
		
	renderAllComments() {
		
		var commentCount = 0;
		var boardCommentjsonObj = this.state.boardCommentjsonObj;

		if (boardCommentjsonObj !== null) {
			commentCount = boardCommentjsonObj.length;
		}
		if (commentCount === 0) {
			return null;
		}

		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (commentCount === this.state.count) {
			nextYN = true;
		}

		var i;
		var itemObj;
		var boardCommentID;
		var userName;
		var commentText;
		var createdUnixTimeSec;
		var editedUnixTimeSec;
		var jsxArray = [];

		for(i=0; i<boardCommentjsonObj.length; i++) {
			itemObj = boardCommentjsonObj[i];
			boardCommentID = itemObj.boardCommentID;
			commentText = itemObj.commentText;
			userName = itemObj.userName;
			createdUnixTimeSec = itemObj.createdUnixTimeSec;
			editedUnixTimeSec = itemObj.editedUnixTimeSec;
			jsxArray.push(this.renderOneComment(boardCommentID, commentText, userName, createdUnixTimeSec, editedUnixTimeSec));
		}

		return (
			<div>
				<div className="">{jsxArray}</div>
				<div style={{height:"30px"}}></div>
				{this.renderPrevNext(prevYN, nextYN)}
			</div>
		);
	}
	
	render() {		

		var boardID = this.props.boardID;
		var boardNumber = this.props.boardNumber;
		var boardMacAddress = this.props.boardMacAddress;
		var companyName = this.props.companyName;
		var directCool = this.props.directCool;
		var multiLog = this.props.multiLog;
		var escSoftwareVersion = this.props.escSoftwareVersion;
		var microSoftwareVersion = this.props.microSoftwareVersion;
		var lastServiceUnixTimeSec = this.props.lastServiceUnixTimeSec;
		var notes = this.props.notes;
		var testedByUserID = this.props.testedByUserID;
		var testedByUserName = this.props.testedByUserName;
		var boardMadeUnixTimeSec = this.props.boardMadeUnixTimeSec;
		var boardSoldUnixTimeSec = this.props.boardSoldUnixTimeSec;

		var boardColor = this.props.boardColor;
		var evaMatColor = this.props.evaMatColor;
		var screwType = this.props.screwType;
		var electronics = this.props.electronics;
		
		var lastServiceDateString = this.getDateStringFromUnixTimeSec(lastServiceUnixTimeSec);
		var boardMadeDateString = this.getDateStringFromUnixTimeSec(boardMadeUnixTimeSec);
		var boardSoldDateString = this.getDateStringFromUnixTimeSec(boardSoldUnixTimeSec);
		var notesJSX = this.props.convertTextNewlineToBr(notes, "notes", "BoardInfoItem");
		
		var screwTypeString;
		if (screwType === 0) {
			screwTypeString = this.props.getLang(BoardLang, "screwType0");
		} else {
			screwTypeString = this.props.getLang(BoardLang, "screwType1");
		}

		return (
			<div>
				<p><b className="BoardInfoTitle">{this.props.getLang(BoardLang, "boardInfo")}</b></p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "boardNumber")}: {boardNumber}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "macAddress")}: {boardMacAddress}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "company")}: {companyName}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "directCool")}: {directCool}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "multiLog")}: {multiLog}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "boardColor")}: {boardColor}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "evaMatColor")}: {evaMatColor}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "screwType")}: {screwTypeString}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "electronics")}: {electronics}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "espSoftwareVersion")}: {escSoftwareVersion}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "microSoftwareVersion")}: {microSoftwareVersion}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "lastService")}: {lastServiceDateString}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "testedBy")}: {testedByUserName} (id: {testedByUserID})</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "boardMade")}: {boardMadeDateString}</p>
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "boardSold")}: {boardSoldDateString}</p>
				
				<p className="BoardInfoItem">{this.props.getLang(BoardLang, "boardIDDB")}: {boardID}</p>

				<div style={{height: "30px"}}></div>
				<div className="BoardInfoItem">{notesJSX}</div>
				<div style={{height: "30px"}}></div>
				{this.renderAllComments()}
			</div>
		);
	}
}

export default function BoardInfoFunction(props) {
	const navigate = useNavigate();
	return <BoardInfo {...props} navigate={navigate} />;
}

