import React  from 'react';
import 'leaflet/dist/leaflet.css';
import './App.css';
import './Geofence.css';
import GeofenceLang from './GeofenceLang.json';
import { MapContainer, TileLayer, Marker, SVGOverlay, LayersControl, LayerGroup, Rectangle, Circle, CircleMarker, Polyline } from 'react-leaflet'
import { getDistance } from 'geolib';
import L from 'leaflet';

import { useNavigate } from "react-router-dom";

import GeofenceMapToolbar from './GeofenceMapToolbar.js';

var index2 = 0;	

class GeofenceMap extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			geofenceID: 0,
			geofenceName: "",
			initPosition: [43.5, 16.4],
			ctrlPressedYN: false,
			mouseDownYN: false,
			measureDistanceYN: false,
			measureDistanceStartPointSelectedYN: false,
			measureDistanceEndPointSelectedYN: false,
			measureDistanceStartLng: 0,
			measureDistanceStartLat: 0,
			measureDistanceEndLng: 0,
			measureDistanceEndLat: 0,
			distance: 0,
			lngArray: [],
			latArray: [],
			pointCount: 0,
			undoArray: [],
			selectedPointIndex: -1,
			mouseOverPointIndex: -1,
			mouseOverGreyPointIndex: -1,
			dragPointIndex: -1,
			dragGreyPointIndex: -1,
			dataLoadedYN: false,
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onKeyUp = this.onKeyUp.bind(this);
		this.undoMap = this.undoMap.bind(this);
		this.addMapToUndo = this.addMapToUndo.bind(this);
		this.serverUpdateMap = this.serverUpdateMap.bind(this);
		this.onGeofenceDeletePoint = this.onGeofenceDeletePoint.bind(this);
		this.separateTextIntoArrayOfLines = this.separateTextIntoArrayOfLines.bind(this);
		this.getLngLatFromLine = this.getLngLatFromLine.bind(this);
		this.serverDownloadGeofenceFile = this.serverDownloadGeofenceFile.bind(this);
		this.serverGetGeofenceData = this.serverGetGeofenceData.bind(this);
		this.startGeofence = this.startGeofence.bind(this);
		this.setMeasureDistanceYN = this.setMeasureDistanceYN.bind(this);
		this.onMeasureDistanceClick = this.onMeasureDistanceClick.bind(this);
		this.onMeasureDistanceMouseMove = this.onMeasureDistanceMouseMove.bind(this);
		this.onGeofenceStartMovePoint = this.onGeofenceStartMovePoint.bind(this);
		this.onGeofenceEndMovePoint = this.onGeofenceEndMovePoint.bind(this);
		this.onGeofenceMouseMove = this.onGeofenceMouseMove.bind(this);
		this.onGeofenceMovePoint = this.onGeofenceMovePoint.bind(this);
		this.onGeofenceMoveGreyPoint = this.onGeofenceMoveGreyPoint.bind(this);
		this.onMouseDownMap = this.onMouseDownMap.bind(this);
		this.onMouseUpMap = this.onMouseUpMap.bind(this);
		this.onMouseMoveMap = this.onMouseMoveMap.bind(this);
		this.calcGreyPoint = this.calcGreyPoint.bind(this);
		this.renderMeasureDistance = this.renderMeasureDistance.bind(this);
		this.renderGeofenceOnePoint = this.renderGeofenceOnePoint.bind(this);
		this.renderGeofence = this.renderGeofence.bind(this);
	}
	
	componentDidMount() {

		var geofenceID = this.props.geofenceID;
		this.setState({geofenceID: geofenceID});
		this.serverGetGeofenceData(geofenceID);

		document.onkeydown = (e) => {
			this.onKeyDown(e.keyCode);
		}

		document.onkeyup = (e) => {
			this.onKeyUp(e.keyCode);
		}
	}
	
	onKeyDown(keyCode) {
		
		var ctrlPressedYN = this.state.ctrlPressedYN;

		// delete
		if (keyCode === 46) {
			this.onGeofenceDeletePoint();
		}
		
		// ctrl
		if (keyCode === 17) {
			this.setState({ctrlPressedYN: true});
		}

		// ctrl + Z
		if (ctrlPressedYN && (keyCode === 90)) {
			this.undoMap();
		}
	}
	
	onKeyUp(keyCode) {		

		// ctrl
		if (keyCode === 17) {
			this.setState({ctrlPressedYN: false});
		}
	}
	
	undoMap() {

		var undoArray = this.state.undoArray;
		var length = undoArray.length;
		if (length < 1) {
			return;
		}

		var itemObj = undoArray.pop();

		var lngArray = itemObj.lngArray;
		var latArray = itemObj.latArray;
		var pointCount = itemObj.pointCount;

		this.setState({
			undoArray: undoArray,
			lngArray: lngArray,
			latArray: latArray,
			pointCount: pointCount,
			mouseOverPointIndex: -1,
			mouseOverGreyPointIndex: -1,
			dragPointIndex: -1,
			dragGreyPointIndex: -1,
			selectedPointIndex: -1,
		});
		
		this.serverUpdateMap(this.state.geofenceID, lngArray, latArray, pointCount);
	}
	
	addMapToUndo(lngArray, latArray, pointCount) {
		
		var selectedPointIndex = this.state.selectedPointIndex;

		var itemObj = {};
		var lngArray2 = [...lngArray];
		var latArray2 = [...latArray];
		
		itemObj.lngArray = lngArray2;
		itemObj.latArray = latArray2;
		itemObj.pointCount = pointCount;

		var undoArray = this.state.undoArray;
		undoArray.push(itemObj);
		
		this.setState({undoArray: undoArray});
	}
	
	async serverUpdateMap(geofenceID, lngArray, latArray, pointCount) {
		
		if (geofenceID <= 0) {
			return;
		}

		var userID = this.props.userID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setgeofencepoints?geofenceid=" + geofenceID;
		url = url + "&pointcount=" + pointCount;
		url = url + "&editeduserid=" + userID;
		
		var fileText = "";

		var i;
		var gpsLng;
		var gpsLat;

		for(i=0; i<pointCount; i++) {
			gpsLng = lngArray[i];
			gpsLat = latArray[i];
			fileText = fileText + gpsLng + "," + gpsLat + "\n";
		}
		
		var params = "filetext=" + fileText;
		
		var res = await fetch(url, {
			method: "POST",
			headers: {
			  "Content-Type": 'application/x-www-form-urlencoded',
			},
			body: params,
		});
		await res.text();
	}

	onGeofenceDeletePoint() {

		var index = this.state.selectedPointIndex;
		var lngArray = this.state.lngArray;
		var latArray = this.state.latArray;
		var pointCount = this.state.pointCount;
		
		if ((index < 0) || (index >= pointCount)) {
			return;
		}
		
		if (pointCount <= 4) {
			return;
		}

		this.addMapToUndo(lngArray, latArray, pointCount);

		var i;
		for(i=index; i<pointCount-1; i++) {
			lngArray[i] = lngArray[i+1];
			latArray[i] = latArray[i+1];
		}

		pointCount = pointCount - 1;
		
		this.setState({
			lngArray: lngArray,
			latArray: latArray,
			pointCount: pointCount,
			mouseOverPointIndex: -1,
			mouseOverGreyPointIndex: -1,
			dragPointIndex: -1,
			dragGreyPointIndex: -1,
			selectedPointIndex: -1,
		});

		this.serverUpdateMap(this.state.geofenceID, lngArray, latArray, pointCount);
	}

	separateTextIntoArrayOfLines(inputText) {

		var i;
		var ch;
		var len = inputText.length;
		var oneLineText = "";
		var lineArray = [];
		
		for(i=0; i<len; i++) {
			ch = inputText[i];
			if (ch == "\n") {
				lineArray.push(oneLineText);
				oneLineText = "";
			} else {
				oneLineText = oneLineText + ch;
			}
		}

		return lineArray;
	}
	
	getLngLatFromLine(oneLine) {
		
		var i;
		var ch;
		var gpsLngString = "";
		var gpsLatString = "";
		var colonFoundYN = false;
		
		for(i=0; i<oneLine.length; i++) {
			ch = oneLine.charAt(i);
			if (ch === ",") {
				colonFoundYN = true;
			} else {
				if (!colonFoundYN) {
					gpsLngString = gpsLngString + ch;
				} else {
					gpsLatString = gpsLatString + ch;
				}
			}
		}
		
		var gpsLng = parseFloat(gpsLngString);
		var gpsLat = parseFloat(gpsLatString);
		var results = [gpsLng, gpsLat];
		return results;
	}

	async serverDownloadGeofenceFile(geofenceID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/geofences/" + geofenceID + ".txt";
		
		const res = await fetch(url);
		const fileText = await res.text();
		
		if (res.status !== 200) {
			this.setState({
				dataLoadedYN: true,
			});
			return;
		}
		
		if (fileText === "") {
			this.setState({
				dataLoadedYN: true,
			});
			return;
		}

		var lineArray = this.separateTextIntoArrayOfLines(fileText);
		
		var lngArray = [];
		var latArray = [];
		var pointCount = 0;
		
		var i;
		var oneLine;
		var results;
		var gpsLng;
		var gpsLat;
		
		for(i=0; i<lineArray.length; i++) {
			oneLine = lineArray[i];
			results = this.getLngLatFromLine(oneLine);
			gpsLng = results[0];
			gpsLat = results[1];
			if (Math.abs(gpsLng) > 0.01) {
				lngArray.push(gpsLng);
				latArray.push(gpsLat);
				pointCount = pointCount + 1;
			}
		}
		
		var gpsLngSum = 0;
		var gpsLatSum = 0;
		var gpsLngAverage;
		var gpsLatAverage;

		for(i=0; i<pointCount; i++) {
			gpsLng = lngArray[i];
			gpsLat = latArray[i];
			gpsLngSum = gpsLngSum + gpsLng;
			gpsLatSum = gpsLatSum + gpsLat;
		}

		gpsLngAverage = gpsLngSum / pointCount;
		gpsLatAverage = gpsLatSum / pointCount;

		var initPosition = [gpsLatAverage, gpsLngAverage];
		
		this.setState({
			lngArray: lngArray,
			latArray: latArray,
			pointCount: pointCount,
			initPosition: initPosition,
			dataLoadedYN: true,
		});
	}

	async serverGetGeofenceData(geofenceID) {

		if (geofenceID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getgeofencedata?geofenceid=" + geofenceID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var geofenceName = jsonObj.geofenceName;
		
		this.setState({
			geofenceName: geofenceName,
		});
		
		this.serverDownloadGeofenceFile(geofenceID);
	}

	startGeofence() {

		var lngArray = [];
		var latArray = [];
		
		var map = this.mapReference;
		var centerPosition = map.getCenter();
		var centerLat = centerPosition.lat;
		var centerLng = centerPosition.lng;
		var dx = 0.001;
		var dy = 0.0006;
		
		lngArray.push(centerLng - dx);
		latArray.push(centerLat - dy);
		
		lngArray.push(centerLng + dx);
		latArray.push(centerLat - dy);
		
		lngArray.push(centerLng + dx);
		latArray.push(centerLat + dy);
		
		lngArray.push(centerLng - dx);
		latArray.push(centerLat + dy);
		
		var pointCount = lngArray.length;

		this.setState({
			lngArray: lngArray,
			latArray: latArray,
			pointCount: pointCount,
		});

		this.serverUpdateMap(this.state.geofenceID, this.state.lngArray, this.state.latArray, this.state.pointCount);
	}
		
	setMeasureDistanceYN(measureDistanceYN) {
		this.setState({
			measureDistanceYN: measureDistanceYN,
			measureDistanceStartPointSelectedYN: false,
			measureDistanceEndPointSelectedYN: false,
		});
	}
	
	onMeasureDistanceClick(lng, lat) {
		
		var measureDistanceStartPointSelectedYN = this.state.measureDistanceStartPointSelectedYN;
		var measureDistanceEndPointSelectedYN = this.state.measureDistanceEndPointSelectedYN;
		
		if (measureDistanceEndPointSelectedYN) {
			measureDistanceStartPointSelectedYN = false;
		}
		
		if (measureDistanceStartPointSelectedYN) {
			this.setState({
				measureDistanceEndPointSelectedYN: true,
			});
			return;
		}

		this.setState({
			measureDistanceStartLng: lng,
			measureDistanceStartLat: lat,
			measureDistanceEndLng: lng,
			measureDistanceEndLat: lat,
			measureDistanceStartPointSelectedYN: true,
			measureDistanceEndPointSelectedYN: false,
		});
	}
	
	onMeasureDistanceMouseMove(lng, lat) {

		var measureDistanceStartPointSelectedYN = this.state.measureDistanceStartPointSelectedYN;
		if (!measureDistanceStartPointSelectedYN) {
			return;
		}
		
		var measureDistanceEndPointSelectedYN = this.state.measureDistanceEndPointSelectedYN;
		if (measureDistanceEndPointSelectedYN) {
			return;
		}

		var measureDistanceStartLng = this.state.measureDistanceStartLng;
		var measureDistanceStartLat = this.state.measureDistanceStartLat;
		var point1 = {latitude: measureDistanceStartLat, longitude: measureDistanceStartLng};
		var point2 = {latitude: lat, longitude: lng};
		var distance = getDistance(point1, point2);

		this.setState({
			measureDistanceEndLng: lng,
			measureDistanceEndLat: lat,
			distance: distance,
		});
	}
	
	onGeofenceStartMovePoint() {

		this.addMapToUndo( this.state.lngArray, this.state.latArray, this.state.pointCount);

		var map = this.mapReference;
		map.dragging.disable();

		var mouseOverPointIndex = this.state.mouseOverPointIndex;
		if (mouseOverPointIndex !== -1) {
			this.setState({
				dragPointIndex: mouseOverPointIndex,
				selectedPointIndex: mouseOverPointIndex,
			});
		}
		
		var mouseOverGreyPointIndex = this.state.mouseOverGreyPointIndex;
		if (mouseOverGreyPointIndex !== -1) {
			this.setState({dragGreyPointIndex: mouseOverGreyPointIndex});
		}
	}
	
	onGeofenceEndMovePoint() {
		var map = this.mapReference;
		map.dragging.enable();
		this.setState({
			dragPointIndex: -1,
			dragGreyPointIndex: -1,
		});
		this.serverUpdateMap(this.state.geofenceID, this.state.lngArray, this.state.latArray, this.state.pointCount);
	}

	onGeofenceMovePoint(index, lng, lat) {
		
		var lngArray = this.state.lngArray;
		var latArray = this.state.latArray;
		var pointCount = this.state.pointCount;
		
		if ((index < 0) || (index >= pointCount)) {
			return;
		}
		
		lngArray[index] = lng;
		latArray[index] = lat;
		
		this.setState({
			lngArray: lngArray,
			latArray: latArray,
		});
	}
	
	onGeofenceMoveGreyPoint(index, lng, lat) {

		var lngArray = this.state.lngArray;
		var latArray = this.state.latArray;
		var pointCount = this.state.pointCount;
		
		if ((index < 0) || (index >= pointCount)) {
			return;
		}

		var i;
		for(i=pointCount-1; i>=index; i--) {
			lngArray[i+1] = lngArray[i];
			latArray[i+1] = latArray[i];
		}

		lngArray[index+1] = lng;
		latArray[index+1] = lat;
		pointCount = pointCount + 1;
		
		this.setState({
			lngArray: lngArray,
			latArray: latArray,
			pointCount: pointCount,
			mouseOverPointIndex: index+1,
			mouseOverGreyPointIndex: -1,
			dragPointIndex: index+1,
			dragGreyPointIndex: -1,
		});
	}
		
	onGeofenceMouseMove(lng, lat) {

		var map = this.mapReference;
		var mousePosition = [lat, lng];
		var mouseCoord = map.latLngToLayerPoint(mousePosition)
		
		var pointCount = this.state.pointCount;
		var gpsLng;
		var gpsLat;
		
		var i;
		var pointPosition;
		var pointCoord;
		var dx;
		var dy;
		var mouseOverPointIndex = -1;
		var mouseOverGreyPointIndex = -1;
		var greyPointPosition;
		var greyPointCoord;
		
		for(i=0; i<pointCount; i++) {
			gpsLng = this.state.lngArray[i];
			gpsLat = this.state.latArray[i];
			pointPosition = [gpsLat, gpsLng];
			pointCoord = map.latLngToLayerPoint(pointPosition)
			dx = Math.abs(mouseCoord.x - pointCoord.x);
			dy = Math.abs(mouseCoord.y - pointCoord.y);
			if ((dx < 7) && (dy < 7)) {
				mouseOverPointIndex = i;
			}
			greyPointPosition = this.calcGreyPoint(i);
			greyPointCoord = map.latLngToLayerPoint(greyPointPosition);
			dx = Math.abs(mouseCoord.x - greyPointCoord.x);
			dy = Math.abs(mouseCoord.y - greyPointCoord.y);
			if ((dx < 7) && (dy < 7)) {
				mouseOverGreyPointIndex = i;
			}
		}

		if (mouseOverPointIndex !== -1) {
			mouseOverGreyPointIndex = -1;
		}

		this.setState({
			mouseOverPointIndex: mouseOverPointIndex,
			mouseOverGreyPointIndex: mouseOverGreyPointIndex,
		});
	}
	
	onMouseDownMap(e) {

		this.setState({mouseDownYN: true});
		
		var measureDistanceYN = this.state.measureDistanceYN;
		var { lat, lng } = e.latlng;
		if (measureDistanceYN) {
			this.onMeasureDistanceClick(lng, lat);
			return;
		}

		var mouseOverPointIndex = this.state.mouseOverPointIndex;
		var mouseOverGreyPointIndex = this.state.mouseOverGreyPointIndex;
		if ((mouseOverPointIndex === -1) && (mouseOverGreyPointIndex === -1)) {
			return;
		}

		this.onGeofenceStartMovePoint();
	}

	onMouseUpMap(e) {

		this.setState({mouseDownYN: false});

		var mouseOverPointIndex = this.state.mouseOverPointIndex;
		var mouseOverGreyPointIndex = this.state.mouseOverGreyPointIndex;
		if ((mouseOverPointIndex !== -1) || (mouseOverGreyPointIndex !== -1)) {
			this.onGeofenceEndMovePoint();
		}
	}

	onMouseMoveMap(e) {
		var measureDistanceYN = this.state.measureDistanceYN;
		var { lat, lng } = e.latlng;
		if (measureDistanceYN) {
			this.onMeasureDistanceMouseMove(lng, lat);
		} else {
			var dragPointIndex = this.state.dragPointIndex;
			var dragGreyPointIndex = this.state.dragGreyPointIndex;
			if (dragPointIndex !== -1) {
				this.onGeofenceMovePoint(dragPointIndex, lng, lat);
				return;
			}
			if (dragGreyPointIndex !== -1) {
				this.onGeofenceMoveGreyPoint(dragGreyPointIndex, lng, lat);
				return;
			};
			this.onGeofenceMouseMove(lng, lat);
		}
	}
	
	renderMeasureDistance() {

		var measureDistanceStartPointSelectedYN = this.state.measureDistanceStartPointSelectedYN;
		if (!measureDistanceStartPointSelectedYN) {
			return;
		}

		var startLng = this.state.measureDistanceStartLng;
		var startLat = this.state.measureDistanceStartLat;
		var endLng = this.state.measureDistanceEndLng;
		var endLat = this.state.measureDistanceEndLat;
		var distance = this.state.distance;

		var startPosition = [startLat, startLng];
		var endPosition = [endLat, endLng];

		var polyline = [startPosition, endPosition];
		var polylineOptions = { color: 'blue', weight: '1',  dashArray: '5, 5', dashOffset: '0' }

		var distanceText = distance + "m";
		var textForIcon = L.divIcon({iconSize: [50,18], iconAnchor: [-20, 10], html: distanceText});
		// labelAnchor: [-100, 0], popupAnchor: [100,-130], 

		return (
			<LayerGroup>
				<CircleMarker center={startPosition} fillColor="blue" radius={5} />
				<CircleMarker center={endPosition} fillColor="blue" radius={5}/>
				<Marker position={endPosition} icon={textForIcon} />
				<Polyline pathOptions={polylineOptions} positions={polyline} />
			</LayerGroup>
		);
	}
	
	calcGreyPoint(index) {

		var pointCount = this.state.pointCount;
		var gpsLng1 = this.state.lngArray[index];
		var gpsLat1 = this.state.latArray[index];
		var gpsLng2;
		var gpsLat2;

		if (index >= pointCount-1) {
			gpsLng2 = this.state.lngArray[0];
			gpsLat2 = this.state.latArray[0];
		} else {
			gpsLng2 = this.state.lngArray[index+1];
			gpsLat2 = this.state.latArray[index+1];
		}

		var gpsLngGrey = (gpsLng1 + gpsLng2) / 2;
		var gpsLatGrey = (gpsLat1 + gpsLat2) / 2;
		var positionGrey = [gpsLatGrey, gpsLngGrey];
		
		return positionGrey;
	}

	renderGeofenceOnePoint(index, index2) {

		var pointCount = this.state.pointCount;
		var gpsLng1 = this.state.lngArray[index];
		var gpsLat1 = this.state.latArray[index];
		var gpsLng2;
		var gpsLat2;
		var position2;
		var gpsLngGrey;
		var gpsLatGrey;
		var positionGrey;
		var polyline;
		var polylineOptions = { color: 'blue', weight: '1' }
		var color1;
		var color2;
		var selectedPointIndex = this.state.selectedPointIndex;
		var mouseOverPointIndex = this.state.mouseOverPointIndex;
		var mouseOverGreyPointIndex = this.state.mouseOverGreyPointIndex;
		
		var position1 = [gpsLat1, gpsLng1];

		if (index >= pointCount-1) {
			gpsLng2 = this.state.lngArray[0];
			gpsLat2 = this.state.latArray[0];
		} else {
			gpsLng2 = this.state.lngArray[index+1];
			gpsLat2 = this.state.latArray[index+1];
		}

		position2 = [gpsLat2, gpsLng2];
		polyline = [position1, position2];

		positionGrey = this.calcGreyPoint(index);
		
		color1 = "#5050ff";
		if (index === mouseOverPointIndex) {
			color1 = "red";
		} else {
			if (index === selectedPointIndex) {
			color1 = "black";
			}
		}
		
		color2 = "grey";
		if (index === mouseOverGreyPointIndex) {
			color2 = "red";
		}
		
		// fillColor="blue" 
		return (
			<LayerGroup key={index2}>
				<CircleMarker center={position1} color={color1} radius={7} />
				<CircleMarker center={positionGrey} color={color2} radius={4} />
				<Polyline pathOptions={polylineOptions} positions={polyline} />
			</LayerGroup>
		);
	}

	renderGeofence() {
		
		var i;
		var pointCount = this.state.pointCount;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<pointCount; i++) {
			jsxCode = this.renderGeofenceOnePoint(i, index2);
			jsxArray.push(jsxCode);			
			index2 = index2 + 1;
		}

		return jsxArray;
	}
	
	render() {
		
		var dataLoadedYN = this.state.dataLoadedYN;
		if (!dataLoadedYN) {
			return null;
		}
		
		var initPosition = this.state.initPosition;
		var pointCount = this.state.pointCount;
		var zoom = 13;
		var displayStartGeofenceYN = false;
		
		if (pointCount === 0) {
			displayStartGeofenceYN = true;
			zoom = 13;
		}
		
		//<div onKeyDown={(event) => this.onKeyDown(event.keyCode)}>
		//map.target.on("keypress", e => this.onKeyDown(e));
		return (
			<div>
				<GeofenceMapToolbar
					baseURLAPI={this.props.baseURLAPI}
					geofenceID={this.state.geofenceID}
					geofenceName={this.state.geofenceName}
					displayStartGeofenceYN={displayStartGeofenceYN}
					measureDistanceYN={this.state.measureDistanceYN}
					startGeofence={this.startGeofence}
					setMeasureDistanceYN={this.setMeasureDistanceYN}
					getLang={this.props.getLang}
				/>
				<div style={{height: "1px"}}></div>
				<MapContainer 
					tabindex={0}
					className="MapContainer" 
					center={initPosition} 
					zoom={zoom} 
					scrollWheelZoom={true} 
					ref={mapReference => this.mapReference = mapReference}
					whenReady={(map) => {
						map.target.on("mousedown", e => this.onMouseDownMap(e));
						map.target.on("mouseup", e => this.onMouseUpMap(e));
						map.target.on("mousemove", e => this.onMouseMoveMap(e));
					}}>
					<TileLayer
					  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
					/>
					{this.renderMeasureDistance()}
					{this.renderGeofence()}
				</MapContainer>
			</div>
		);
	}
}

export default function GeofenceMapFunction(props) {
	const navigate = useNavigate();
	return <GeofenceMap {...props} navigate={navigate} />;
}

