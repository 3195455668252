import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { useNavigate } from "react-router-dom";

class BatteryLogStats extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			totalVoltageAverage: 0,
			totalVoltageMax: 0,
			currentAverage: 0,
			currentMax: 0,
			residualCapacityAverage: 0,
			residualCapacityMax: 0,
			socAverage: 0,
			socMax: 0,
			cycleTimesMax: 0,
			minCellVoltageValueAverage: 0,
			minCellVoltageValueMax: 0,
			temp1Average: 0,
			temp1Max: 0,
			temp2Average: 0,
			temp2Max: 0,
			temp3Average: 0,
			temp3Max: 0,
			temp4Average: 0,
			temp4Max: 0,
			temp5Average: 0,
			temp5Max: 0,
			temp6Average: 0,
			temp6Max: 0,
			tempMaxAverage: 0,
			tempMaxMax: 0,
		}

		this.loadData = this.loadData.bind(this);
		this.formatDateTimeString = this.formatDateTimeString.bind(this);
		this.twoDigits = this.twoDigits.bind(this);
		this.getTimeStringFromSeconds = this.getTimeStringFromSeconds.bind(this);
		this.handleClickDeleteButton = this.handleClickDeleteButton.bind(this);
		this.serverDeleteBatteryLogRecord = this.serverDeleteBatteryLogRecord.bind(this);
	}
	
	componentDidMount() {
		this.loadData();
	}

	loadData() {

		var i;
		var dataCount = this.props.dataCount;

		var totalVoltage = 0;
		var totalVoltageSum = 0;
		var totalVoltageCountForAverage = 0;
		var totalVoltageAverage = 0;
		var totalVoltageMax = 0;
		
		var current = 0;
		var currentSum = 0;
		var currentCountForAverage = 0;
		var currentAverage = 0;
		var currentMax = 0;
		
		var residualCapacity = 0;
		var residualCapacitySum = 0;
		var residualCapacityAverage = 0;
		var residualCapacityMax = 0;
		
		var cycleTimes = 0;
		var cycleTimesMax = 0;
		
		var soc = 0;
		var socSum = 0;
		var socAverage = 0;
		var socMax = 0;
		
		var minCellVoltageValue = 0;
		var minCellVoltageValueSum = 0;
		var minCellVoltageValueAverage = 0;
		var minCellVoltageValueMax = 0;
		
		var temp1 = 0;
		var temp1Sum = 0;
		var temp1Average = 0;
		var temp1Max = 0;
		
		var temp2 = 0;
		var temp2Sum = 0;
		var temp2Average = 0;
		var temp2Max = 0;
		
		var temp3 = 0;
		var temp3Sum = 0;
		var temp3Average = 0;
		var temp3Max = 0;
		
		var temp4 = 0;
		var temp4Sum = 0;
		var temp4Average = 0;
		var temp4Max = 0;
		
		var temp5 = 0;
		var temp5Sum = 0;
		var temp5Average = 0;
		var temp5Max = 0;
		
		var temp6 = 0;
		var temp6Sum = 0;
		var temp6Average = 0;
		var temp6Max = 0;
		
		var tempMax = 0;
		var tempMaxSum = 0;
		var tempMaxAverage = 0;
		var tempMaxMax = 0;
		
		for(i=0; i<dataCount; i++) {
			
			totalVoltage = this.props.totalVoltageArray[i];
			current = this.props.currentArray[i];
			residualCapacity = this.props.residualCapacityArray[i];
			soc = this.props.socArray[i];
			cycleTimes = this.props.cycleTimesArray[i];
			minCellVoltageValue = this.props.minCellVoltageValueArray[i];
			temp1 = this.props.temp1Array[i];
			temp2 = this.props.temp2Array[i];
			temp3 = this.props.temp3Array[i];
			temp4 = this.props.temp4Array[i];
			temp5 = this.props.temp5Array[i];
			temp6 = this.props.temp6Array[i];
			tempMax = this.props.tempMaxArray[i];

			if (totalVoltage > 1) {
				totalVoltageCountForAverage = totalVoltageCountForAverage + 1;
				totalVoltageSum += totalVoltage;
			}
			if (totalVoltage > totalVoltageMax) {
				totalVoltageMax = totalVoltage;
			}
			
			if (current > 3.0) {
				currentCountForAverage = currentCountForAverage + 1;
				currentSum += current;
			}
			if (current > currentMax) {
				currentMax = current;
			}
			
			residualCapacitySum += residualCapacity;
			if (residualCapacity > residualCapacityMax) {
				residualCapacityMax = residualCapacity;
			}
			
			socSum += soc;
			if (soc > socMax) {
				socMax = soc;
			}
			
			if (cycleTimes > cycleTimesMax) {
				cycleTimesMax = cycleTimes;
			}
			
			minCellVoltageValueSum += minCellVoltageValue;
			if (minCellVoltageValue > minCellVoltageValueMax) {
				minCellVoltageValueMax = minCellVoltageValue;
			}
			
			temp1Sum += temp1;
			if (temp1 > temp1Max) {
				temp1Max = temp1;
			}
			
			temp2Sum += temp2;
			if (temp2 > temp2Max) {
				temp2Max = temp2;
			}
			
			temp3Sum += temp3;
			if (temp3 > temp3Max) {
				temp3Max = temp3;
			}
			
			temp4Sum += temp4;
			if (temp4 > temp4Max) {
				temp4Max = temp4;
			}
			
			temp5Sum += temp5;
			if (temp5 > temp5Max) {
				temp5Max = temp5;
			}
			
			temp6Sum += temp6;
			if (temp6 > temp6Max) {
				temp6Max = temp6;
			}
			
			tempMaxSum += tempMax;
			if (tempMax > tempMaxMax) {
				tempMaxMax = tempMax;
			}
			
			/*if (minCellVoltage > 1.0) {
				minCellVoltageSum += minCellVoltage;
				minCellVoltageCountForAverage = minCellVoltageCountForAverage + 1;
				if (minCellVoltage < minCellVoltageMin) {
					if (minCellVoltage > 0) {
						minCellVoltageMin = minCellVoltage;
					}
				}
				if (minCellVoltage > minCellVoltageMax) {
					minCellVoltageMax = minCellVoltage;
				}
			}*/
		}
		
		if (totalVoltageCountForAverage === 0) {
			totalVoltageAverage = 0;
		} else {
			totalVoltageAverage = totalVoltageSum / totalVoltageCountForAverage;
		}
		totalVoltageAverage = Math.round(totalVoltageAverage * 10) / 10;

		if (currentCountForAverage === 0) {
			currentAverage = 0;
		} else {
			currentAverage = currentSum / currentCountForAverage;
		}
		currentAverage = Math.round(currentAverage);

		residualCapacityAverage = residualCapacitySum / dataCount;
		residualCapacityAverage = Math.round(residualCapacityAverage);

		socAverage = socSum / dataCount;
		socAverage = Math.round(socAverage);

		minCellVoltageValueAverage = minCellVoltageValueSum / dataCount;
		minCellVoltageValueAverage = Math.round(minCellVoltageValueAverage * 100) / 100;

		temp1Average = temp1Sum / dataCount;
		temp1Average = Math.round(temp1Average);

		temp2Average = temp2Sum / dataCount;
		temp2Average = Math.round(temp2Average);

		temp3Average = temp3Sum / dataCount;
		temp3Average = Math.round(temp3Average);

		temp4Average = temp4Sum / dataCount;
		temp4Average = Math.round(temp4Average);

		temp5Average = temp5Sum / dataCount;
		temp5Average = Math.round(temp5Average);

		temp6Average = temp6Sum / dataCount;
		temp6Average = Math.round(temp6Average);

		tempMaxAverage = tempMaxSum / dataCount;
		tempMaxAverage = Math.round(tempMaxAverage);

		this.setState({
			totalVoltageAverage: totalVoltageAverage,
			totalVoltageMax: totalVoltageMax,
			currentAverage: currentAverage,
			currentMax: currentMax,
			residualCapacityAverage: residualCapacityAverage,
			residualCapacityMax: residualCapacityMax,
			socAverage: socAverage,
			socMax: socMax,
			cycleTimesMax: cycleTimesMax,
			minCellVoltageValueAverage: minCellVoltageValueAverage,
			minCellVoltageValueMax: minCellVoltageValueMax,
			temp1Average: temp1Average,
			temp1Max: temp1Max,
			temp2Average: temp2Average,
			temp2Max: temp2Max,
			temp3Average: temp3Average,
			temp3Max: temp3Max,
			temp4Average: temp4Average,
			temp4Max: temp4Max,
			temp5Average: temp5Average,
			temp5Max: temp5Max,
			temp6Average: temp6Average,
			temp6Max: temp6Max,
			tempMaxAverage: tempMaxAverage,
			tempMaxMax: tempMaxMax,
		});
	}

	formatDateTimeString(timestamp) {
		
		var timestampString;
		
		var dateString = timestamp.substr(0, 10);
		var timeString = timestamp.substr(11, 8);

		timestampString = dateString + " " + timeString;
		
		return timestampString;
	}
	
	twoDigits(value1) {
		if (value1 < 10) {
			return "0" + value1;
		} else {
			return value1;
		}
	}

	getTimeStringFromSeconds(seconds) {

		var hours;
		var minutes;
		var secondsLeft;
		var timeString = "";
	
		hours = Math.floor(seconds / 3600);
		secondsLeft = seconds - hours * 3600;

		minutes = Math.floor(secondsLeft / 60);
		seconds = secondsLeft - minutes * 60;
	
		if (hours > 0) {
			timeString = hours + ":";
		}
	
		timeString = timeString + this.twoDigits(minutes) + ":" + this.twoDigits(seconds);
		return timeString;
	}
	
	handleClickDeleteButton() {
		
		var batteryLogID = this.props.batteryLogID;

		confirmAlert({
			title: this.props.getLang(BatteryLang, "deleteBatteryLog"),
			message: this.props.getLang(BatteryLang, "deleteBatteryLogAreYouSure"),
			buttons: [
				{
					label: this.props.getLang(BatteryLang, "yes"),
					onClick: () => this.serverDeleteBatteryLogRecord(batteryLogID)
				},
				{
					label: this.props.getLang(BatteryLang, "no"),
				}
			]
		});
		
	}

	async serverDeleteBatteryLogRecord(batteryLogID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deletebatterylogrecord?batterylogid=" + batteryLogID;
		
		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(BatteryLang, "batteryLogDeleted") + ": \"" + batteryLogID + "\"");
		const navigate = this.props.navigate;
  		navigate("/batterylogall");
	}

	render() {

		var batteryLogID = this.props.batteryLogID;		
		var unixTimeSec = this.props.unixTimeSec;
		var uploadedUnixTimeSec = this.props.uploadedUnixTimeSec;
		var batteryName = this.props.batteryName;
		var operation = this.props.operation;
		var durationSec = this.props.durationSec;
		var nominalCapacity = this.props.nominalCapacity;
		var boardNumber = this.props.boardNumber;
		var softwareVersion = this.props.softwareVersion;
		var sailfinAppUserType = this.props.sailfinAppUserType;

		var cycleTimesMax = this.state.cycleTimesMax;

		var durationString = this.getTimeStringFromSeconds(durationSec);
		
		var logStartedString = this.props.getDateTimeStringFromUnixTimeSec(unixTimeSec);
		var uploadedDateTimeString = this.props.getDateTimeStringFromUnixTimeSec(uploadedUnixTimeSec);

		var showDeleteButtonYN = false;
		if (sailfinAppUserType >= 100) {
			showDeleteButtonYN = true;
		}
		
		var dischargeYN = false;
		if (operation === "discharge") {
			dischargeYN = true;
		}
		
		return (
			<div>
				<p><b className="BatteryLogStatsTitle">{this.props.getLang(BatteryLang, "batteryLogInfo")}</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "batteryLogID")}: {batteryLogID}</p>				
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "operation")}: <b>{operation}</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "logStarted")}: {logStartedString}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "uploadedTime")}: {uploadedDateTimeString}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "batteryName")}: {batteryName}</p>
				{dischargeYN && (<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "boardNumber")}: {boardNumber}</p>)}
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "softwareVersion")}: {softwareVersion}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "duration")}: {durationSec} sec - {durationString}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "nominalCapacity")}: {nominalCapacity}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "cycleTimes")}: {cycleTimesMax}</p>
				
				<p><b className="BatteryLogStatsTitle">{this.props.getLang(BatteryLang, "totalVoltage")}</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.totalVoltageAverage}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.totalVoltageMax}</p>
				
				<p><b className="BatteryLogStatsTitle">{this.props.getLang(BatteryLang, "current")}</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.currentAverage}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.currentMax}</p>

				<p><b className="BatteryLogStatsTitle">{this.props.getLang(BatteryLang, "residualCapacity")}</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.residualCapacityAverage}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.residualCapacityMax}</p>

				<p><b className="BatteryLogStatsTitle">{this.props.getLang(BatteryLang, "socStateOfCharge")}</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.socAverage}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.socMax}</p>

				<p><b className="BatteryLogStatsTitle">{this.props.getLang(BatteryLang, "minCellVoltage")}</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.minCellVoltageValueAverage}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.minCellVoltageValueMax}</p>

				<p><b className="BatteryLogStatsTitle">Temp 1</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.temp1Average}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.temp1Max}</p>

				<p><b className="BatteryLogStatsTitle">Temp 2</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.temp2Average}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.temp2Max}</p>

				<p><b className="BatteryLogStatsTitle">Temp 3</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.temp3Average}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.temp3Max}</p>

				<p><b className="BatteryLogStatsTitle">Temp 4</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.temp4Average}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.temp4Max}</p>

				<p><b className="BatteryLogStatsTitle">Temp 5</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.temp5Average}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.temp5Max}</p>

				<p><b className="BatteryLogStatsTitle">Temp 6</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.temp6Average}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.temp6Max}</p>

				<p><b className="BatteryLogStatsTitle">Temp Max</b></p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "average")}: {this.state.tempMaxAverage}</p>
				<p className="BatteryLogStatsItem">{this.props.getLang(BatteryLang, "max")}: {this.state.tempMaxMax}</p>

				{showDeleteButtonYN && (
					<div>
						<div style={{height: "30px"}}></div>
						<div className="BatteryLogStatsContainerDeleteButton">
							<button className="ViewSaveButton" onClick={this.handleClickDeleteButton}>{this.props.getLang(BatteryLang, "deleteThisBatteryLog")}</button>
						</div>
					</div>)}
				
				<div style={{height: "40px"}}></div>
			</div>
		);
	}
}

export default function BatteryLogStatsFunction(props) {
	const navigate = useNavigate();
	return <BatteryLogStats {...props} navigate={navigate} />;
}

