import React from 'react';
import './App.css';
import BoardLang from './BoardLang.json';

import { useNavigate } from "react-router-dom";

import BoardListToolbar from './BoardListToolbar.js';

class BoardList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			searchString: "",
			index: 0,
			count: 10,
			boardJsonObj: null,
		};

		this.handleChangeSearchString = this.handleChangeSearchString.bind(this);
		this.handleSelectBoard = this.handleSelectBoard.bind(this);
		this.getBoardDataFromBoardNameStart = this.getBoardDataFromBoardNameStart.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);	
		this.renderOneBoard = this.renderOneBoard.bind(this);
		this.renderSearchResults = this.renderSearchResults.bind(this);
	}
	
	componentDidMount() {
		this.getBoardDataFromBoardNameStart(this.state.searchString, this.state.index, this.state.count);
	}
	
	handleChangeSearchString(event) {
		var searchString = event.target.value;
		this.setState({
			searchString: searchString,
			index: 0,
		});
		this.getBoardDataFromBoardNameStart(searchString, this.state.index, this.state.count);
	}

	handleSelectBoard(boardID) {
		this.props.setSelectedBoardID(boardID);
		const navigate = this.props.navigate;
  		navigate("/board");
	}

	handlePrev() {
		var index = this.state.index - 10;
		this.setState({index: index});
		this.getBoardDataFromBoardNameStart(this.state.searchString, index, this.state.count);
	}

	handleNext() {
		var index = this.state.index + 10;
		this.setState({index: index});
		this.getBoardDataFromBoardNameStart(this.state.searchString, index, this.state.count);
	}
	
	async getBoardDataFromBoardNameStart(searchString, index, count) {
		
		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getboarddatafromboardnumberstart?boardnumberstart=" + searchString;
		url = url + "&companyid=" + companyID;
		url = url + "&index=" + index;
		url = url + "&count=" + count;

		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			boardJsonObj: jsonObj,
		});
	}
	
	renderOneBoard(boardID, boardNumber, boardMacAddress) {
		return (
			<tr key={boardID}>
				<td
					className="BoardListBoardNumber"
					onClick={() => this.handleSelectBoard(boardID)}
				>
					{boardNumber}
				</td>
				<td
					className="BoardListBoardMacAddress"
					onClick={() => this.handleSelectBoard(boardID)}
				>
					{boardMacAddress}
				</td>
			</tr>
		);
	}

	renderAllBoards() {

		var i;
		var boardJsonObj = this.state.boardJsonObj;
		var itemObj;
		var boardID;
		var boardNumber;
		var boardMacAddress;
		var jsxArray = [];

		for(i=0; i<boardJsonObj.length; i++) {
			itemObj = boardJsonObj[i];
			boardID = itemObj.boardID;
			boardNumber = itemObj.boardNumber;
			boardMacAddress = itemObj.boardMacAddress;
			jsxArray.push(this.renderOneBoard(boardID, boardNumber, boardMacAddress));
		}

		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="BoardListBoardNumberHeader">{this.props.getLang(BoardLang, "boardNumber")}</td>
						<td className="BoardListBoardMacAddressHeader">{this.props.getLang(BoardLang, "macAddress")}</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	renderSearchResults() {

		var boardCount = 0;
		var boardJsonObj = this.state.boardJsonObj;
		
		if (boardJsonObj !== null) {
			boardCount = boardJsonObj.length;
		}
		if (boardCount === 0) {
			return (<div>No boards found</div>);
		}

		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (boardCount === 10) {
			nextYN = true;
		}

		return (
			<div>
				{this.renderAllBoards()}
				<div style={{height:"30px"}}></div>
				<div className="ListContainer3">
					{prevYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handlePrev()}
					>
						&lt;&lt; Previous
					</div>)}
					{!prevYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						&lt;&lt; Previous
					</div>)}
					<div className="ListPrevNextLinkDisabled">|</div>
					{nextYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handleNext()}
					>
						Next &gt;&gt;
					</div>)}
					{!nextYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						Next &gt;&gt;
					</div>)}
				</div>
			</div>
		);
	}
	
	render() {

		return (
			<div>
				<BoardListToolbar
					baseURLAPI={this.props.baseURLAPI}
					companyIDInit={this.props.companyIDInit}
					companyName={this.props.companyName}
					rentAppUserType={this.props.rentAppUserType}
					getLang={this.props.getLang}
				/>
				<div style={{height:"30px"}}></div>
				<form>
					<label style={{fontSize:"20px"}}>
						<b>{this.props.getLang(BoardLang, "boardSearch")}:</b>
						&nbsp;&nbsp;&nbsp;
						<input type="text" style={{fontSize:"20px"}} value={this.state.searchString} onChange={this.handleChangeSearchString} autoFocus />
					</label>
				</form>
				<div style={{height:"30px"}}></div>
				{this.renderSearchResults()}
				<div style={{height:"30px"}}></div>
			</div>
		);
	}
}

export default function BoardListFunction(props) {
	const navigate = useNavigate();
	return <BoardList {...props} navigate={navigate} />;
}
