import React  from 'react';
import './App.css';
import './Ride.css';
import RideLang from './RideLang.json';
import { useNavigate } from "react-router-dom";

import RideListToolbar from './RideListToolbar.js';

class RideLogList extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			companyID: 0,
			jsonObj: null,
			rideCount: 0,
			index: 0,
			count: 10,
			showOnlyLogs5MinYN: true,
		};

		this.serverGetRideList = this.serverGetRideList.bind(this);
		this.handleSelectRide = this.handleSelectRide.bind(this);
		this.twoDigits = this.twoDigits.bind(this);
		this.getTimeStringFromSeconds = this.getTimeStringFromSeconds.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
		this.renderCheckbox = this.renderCheckbox.bind(this);
		this.renderPrevNext = this.renderPrevNext.bind(this);
		this.renderOneRide = this.renderOneRide.bind(this);
		this.renderAllRides = this.renderAllRides.bind(this);
	}
	
	componentDidMount() {
		var companyID = this.props.companyID;
		if (companyID > 0) {
			this.serverGetRideList(0, true);
		}
		this.setState({
			companyID: companyID,
		});
	}
	
	componentDidUpdate(prevProps) {
		var companyID1 = this.state.companyID;
		var companyID2 = this.props.companyID;
		if (companyID1 === companyID2) {
			return;
		}
		this.setState({
			companyID: companyID2,
		});
		this.serverGetRideList(0, true);
	}
	
	async serverGetRideList(index, showOnlyLogs5MinYN) {

		var durationMinimumSec = 0;
		if (showOnlyLogs5MinYN) {
			durationMinimumSec = 5*60;
		}

		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getridelist?boardid=0";
		url = url + "&companyid=" + companyID;
		url = url + "&sortby=rideID&sortasc=0";
		url = url + "&index=" + index + "&count=" + this.state.count;
		url = url + "&durationminsec=" + durationMinimumSec;
		
		const res = await fetch(url);
		const jsonObj = await res.json();
		var rideCount = jsonObj.length;

		this.setState({
			jsonObj: jsonObj,
			rideCount: rideCount,
		});
	}
		
	handleSelectRide(rideID) {
		this.props.setRideID(rideID);
		const navigate = this.props.navigate;
  		navigate("/ride");
	}

	twoDigits(value1) {
		if (value1 < 10) {
			return "0" + value1;
		} else {
			return value1;
		}
	}

	getTimeStringFromSeconds(seconds) {

		var hours;
		var minutes;
		var secondsLeft;
		var timeString = "";
	
		hours = Math.floor(seconds / 3600);
		secondsLeft = seconds - hours * 3600;

		minutes = Math.floor(secondsLeft / 60);
		seconds = secondsLeft - minutes * 60;
	
		if (hours > 0) {
			timeString = hours + ":";
		}
	
		timeString = timeString + this.twoDigits(minutes) + ":" + this.twoDigits(seconds);
		return timeString;
	}
	
	handlePrev() {
		var index = this.state.index - 10;
		this.setState({index: index});
		this.serverGetRideList(index, this.state.showOnlyLogs5MinYN);
	}

	handleNext() {
		var index = this.state.index + 10;
		this.setState({index: index});
		this.serverGetRideList(index, this.state.showOnlyLogs5MinYN);
	}

	handleCheckboxClick(event) {
		var target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		this.setState({
			index: 0,
			showOnlyLogs5MinYN: value,
		});
		this.serverGetRideList(0, value);
	}
	
	renderCheckbox() {
		
		var showOnlyLogs5MinYN = this.state.showOnlyLogs5MinYN;
		
		return (
			<div className="RideLogListChecboxContainer">
				<input 
					type="checkbox" 
					className="RideLogListChecbox" 
					id="showOnlyLogs5Min" 
					name="showOnlyLogs5Min"
					checked={showOnlyLogs5MinYN}
					onChange={(event) => this.handleCheckboxClick(event)}
				/>
				<label htmlFor="showOnlyLogs5Min" className="RideLogListChecboxText">{this.props.getLang(RideLang, "showOnlyLogs5Min")}</label>
			</div>
		)
	}
	
	renderPrevNext() {

		var jsonObj = this.state.jsonObj;
		
		if (jsonObj === null) {
			return (<div></div>)
		}
		
		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (jsonObj.length === 10) {
			nextYN = true;
		}
		
		return (
			<div className="ListContainer3">
				{prevYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handlePrev()}
				>
					&lt;&lt; Previous
				</div>)}
				{!prevYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					&lt;&lt; Previous
				</div>)}
				<div className="ListPrevNextLinkDisabled">|</div>
				{nextYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handleNext()}
				>
					Next &gt;&gt;
				</div>)}
				{!nextYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					Next &gt;&gt;
				</div>)}
			</div>
		);
	}

	renderOneRide(itemObj) {
		
		var rideID = itemObj.rideID;
		var boardNumber = itemObj.boardNumber;
		var unixTimeSec = itemObj.unixTimeSec;
		var uploadedUnixTimeSec = itemObj.uploadedUnixTimeSec;
		var durationSec = itemObj.durationSec;
		
		var unixTimeString = this.props.getDateTimeStringFromUnixTimeSec(unixTimeSec);
		var uploadedTimeString = this.props.getDateTimeStringFromUnixTimeSec(uploadedUnixTimeSec);
		var durationSecString = this.getTimeStringFromSeconds(durationSec);
		
		return (

			<div key={rideID} className="ListRow">
				<div
					className="ListLink"
					onClick={() => this.handleSelectRide(rideID)}
				>
					{rideID}
				</div>
				<div 
					className="ListLink"
					onClick={() => this.handleSelectRide(rideID)}
				>
					{boardNumber}
				</div>
				<div 
					className="ListLink"
					onClick={() => this.handleSelectRide(rideID)}
				>
					{unixTimeString}
				</div>				
				<div 
					className="ListLink"
					onClick={() => this.handleSelectRide(rideID)}
				>
					{uploadedTimeString}
				</div>				
				<div 
					className="ListLink"
					onClick={() => this.handleSelectRide(rideID)}
				>
					{durationSecString}
				</div>								
			</div>
		);
	}
	
	renderAllRides() {

		var jsonObj = this.state.jsonObj;
		
		if (jsonObj === null) {
			return;
		}
		
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];
		
		for(i=0; i<jsonObj.length; i++) {
			itemObj = jsonObj[i];
			jsxCode = this.renderOneRide(itemObj);
			jsxArray.push(jsxCode);
		}

		return (
			<div className="ListContainer2">
				<div className="ListRow">
					<div className="ListHeader">{this.props.getLang(RideLang, "rideID")}</div>
					<div className="ListHeader">{this.props.getLang(RideLang, "boardNumber")}</div>
					<div className="ListHeader">{this.props.getLang(RideLang, "rideStarted")}</div>
					<div className="ListHeader">{this.props.getLang(RideLang, "uploadedTime")}</div>
					<div className="ListHeader">{this.props.getLang(RideLang, "duration")}</div>
				</div>
				{jsxArray}
				<p>&nbsp;</p>
			</div>
		);
	}

	render() {
		
		var jsonObj = this.state.jsonObj;		
		if (jsonObj === null) {
			return null;
		}
		
		return (
			<div className="ListContainer1">
				<RideListToolbar
					baseURLAPI={this.props.baseURLAPI}
					companyName={this.props.companyName}
					getLang={this.props.getLang}
				/>
				<div style={{height: "20px"}}></div>
				{this.renderAllRides()}
				{this.renderCheckbox()}
				{this.renderPrevNext()}
			</div>
		);
	}
}

export default function RideLogListFunct(props) {
	const navigate = useNavigate();
	return <RideLogList {...props} navigate={navigate} />;
}

