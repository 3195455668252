import React  from 'react';
import './App.css';
import './Battery.css';

import BatteryLogToolbar from './BatteryLogToolbar.js';
import BatteryLogStats from './BatteryLogStats.js';
import BatteryLogChart from './BatteryLogChart.js';
import BatteryLogTable from './BatteryLogTable.js';
import BatteryLogDownload from './BatteryLogDownload.js';
import BatteryLogSettings from './BatteryLogSettings.js';

class BatteryLog extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			selectedTab: "stats",
			batteryID: 0,
			unixTimeSec: 0,
			uploadedUnixTimeSec: 0,
			durationSec: 0,
			operation: "",
			batteryName: "",
			softwareVersion: "",
			boardNumber: "",
			logID: 0,
			dataCount: 0,
			timeArray: [],
			totalVoltageArray: [],
			currentArray: [],
			residualCapacityArray: [],
			socArray: [],
			cycleTimesArray: [],
			minCellVoltageValueArray: [],
			minCellVoltageIndexArray: [],
			temp1Array: [],
			temp2Array: [],
			temp3Array: [],
			temp4Array: [],
			temp5Array: [],
			temp6Array: [],
			tempMaxArray: [],
			dataCount10: 0,
			timeArray10: [],
			totalVoltageArray10: [],
			currentArray10: [],
			residualCapacityArray10: [],
			socArray10: [],
			cycleTimesArray10: [],
			minCellVoltageValueArray10: [],
			minCellVoltageIndexArray10: [],
			temp1Array10: [],
			temp2Array10: [],
			temp3Array10: [],
			temp4Array10: [],
			temp5Array10: [],
			temp6Array10: [],
			tempMaxArray10: [],
			dataCount30: 0,
			timeArray30: [],
			totalVoltageArray30: [],
			currentArray30: [],
			residualCapacityArray30: [],
			socArray30: [],
			cycleTimesArray30: [],
			minCellVoltageValueArray30: [],
			minCellVoltageIndexArray30: [],
			temp1Array30: [],
			temp2Array30: [],
			temp3Array30: [],
			temp4Array30: [],
			temp5Array30: [],
			temp6Array30: [],
			tempMaxArray30: [],
			timeArray60: [],
			totalVoltageArray60: [],
			currentArray60: [],
			residualCapacityArray60: [],
			socArray60: [],
			cycleTimesArray60: [],
			minCellVoltageValueArray60: [],
			minCellVoltageIndexArray60: [],
			temp1Array60: [],
			temp2Array60: [],
			temp3Array60: [],
			temp4Array60: [],
			temp5Array60: [],
			temp6Array60: [],
			tempMaxArray60: [],
		};

		this.getBatteryLogData = this.getBatteryLogData.bind(this);
		this.downloadBatteryLogFile = this.downloadBatteryLogFile.bind(this);
		this.getLineFromText = this.getLineFromText.bind(this);
		this.parseOneDataLine = this.parseOneDataLine.bind(this);
		this.parseBatteryLogText = this.parseBatteryLogText.bind(this);
		this.calcDataForInterval = this.calcDataForInterval.bind(this);
		this.setSelectedTab = this.setSelectedTab.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}
	
	componentDidMount() {
		var batteryLogID = this.props.batteryLogID;
		if (batteryLogID > 0) {
			this.getBatteryLogData(batteryLogID);
		}
	}
	
	async getBatteryData(batteryID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbatterydata?batteryid=" + batteryID;

		const res = await fetch(url);
		const jsonObj = await res.json();
		var batteryName = jsonObj.batteryName;

		this.setState({
			batteryName: batteryName,
		});
	}

	async getBatteryLogData(batteryLogID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbatterylogdata?batterylogid=" + batteryLogID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var batteryID = jsonObj.batteryID;
		var unixTimeSec = jsonObj.unixTimeSec;
		var uploadedUnixTimeSec = jsonObj.uploadedUnixTimeSec;
		var durationSec = jsonObj.durationSec;
		var logID = jsonObj.logID;
		var operation = jsonObj.operation;

		this.setState({
			batteryID: batteryID,
			unixTimeSec: unixTimeSec,
			uploadedUnixTimeSec: uploadedUnixTimeSec,
			durationSec: durationSec,
			logID: logID,
			operation: operation,
		});
		
		this.getBatteryData(batteryID);
		this.downloadBatteryLogFile(batteryLogID);
	}
	
	getLineFromText(fileText, startIndex) {

		var i;
		var ch;
		var oneLine = "";

		var length = fileText.length;

		for(i=startIndex; i<length; i++) {
			ch = fileText.charAt(i);
			if (ch === "\n") {
				return oneLine;
			}
			oneLine = oneLine + ch;
		}
		
		return oneLine;
	}

	parseOneDataLine(lineString, valueStringArray) {

		var i;
		var ch;
		var valueString = "";
		var valueCount = 0;

		var length = lineString.length;

		for(i=0; i<length; i++) {
			ch = lineString.charAt(i);
			if (ch === " ") {
				valueStringArray.push(valueString);
				valueCount = valueCount + 1;
				valueString = "";
			} else {
				valueString = valueString + ch;
			}
		}
		
		if (valueString !== "") {
			valueStringArray.push(valueString);
			valueCount = valueCount + 1;
		}
		
		return valueCount;
	}

	parseBatteryLogText(fileText) {

		var oneLine;
		var startIndex = 0;
		var fileTextLength = fileText.length;

		// PARSE HEADER
		
		var unixTimeSec;
		var batteryName;
		var softwareVersion;
		var boardNumber;
		var cellCount;
		var nominalCapacity;
		var operation;

		// unix time
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		unixTimeSec = parseInt(oneLine);

		// battery name
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		batteryName = oneLine;

		// software version
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		softwareVersion = oneLine;

		// board number
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		boardNumber = oneLine;

		// cell count
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		cellCount = oneLine;

		// nominal capacity
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		nominalCapacity = oneLine;

		// operation
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		operation = oneLine;

		/*console.log("unixTimeSec = " + unixTimeSec);
		console.log("batteryName = " + batteryName);
		console.log("softwareVersion = " + softwareVersion);
		console.log("boardNumber = " + boardNumber);
		console.log("cellCount = " + cellCount);
		console.log("nominalCapacity = " + nominalCapacity);
		console.log("operation = " + operation);
		console.log("");*/
		
		this.setState({
			softwareVersion: softwareVersion,
			boardNumber: boardNumber,
			cellCount: cellCount,
			nominalCapacity: nominalCapacity,
		});

		// end of header
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		console.log("end of header = " + oneLine);
		
		if (oneLine !== "BATTERYLOGHEADEREND") {
			return;
		}

		// PARSE DATA
		
		oneLine = this.getLineFromText(fileText, startIndex);
		//var headerString = oneLine; // not looking into header for now
		startIndex = startIndex + oneLine.length + 1;
		
		var timeArray = [];
		var totalVoltageArray = [];
		var currentArray = [];
		var residualCapacityArray = [];
		var socArray = [];
		var cycleTimesArray = [];
		var minCellVoltageValueArray = [];
		var minCellVoltageIndexArray = [];
		var temp1Array = [];
		var temp2Array = [];
		var temp3Array = [];
		var temp4Array = [];
		var temp5Array = [];
		var temp6Array = [];
		var tempMaxArray = [];
		var dataCount = 0;

		// seconds total_voltage current residual_capacity soc cycle_times min_cell_voltage_value min_cell_voltage_index temp1 temp2 temp3 temp4
		
		for(; startIndex<fileTextLength; ) {
			
			oneLine = this.getLineFromText(fileText, startIndex);
			startIndex = startIndex + oneLine.length + 1;
			
			var valueStringArray = [];
			var valueCount = this.parseOneDataLine(oneLine, valueStringArray);
			//console.log("valueCount = " + valueCount);
			
			if (valueCount === 14) {

				var seconds = parseInt(valueStringArray[0]);
				var totalVoltage = parseFloat(valueStringArray[1], 2);
				var current = parseInt(valueStringArray[2]);
				var residualCapacity = parseInt(valueStringArray[3]);
				var soc = parseInt(valueStringArray[4]);
				var cycleTimes = parseInt(valueStringArray[5]);
				var minCellVoltageValue = parseInt(valueStringArray[6]) / 1000.0;
				var minCellVoltageIndex = parseInt(valueStringArray[7]);
				var temp1 = parseInt(valueStringArray[8]);
				var temp2 = parseInt(valueStringArray[9]);
				var temp3 = parseInt(valueStringArray[10]);
				var temp4 = parseInt(valueStringArray[11]);
				var temp5 = parseInt(valueStringArray[12]);
				var temp6 = parseInt(valueStringArray[13]);
				
				var tempMax = temp1;
				if (temp2 > tempMax) {
					tempMax = temp2;
				}
				if (temp3 > tempMax) {
					tempMax = temp3;
				}
				if (temp4 > tempMax) {
					tempMax = temp4;
				}
				if (temp5 > tempMax) {
					tempMax = temp5;
				}
				if (temp6 > tempMax) {
					tempMax = temp6;
				}

				timeArray.push(seconds);
				totalVoltageArray.push(totalVoltage);
				currentArray.push(current);
				residualCapacityArray.push(residualCapacity);
				socArray.push(soc);
				cycleTimesArray.push(cycleTimes);
				minCellVoltageValueArray.push(minCellVoltageValue);
				minCellVoltageIndexArray.push(minCellVoltageIndex);
				temp1Array.push(temp1);
				temp2Array.push(temp2);
				temp3Array.push(temp3);
				temp4Array.push(temp4);
				temp5Array.push(temp5);
				temp6Array.push(temp6);
				tempMaxArray.push(tempMax);

				dataCount = dataCount + 1;
			}
		}

		this.setState({
			dataCount: dataCount,
			timeArray: timeArray,
			totalVoltageArray: totalVoltageArray,
			currentArray: currentArray,
			residualCapacityArray: residualCapacityArray,
			socArray: socArray,
			cycleTimesArray: cycleTimesArray,
			minCellVoltageValueArray: minCellVoltageValueArray,
			minCellVoltageIndexArray: minCellVoltageIndexArray,
			temp1Array: temp1Array,
			temp2Array: temp2Array,
			temp3Array: temp3Array,
			temp4Array: temp4Array,
			temp5Array: temp5Array,
			temp6Array: temp6Array,
			tempMaxArray: tempMaxArray,
		});
		
		this.loadData(dataCount)
 	}

	async downloadBatteryLogFile(batteryLogID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/batterylogs/" + batteryLogID + ".txt";
		
		console.log(url);

		var res = await fetch(url);
		const fileText = await res.text();
		this.parseBatteryLogText(fileText);
	}
	
	calcDataForInterval(intervalValueInput) {

		var dataCount = this.state.dataCount;
		var dataCountInterval;
		var dataIndex;
		
		var timeArrayInterval = [];


		var totalVoltage = 0;
		var totalVoltageSum = 0;
		var totalVoltageAverage = 0;
		var totalVoltageArrayInterval = [];

		var current = 0;
		var currentSum = 0;
		var currentAverage = 0;
		var currentArrayInterval = [];

		var residualCapacity = 0;
		var residualCapacitySum = 0;
		var residualCapacityAverage = 0;
		var residualCapacityArrayInterval = [];

		var soc = 0;
		var socSum = 0;
		var socAverage = 0;
		var socArrayInterval = [];

		var cycleTimesArrayInterval = [];

		var minCellVoltageValue = 0;
		var minCellVoltageValueSum = 0;
		var minCellVoltageValueAverage = 0;
		var minCellVoltageValueArrayInterval = [];

		var minCellVoltageIndexArrayInterval = [];

		var temp1 = 0;
		var temp1Sum = 0;
		var temp1Average = 0;
		var temp1ArrayInterval = [];

		var temp2 = 0;
		var temp2Sum = 0;
		var temp2Average = 0;
		var temp2ArrayInterval = [];

		var temp3 = 0;
		var temp3Sum = 0;
		var temp3Average = 0;
		var temp3ArrayInterval = [];

		var temp4 = 0;		
		var temp4Sum = 0;
		var temp4Average = 0;
		var temp4ArrayInterval = [];

		var temp5 = 0;		
		var temp5Sum = 0;
		var temp5Average = 0;
		var temp5ArrayInterval = [];

		var temp6 = 0;		
		var temp6Sum = 0;
		var temp6Average = 0;
		var temp6ArrayInterval = [];

		var tempMax = 0;
		var tempMaxSum = 0;
		var tempMaxAverage = 0;
		var tempMaxArrayInterval = [];

		var intervalValue = intervalValueInput / 10;

		dataCountInterval = Math.floor(dataCount / intervalValue);
		
		for(var i=0; i<dataCountInterval; i++) {

			var batchCount = 0;

			var time = intervalValueInput*i;
			timeArrayInterval.push(time);
			
			totalVoltageSum = 0;
			currentSum = 0;
			residualCapacitySum = 0;
			socSum = 0;
			minCellVoltageValueSum = 0;
			temp1Sum = 0;
			temp2Sum = 0;
			temp3Sum = 0;
			temp4Sum = 0;
			temp5Sum = 0;
			temp6Sum = 0;
			tempMaxSum = 0;
			
			if (intervalValue === 1) {
				//timeArrayInterval.push(this.state.timeArray[i]);
				totalVoltageArrayInterval.push(this.state.totalVoltageArray[i]); 
				currentArrayInterval.push(this.state.currentArray[i]); 
				residualCapacityArrayInterval.push(this.state.residualCapacityArray[i]); 
				socArrayInterval.push(this.state.socArray[i]); 
				cycleTimesArrayInterval.push(this.state.cycleTimesArray[i]); 
				minCellVoltageValueArrayInterval.push(this.state.minCellVoltageValueArray[i]); 
				minCellVoltageIndexArrayInterval.push(this.state.minCellVoltageIndexArray[i]); 
				temp1ArrayInterval.push(this.state.temp1Array[i]); 
				temp2ArrayInterval.push(this.state.temp2Array[i]); 
				temp3ArrayInterval.push(this.state.temp3Array[i]); 
				temp4ArrayInterval.push(this.state.temp4Array[i]); 
				temp5ArrayInterval.push(this.state.temp5Array[i]); 
				temp6ArrayInterval.push(this.state.temp6Array[i]); 
				tempMaxArrayInterval.push(this.state.tempMaxArray[i]); 
			}
			
			if (intervalValue > 1) {

				for(var j=0; j<intervalValue; j++) {

					dataIndex = intervalValue*i + j;
					
					if (dataIndex < dataCount) {

						totalVoltage = this.state.totalVoltageArray[dataIndex];
						totalVoltageSum = totalVoltageSum + totalVoltage;

						current = this.state.currentArray[dataIndex];
						currentSum = currentSum + current;

						residualCapacity = this.state.residualCapacityArray[dataIndex];
						residualCapacitySum = residualCapacitySum + residualCapacity;

						soc = this.state.socArray[dataIndex];
						socSum = socSum + soc;

						minCellVoltageValue = this.state.minCellVoltageValueArray[dataIndex];
						minCellVoltageValueSum = minCellVoltageValueSum + minCellVoltageValue;

						temp1 = this.state.temp1Array[dataIndex];
						temp1Sum = temp1Sum + temp1;

						temp2 = this.state.temp2Array[dataIndex];
						temp2Sum = temp2Sum + temp2;

						temp3 = this.state.temp3Array[dataIndex];
						temp3Sum = temp3Sum + temp3;

						temp4 = this.state.temp4Array[dataIndex];
						temp4Sum = temp4Sum + temp4;

						temp5 = this.state.temp5Array[dataIndex];
						temp5Sum = temp5Sum + temp5;

						temp6 = this.state.temp6Array[dataIndex];
						temp6Sum = temp6Sum + temp6;

						tempMax = this.state.tempMaxArray[dataIndex];
						tempMaxSum = tempMaxSum + tempMax;

						batchCount = batchCount + 1;
					}
				}

				if (batchCount === 0) {
					totalVoltageAverage = 0;
				} else {
					totalVoltageAverage = totalVoltageSum / batchCount;
				}
				totalVoltageAverage = Math.round(totalVoltageAverage);
				totalVoltageArrayInterval.push(totalVoltageAverage);
				
				if (batchCount === 0) {
					currentAverage = 0;
				} else {
					currentAverage = currentSum / batchCount;
				}
				currentAverage = Math.round(currentAverage);
				currentArrayInterval.push(currentAverage);
				
				if (batchCount === 0) {
					residualCapacityAverage = 0;
				} else {
					residualCapacityAverage = residualCapacitySum / batchCount;
				}
				residualCapacityAverage = Math.round(residualCapacityAverage);
				residualCapacityArrayInterval.push(residualCapacityAverage);
				
				if (batchCount === 0) {
					socAverage = 0;
				} else {
					socAverage = socSum / batchCount;
				}
				socAverage = Math.round(socAverage);
				socArrayInterval.push(socAverage);
				
				if (batchCount === 0) {
					socAverage = 0;
				} else {
					socAverage = socSum / batchCount;
				}
				socAverage = Math.round(socAverage);
				socArrayInterval.push(socAverage);
				
				if (batchCount === 0) {
					minCellVoltageValueAverage = 0;
				} else {
					minCellVoltageValueAverage = minCellVoltageValueSum / batchCount;
				}
				minCellVoltageValueAverage = Math.round(minCellVoltageValueAverage);
				minCellVoltageValueArrayInterval.push(minCellVoltageValueAverage);
				
				if (batchCount === 0) {
					temp1Average = 0;
				} else {
					temp1Average = temp1Sum / batchCount;
				}
				temp1Average = Math.round(temp1Average);
				temp1ArrayInterval.push(temp1Average);
				
				if (batchCount === 0) {
					temp2Average = 0;
				} else {
					temp2Average = temp2Sum / batchCount;
				}
				temp2Average = Math.round(temp2Average);
				temp2ArrayInterval.push(temp2Average);
				
				if (batchCount === 0) {
					temp3Average = 0;
				} else {
					temp3Average = temp3Sum / batchCount;
				}
				temp3Average = Math.round(temp3Average);
				temp3ArrayInterval.push(temp3Average);
				
				if (batchCount === 0) {
					temp4Average = 0;
				} else {
					temp4Average = temp4Sum / batchCount;
				}
				temp4Average = Math.round(temp4Average);
				temp4ArrayInterval.push(temp4Average);
				
				if (batchCount === 0) {
					temp5Average = 0;
				} else {
					temp5Average = temp5Sum / batchCount;
				}
				temp5Average = Math.round(temp5Average);
				temp5ArrayInterval.push(temp5Average);
				
				if (batchCount === 0) {
					temp6Average = 0;
				} else {
					temp6Average = temp6Sum / batchCount;
				}
				temp6Average = Math.round(temp6Average);
				temp6ArrayInterval.push(temp6Average);
				
				if (batchCount === 0) {
					tempMaxAverage = 0;
				} else {
					tempMaxAverage = tempMaxSum / batchCount;
				}
				tempMaxAverage = Math.round(tempMaxAverage);
				tempMaxArrayInterval.push(tempMaxAverage);				
			}
		}

		if (intervalValueInput === 10) {
			this.setState({
				dataCount10: dataCountInterval,
				timeArray10: timeArrayInterval,
				totalVoltageArray10: totalVoltageArrayInterval, 
				currentArray10: currentArrayInterval, 
				residualCapacityArray10: residualCapacityArrayInterval, 
				socArray10: socArrayInterval, 
				cycleTimesArray10: cycleTimesArrayInterval, 
				minCellVoltageValueArray10: minCellVoltageValueArrayInterval, 
				minCellVoltageIndexArray10: minCellVoltageIndexArrayInterval, 
				temp1Array10: temp1ArrayInterval, 
				temp2Array10: temp2ArrayInterval, 
				temp3Array10: temp3ArrayInterval, 
				temp4Array10: temp4ArrayInterval, 
				temp5Array10: temp5ArrayInterval, 
				temp6Array10: temp6ArrayInterval, 
				tempMaxArray10: tempMaxArrayInterval, 
			});
		}

		if (intervalValueInput === 30) {
			this.setState({
				dataCount30: dataCountInterval,
				timeArray30: timeArrayInterval,
				totalVoltageArray30: totalVoltageArrayInterval, 
				currentArray30: currentArrayInterval, 
				residualCapacityArray30: residualCapacityArrayInterval, 
				socArray30: socArrayInterval, 
				cycleTimesArray30: cycleTimesArrayInterval, 
				minCellVoltageValueArray30: minCellVoltageValueArrayInterval, 
				minCellVoltageIndexArray30: minCellVoltageIndexArrayInterval, 
				temp1Array30: temp1ArrayInterval, 
				temp2Array30: temp2ArrayInterval, 
				temp3Array30: temp3ArrayInterval, 
				temp4Array30: temp4ArrayInterval, 
				temp5Array30: temp5ArrayInterval, 
				temp6Array30: temp6ArrayInterval, 
				tempMaxArray30: tempMaxArrayInterval, 
			});
		}

		if (intervalValueInput === 60) {
			this.setState({
				dataCount60: dataCountInterval,
				timeArray60: timeArrayInterval,
				totalVoltageArray60: totalVoltageArrayInterval, 
				currentArray60: currentArrayInterval, 
				residualCapacityArray60: residualCapacityArrayInterval, 
				socArray60: socArrayInterval, 
				cycleTimesArray60: cycleTimesArrayInterval, 
				minCellVoltageValueArray60: minCellVoltageValueArrayInterval, 
				minCellVoltageIndexArray60: minCellVoltageIndexArrayInterval, 
				temp1Array60: temp1ArrayInterval, 
				temp2Array60: temp2ArrayInterval, 
				temp3Array60: temp3ArrayInterval, 
				temp4Array60: temp4ArrayInterval, 
				temp5Array60: temp5ArrayInterval, 
				temp6Array60: temp6ArrayInterval, 
				tempMaxArray60: tempMaxArrayInterval, 
			});
		}
	}

	loadData(dataCount) {
		var operation = this.state.operation;
		if (operation === "discharge") {
			this.calcDataForInterval(10);
			this.calcDataForInterval(30);
			this.calcDataForInterval(60);
		}
	}
	
	setSelectedTab(tab) {
		this.setState({
			selectedTab: tab,
		});
	}
	
	renderTab() {

		var batteryLogID = this.props.batteryLogID;
		var selectedTab = this.state.selectedTab;
		var dataCount = this.state.dataCount;
		var dataInterval = this.props.dataInterval;
		var operation = this.state.operation;
		
		if (batteryLogID <= 0) {
			return null;
		}

		if (dataCount === 0) {
			return null;
		}
		
		if (operation === "charge") {
			dataInterval = 1;
		}
		
		var dataCountCurrent;
		var timeArrayCurrent;
		var totalVoltageArrayCurrent;
		var currentArrayCurrent;
		var residualCapacityArrayCurrent;
		var socArrayCurrent;
		var cycleTimesArrayCurrent;
		var minCellVoltageValueArrayCurrent;
		var minCellVoltageIndexArrayCurrent;
		var temp1ArrayCurrent;
		var temp2ArrayCurrent;
		var temp3ArrayCurrent;
		var temp4ArrayCurrent;
		var temp5ArrayCurrent;
		var temp6ArrayCurrent;
		var tempMaxArrayCurrent;

		if (dataInterval === 1) {
			dataCountCurrent = this.state.dataCount;
			timeArrayCurrent = this.state.timeArray;
			totalVoltageArrayCurrent = this.state.totalVoltageArray;
			currentArrayCurrent = this.state.currentArray;
			residualCapacityArrayCurrent = this.state.residualCapacityArray;
			socArrayCurrent = this.state.socArray;
			cycleTimesArrayCurrent = this.state.cycleTimesArray;
			minCellVoltageValueArrayCurrent = this.state.minCellVoltageValueArray;
			minCellVoltageIndexArrayCurrent = this.state.minCellVoltageIndexArray;
			temp1ArrayCurrent = this.state.temp1Array;
			temp2ArrayCurrent = this.state.temp2Array;
			temp3ArrayCurrent = this.state.temp3Array;
			temp4ArrayCurrent = this.state.temp4Array;
			temp5ArrayCurrent = this.state.temp5Array;
			temp6ArrayCurrent = this.state.temp6Array;
			tempMaxArrayCurrent = this.state.tempMaxArray;
		}
		
		if (dataInterval === 10) {
			dataCountCurrent = this.state.dataCount10;
			timeArrayCurrent = this.state.timeArray10;
			totalVoltageArrayCurrent = this.state.totalVoltageArray10;
			currentArrayCurrent = this.state.currentArray10;
			residualCapacityArrayCurrent = this.state.residualCapacityArray10;
			socArrayCurrent = this.state.socArray10;
			cycleTimesArrayCurrent = this.state.cycleTimesArray10;
			minCellVoltageValueArrayCurrent = this.state.minCellVoltageValueArray10;
			minCellVoltageIndexArrayCurrent = this.state.minCellVoltageIndexArray10;
			temp1ArrayCurrent = this.state.temp1Array10;
			temp2ArrayCurrent = this.state.temp2Array10;
			temp3ArrayCurrent = this.state.temp3Array10;
			temp4ArrayCurrent = this.state.temp4Array10;
			temp5ArrayCurrent = this.state.temp5Array10;
			temp6ArrayCurrent = this.state.temp6Array10;
			tempMaxArrayCurrent = this.state.tempMaxArray10;
		}
		
		if (dataInterval === 30) {
			dataCountCurrent = this.state.dataCount30;
			timeArrayCurrent = this.state.timeArray30;
			totalVoltageArrayCurrent = this.state.totalVoltageArray30;
			currentArrayCurrent = this.state.currentArray30;
			residualCapacityArrayCurrent = this.state.residualCapacityArray30;
			socArrayCurrent = this.state.socArray30;
			cycleTimesArrayCurrent = this.state.cycleTimesArray30;
			minCellVoltageValueArrayCurrent = this.state.minCellVoltageValueArray30;
			minCellVoltageIndexArrayCurrent = this.state.minCellVoltageIndexArray30;
			temp1ArrayCurrent = this.state.temp1Array30;
			temp2ArrayCurrent = this.state.temp2Array30;
			temp3ArrayCurrent = this.state.temp3Array30;
			temp4ArrayCurrent = this.state.temp4Array30;
			temp5ArrayCurrent = this.state.temp5Array30;
			temp6ArrayCurrent = this.state.temp6Array30;
			tempMaxArrayCurrent = this.state.tempMaxArray30;
		}
		
		if (dataInterval === 60) {
			dataCountCurrent = this.state.dataCount60;
			timeArrayCurrent = this.state.timeArray60;
			totalVoltageArrayCurrent = this.state.totalVoltageArray60;
			currentArrayCurrent = this.state.currentArray60;
			residualCapacityArrayCurrent = this.state.residualCapacityArray60;
			socArrayCurrent = this.state.socArray60;
			cycleTimesArrayCurrent = this.state.cycleTimesArray60;
			minCellVoltageValueArrayCurrent = this.state.minCellVoltageValueArray60;
			minCellVoltageIndexArrayCurrent = this.state.minCellVoltageIndexArray60;
			temp1ArrayCurrent = this.state.temp1Array60;
			temp2ArrayCurrent = this.state.temp2Array60;
			temp3ArrayCurrent = this.state.temp3Array60;
			temp4ArrayCurrent = this.state.temp4Array60;
			temp5ArrayCurrent = this.state.temp5Array60;
			temp6ArrayCurrent = this.state.temp6Array60;
			tempMaxArrayCurrent = this.state.tempMaxArray60;
		}

		if (selectedTab === "stats") {
			return (
				<BatteryLogStats
					baseURLAPI={this.props.baseURLAPI}
					sailfinAppUserType={this.props.sailfinAppUserType}
					batteryLogID={this.props.batteryLogID}
					batteryID={this.state.batteryID}
					unixTimeSec={this.state.unixTimeSec}
					uploadedUnixTimeSec={this.state.uploadedUnixTimeSec}
					durationSec={this.state.durationSec}
					batteryName={this.state.batteryName}
					boardNumber={this.state.boardNumber}
					softwareVersion={this.state.softwareVersion}
					nominalCapacity={this.state.nominalCapacity}
					operation={this.state.operation}
					dataCount={this.state.dataCount}
					totalVoltageArray={this.state.totalVoltageArray}
					currentArray={this.state.currentArray}
					residualCapacityArray={this.state.residualCapacityArray}
					socArray={this.state.socArray}
					cycleTimesArray={this.state.cycleTimesArray}
					minCellVoltageValueArray={this.state.minCellVoltageValueArray}
					minCellVoltageIndexArray={this.state.minCellVoltageIndexArray}
					temp1Array={this.state.temp1Array}
					temp2Array={this.state.temp2Array}
					temp3Array={this.state.temp3Array}
					temp4Array={this.state.temp4Array}
					temp5Array={this.state.temp5Array}
					temp6Array={this.state.temp6Array}
					tempMaxArray={this.state.tempMaxArray}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
					getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
				/>
			);
		}

		if (selectedTab === "chart") {
			return (
				<BatteryLogChart
					operation={this.state.operation}
					dataInterval={this.props.dataInterval}
					batteryTotalVoltageYN={this.props.batteryTotalVoltageYN}
					batteryCurrentYN={this.props.batteryCurrentYN}
					batterySocYN={this.props.batterySocYN}
					batteryResidualCapacityYN={this.props.batteryResidualCapacityYN}
					batteryMinCellVoltageValueYN={this.props.batteryMinCellVoltageValueYN}
					batteryTemp1YN={this.props.batteryTemp1YN}
					batteryTemp2YN={this.props.batteryTemp2YN}
					batteryTemp3YN={this.props.batteryTemp3YN}
					batteryTemp4YN={this.props.batteryTemp4YN}
					batteryTemp5YN={this.props.batteryTemp5YN}
					batteryTemp6YN={this.props.batteryTemp6YN}
					batteryTempMaxYN={this.props.batteryTempMaxYN}
					dataCountCurrent={dataCountCurrent}
					timeArrayCurrent={timeArrayCurrent}

					totalVoltageArrayCurrent={totalVoltageArrayCurrent}
					currentArrayCurrent={currentArrayCurrent}
					residualCapacityArrayCurrent={residualCapacityArrayCurrent}
					socArrayCurrent={socArrayCurrent}
					cycleTimesArrayCurrent={cycleTimesArrayCurrent}
					minCellVoltageValueArrayCurrent={minCellVoltageValueArrayCurrent}
					minCellVoltageIndexArrayCurrent={minCellVoltageIndexArrayCurrent}
					temp1ArrayCurrent={temp1ArrayCurrent}
					temp2ArrayCurrent={temp2ArrayCurrent}
					temp3ArrayCurrent={temp3ArrayCurrent}
					temp4ArrayCurrent={temp4ArrayCurrent}
					temp5ArrayCurrent={temp5ArrayCurrent}
					temp6ArrayCurrent={temp6ArrayCurrent}
					tempMaxArrayCurrent={tempMaxArrayCurrent}

					totalVoltageArray={this.state.totalVoltageArray}
					currentArray={this.state.currentArray}
					residualCapacityArray={this.state.residualCapacityArray}
					socArray={this.state.socArray}
					cycleTimesArray={this.state.cycleTimesArray}
					minCellVoltageValueArray={this.state.minCellVoltageValueArray}
					minCellVoltageIndexArray={this.state.minCellVoltageIndexArray}
					temp1Array={this.state.temp1Array}
					temp2Array={this.state.temp2Array}
					temp3Array={this.state.temp3Array}
					temp4Array={this.state.temp4Array}
					temp5Array={this.state.temp5Array}
					temp6Array={this.state.temp6Array}
					tempMaxArray={this.state.tempMaxArray}

					getLang={this.props.getLang}
				/>
			);
		}
		
		if (selectedTab === "table") {
			return (
				<BatteryLogTable
					operation={this.state.operation}
					batteryTotalVoltageYN={this.props.batteryTotalVoltageYN}
					batteryCurrentYN={this.props.batteryCurrentYN}
					batterySocYN={this.props.batterySocYN}
					batteryResidualCapacityYN={this.props.batteryResidualCapacityYN}
					batteryMinCellVoltageValueYN={this.props.batteryMinCellVoltageValueYN}
					batteryTemp1YN={this.props.batteryTemp1YN}
					batteryTemp2YN={this.props.batteryTemp2YN}
					batteryTemp3YN={this.props.batteryTemp3YN}
					batteryTemp4YN={this.props.batteryTemp4YN}
					batteryTemp5YN={this.props.batteryTemp5YN}
					batteryTemp6YN={this.props.batteryTemp6YN}
					batteryTempMaxYN={this.props.batteryTempMaxYN}
					dataCountCurrent={dataCountCurrent}
					timeArrayCurrent={timeArrayCurrent}
					totalVoltageArrayCurrent={totalVoltageArrayCurrent}
					currentArrayCurrent={currentArrayCurrent}
					residualCapacityArrayCurrent={residualCapacityArrayCurrent}
					socArrayCurrent={socArrayCurrent}
					cycleTimesArrayCurrent={cycleTimesArrayCurrent}
					minCellVoltageValueArrayCurrent={minCellVoltageValueArrayCurrent}
					minCellVoltageIndexArrayCurrent={minCellVoltageIndexArrayCurrent}
					temp1ArrayCurrent={temp1ArrayCurrent}
					temp2ArrayCurrent={temp2ArrayCurrent}
					temp3ArrayCurrent={temp3ArrayCurrent}
					temp4ArrayCurrent={temp4ArrayCurrent}
					temp5ArrayCurrent={temp5ArrayCurrent}
					temp6ArrayCurrent={temp6ArrayCurrent}
					tempMaxArrayCurrent={tempMaxArrayCurrent}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "download") {
			return (
				<BatteryLogDownload
					baseURLServer={this.props.baseURLServer}
					batteryLogID={this.props.batteryLogID}
					batteryName={this.state.batteryName}
					uploadedUnixTimeSec={this.state.uploadedUnixTimeSec}
					dataCount={this.state.dataCount}
					operation={this.state.operation}
					timeArray={this.state.timeArray}
					totalVoltageArray={this.state.totalVoltageArray}
					currentArray={this.state.currentArray}
					residualCapacityArray={this.state.residualCapacityArray}
					socArray={this.state.socArray}
					cycleTimesArray={this.state.cycleTimesArray}
					minCellVoltageValueArray={this.state.minCellVoltageValueArray}
					minCellVoltageIndexArray={this.state.minCellVoltageIndexArray}
					temp1Array={this.state.temp1Array}
					temp2Array={this.state.temp2Array}
					temp3Array={this.state.temp3Array}
					temp4Array={this.state.temp4Array}
					temp5Array={this.state.temp5Array}
					temp6Array={this.state.temp6Array}
					tempMaxArray={this.state.tempMaxArray}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "settings") {
			return (
				<BatteryLogSettings
					operation={this.state.operation}
					dataInterval={this.props.dataInterval}
					batteryTotalVoltageYN={this.props.batteryTotalVoltageYN}
					batteryCurrentYN={this.props.batteryCurrentYN}
					batterySocYN={this.props.batterySocYN}
					batteryResidualCapacityYN={this.props.batteryResidualCapacityYN}
					batteryMinCellVoltageValueYN={this.props.batteryMinCellVoltageValueYN}
					batteryTemp1YN={this.props.batteryTemp1YN}
					batteryTemp2YN={this.props.batteryTemp2YN}
					batteryTemp3YN={this.props.batteryTemp3YN}
					batteryTemp4YN={this.props.batteryTemp4YN}
					batteryTemp5YN={this.props.batteryTemp5YN}
					batteryTemp6YN={this.props.batteryTemp6YN}
					batteryTempMaxYN={this.props.batteryTempMaxYN}
					setSelectedTab={this.setSelectedTab}
					setBatteryLogSettingsValue={this.props.setBatteryLogSettingsValue}
					getLang={this.props.getLang}
				/>
			);
		}
	}

	render() {
		return (
			<div>
				<BatteryLogToolbar
					batteryLogID={this.props.batteryLogID}
					dataCount={this.state.dataCount}
					selectedTab={this.state.selectedTab}
					setSelectedTab={this.setSelectedTab}
					getLang={this.props.getLang}
				/>
				{this.renderTab()}
			</div>
		);
	}
}

export default BatteryLog;



