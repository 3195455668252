import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';

var moment = require('moment');

class BatteryInfo extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			batteryCommentjsonObj: null,
			index: 0,
			count: 10,
		};

		this.serverGetBatteryCommentList = this.serverGetBatteryCommentList.bind(this);
		this.getDateStringFromUnixTimeSec = this.getDateStringFromUnixTimeSec.bind(this);
		this.getDateTimeStringFromUnixTimeSec = this.getDateTimeStringFromUnixTimeSec.bind(this);
		this.getYesNo = this.getYesNo.bind(this);
		this.renderOneComment = this.renderOneComment.bind(this);
		this.renderPrevNext = this.renderPrevNext.bind(this);
		this.renderAllComments = this.renderAllComments.bind(this);
	}

	componentDidMount() {
		this.serverGetBatteryCommentList(this.props.batteryID, this.state.index, this.state.count);
	}
	
	async serverGetBatteryCommentList(batteryID, index, count) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbatterycommentlist?batteryid=" + batteryID;
		url = url + "&sortby=batteryCommentID&sortasc=0";
		url = url + "&index=" + index + "&count=" + this.state.count;

		const res = await fetch(url);
		const jsonObj = await res.json();
		
		this.setState({
			batteryCommentjsonObj: jsonObj,
		});
	}
	
	getDateStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		var dateString = moment(dateObj).format('DD.MM.YYYY');
		return dateString;
	}

	getDateTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);

		// 50 minutes ago

		var dateTimeString = moment(dateObj).format('DD.MM.YYYY h:mm:ss');
		return dateTimeString;
	}
	
	getYesNo(value) {
		if (value === 0) {
			return this.props.getLang(BatteryLang, "no");
		} else {
			return this.props.getLang(BatteryLang, "yes");
		}
	}

	renderOneComment(batteryCommentID, commentText, userName, createdUnixTimeSec, editedUnixTimeSec) {
		
		var commentJSX = this.props.convertTextNewlineToBr(commentText, "comment" + batteryCommentID, "BatteryInfoItem");
		var dateTimeString = this.getDateTimeStringFromUnixTimeSec(createdUnixTimeSec);

		return (
			<div key={batteryCommentID}>
				<hr/>
				<div className="BatteryInfoCommentUsername">{userName}</div>
				<div className="BatteryInfoCommentText">{commentJSX}</div>
				<div className="BatteryInfoCommentDateTime">{dateTimeString}</div>
			</div>
		);
	}
	
	renderPrevNext(prevYN, nextYN) {
		
		if ((!prevYN) && (!nextYN)) {
			return null;
		}
		
		return (
			<div className="ListContainer3">
				{prevYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handlePrev()}
				>
					&lt;&lt; Previous
				</div>)}
				{!prevYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					&lt;&lt; Previous
				</div>)}
				<div className="ListPrevNextLinkDisabled">|</div>
				{nextYN && (<div 
					className="ListPrevNextLink"
					onClick={() => this.handleNext()}
				>
					Next &gt;&gt;
				</div>)}
				{!nextYN && (<div 
					className="ListPrevNextLinkDisabled"
				>
					Next &gt;&gt;
				</div>)}
			</div>
		);
	}
		
	renderAllComments() {
		
		var commentCount = 0;
		var batteryCommentjsonObj = this.state.batteryCommentjsonObj;

		if (batteryCommentjsonObj !== null) {
			commentCount = batteryCommentjsonObj.length;
		}
		if (commentCount === 0) {
			return null;
		}

		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (commentCount === this.state.count) {
			nextYN = true;
		}

		var i;
		var itemObj;
		var batteryCommentID;
		var userName;
		var commentText;
		var createdUnixTimeSec;
		var editedUnixTimeSec;
		var jsxArray = [];

		for(i=0; i<batteryCommentjsonObj.length; i++) {
			itemObj = batteryCommentjsonObj[i];
			batteryCommentID = itemObj.batteryCommentID;
			commentText = itemObj.commentText;
			userName = itemObj.userName;
			createdUnixTimeSec = itemObj.createdUnixTimeSec;
			editedUnixTimeSec = itemObj.editedUnixTimeSec;
			jsxArray.push(this.renderOneComment(batteryCommentID, commentText, userName, createdUnixTimeSec, editedUnixTimeSec));
		}

		return (
			<div>
				<div className="">{jsxArray}</div>
				<div style={{height:"30px"}}></div>
				{this.renderPrevNext(prevYN, nextYN)}
			</div>
		);
	}
	
	render() {		

		var batteryID = this.props.batteryID;
		var batteryName = this.props.batteryName;
		var batteryMacAddress = this.props.batteryMacAddress;
		var companyName = this.props.companyName;
		var softwareVersion = this.props.softwareVersion;
		var lastServiceUnixTimeSec = this.props.lastServiceUnixTimeSec;
		var notes = this.props.notes;
		var testedByUserID = this.props.testedByUserID;
		var testedByFullName = this.props.testedByFullName;
		var batteryMadeUnixTimeSec = this.props.batteryMadeUnixTimeSec;
		var batterySoldUnixTimeSec = this.props.batterySoldUnixTimeSec;
		var batteryMadeByUserID = this.props.batteryMadeByUserID;
		var batteryMadeByFullName = this.props.batteryMadeByFullName;
		var nfcID = this.props.nfcID;
		var screwType = this.props.screwType;
		var firestop = this.props.firestop;
		var bmsVersion = this.props.bmsVersion;
		var esp = this.props.esp;
		var diff = this.props.diff;
		var bimetal = this.props.bimetal;
		var testHighCurrent = this.props.testHighCurrent;
		var testCage = this.props.testCage;
		var testTemperature = this.props.testTemperature;
		var testPool = this.props.testPool;
		var testPressure = this.props.testPressure;
		var rent = this.props.rent;
		
		var lastServiceDateString = this.getDateStringFromUnixTimeSec(lastServiceUnixTimeSec);
		var batteryMadeDateString = this.getDateStringFromUnixTimeSec(batteryMadeUnixTimeSec);
		var batterySoldDateString = this.getDateStringFromUnixTimeSec(batterySoldUnixTimeSec);
		var notesJSX = this.props.convertTextNewlineToBr(notes, "notes", "BatteryInfoItem");
		
		var screwTypeString;
		if (screwType === 0) {
			screwTypeString = this.props.getLang(BatteryLang, "screwType0");
		} else {
			screwTypeString = this.props.getLang(BatteryLang, "screwType1");
		}
		
		return (
			<div>
				<p><b className="BatteryInfoTitle">{this.props.getLang(BatteryLang, "batteryInfo")}</b></p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "batteryName")}: {batteryName}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "company")}: {companyName}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "macAddress")}: {batteryMacAddress}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "nfcID")}: {nfcID}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "softwareVersion")}: {softwareVersion}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "batteryMadeBy")}: {batteryMadeByFullName}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "batteryMade")}: {batteryMadeDateString}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "testedBy")}: {testedByFullName}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "testHighCurrent")}: {this.getYesNo(testHighCurrent)}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "testCage")}: {this.getYesNo(testCage)}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "testTemperature")}: {this.getYesNo(testTemperature)}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "testPool")}: {this.getYesNo(testPool)}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "testPressure")}: {this.getYesNo(testPressure)}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "batterySold")}: {batterySoldDateString}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "lastService")}: {lastServiceDateString}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "rent")}: {this.getYesNo(rent)}</p>
				<div style={{height: "15px"}}></div>
				<div className="BatteryInfoItem">{notesJSX}</div>

				<p><b className="BatteryInfoTitle">{this.props.getLang(BatteryLang, "technicalDetails")}</b></p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "bmsVersion")}: {bmsVersion}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "diff")}: {diff}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "screwType")}: {screwTypeString}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "firestop")}: {this.getYesNo(firestop)}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "bimetal")}: {this.getYesNo(bimetal)}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "esp")}: {this.getYesNo(esp)}</p>
				<p className="BatteryInfoItem">{this.props.getLang(BatteryLang, "batteryIDDB")}: {batteryID}</p>
				
				
				<div style={{height: "30px"}}></div>
				{this.renderAllComments()}
			</div>
		);
	}
}

export default BatteryInfo;
