import React  from 'react';
import './App.css';
import './Ride.css';
import RideLang from './RideLang.json';

class RideToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
		this.renderLinkMap = this.renderLinkMap.bind(this);
	}
	
	handleSelectLink(tab) {
		this.props.setSelectedTab(tab);
	}
	
	renderLink(linkTitle, linkValue) {

		var selectedTab = this.props.selectedTab;
		var classNameString = "RideToolbarLink";
		
		if (selectedTab === linkValue) {
			classNameString = "RideToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	renderLinkMap(linkTitle, linkValue) {

		var selectedTab = this.props.selectedTab;
		var classNameString = "RideToolbarLink";
		
		if (selectedTab === linkValue) {
			classNameString = "RideToolbarSelectedLink";
		}
		
		var logFormat = this.props.logFormat;
		var showMapYN = false;
		if (logFormat === 2) {
			showMapYN = true;
		}
		
		if (showMapYN) {
			return (
				<div 
					className={classNameString}
					onClick={() => this.handleSelectLink(linkValue)}
				>
					{linkTitle}
				</div>
			);
		}

		return (
			<div 
				className="RideToolbarDisabledLink"
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {
		
		var statsText = this.props.getLang(RideLang, "stats");
		var chartText = this.props.getLang(RideLang, "chart");
		var mapText = this.props.getLang(RideLang, "map");
		var tableText = this.props.getLang(RideLang, "table");
		var downloadText = this.props.getLang(RideLang, "download");
		var settingsText = this.props.getLang(RideLang, "settings");
		
		return (
			<div className="RideToolbarContainer1">
				<div className="RideToolbarLeft">{this.props.getLang(RideLang, "rideID")}: {this.props.rideID}</div>
				<div className="RideToolbarRight">
					<div className="RideToolbarContainer2">
						{this.renderLink(statsText, "stats")} <div className="RideToolbarText">|</div>  
						{this.renderLink(chartText, "chart")} <div className="RideToolbarText">|</div>
						{this.renderLinkMap(mapText, "map")} <div className="RideToolbarText">|</div>
						{this.renderLink(tableText, "table")} <div className="RideToolbarText">|</div>
						{this.renderLink(downloadText, "download")} <div className="RideToolbarText">|</div>
						{this.renderLink(settingsText, "settings")}
					</div>
				</div>
			</div>
		);
	}
}

export default RideToolbar;

