import React  from 'react';
import './App.css';
import './Ride.css';
import RideLang from './RideLang.json';

class RideChartCustomTooltip extends React.Component {

	constructor(props) {

		super(props);

		this.getValue = this.getValue.bind(this);
		this.renderOneLine = this.renderOneLine.bind(this);
		this.renderAllLines = this.renderAllLines.bind(this);		
	}
	
	getValue(time, name) {
		var dataInterval = this.props.dataInterval;
		var time2 = time / dataInterval;

		if (name === this.props.getLang(RideLang, "sensorThrottle")) {
			return this.props.sensorThrottleArrayCurrent[time2];
		}
		if (name === this.props.getLang(RideLang, "throttle")) {
			return this.props.throttleArrayCurrent[time2];
		}
		if (name === this.props.getLang(RideLang, "escTemperature")) {
			return this.props.escTempArrayCurrent[time2];
		}
		if (name === this.props.getLang(RideLang, "escCurrent")) {
			return this.props.escCurrentArrayCurrent[time2];
		}
		if (name === this.props.getLang(RideLang, "batteryPercentage")) {
			return this.props.batteryPercentageArrayCurrent[time2];
		}
		if (name === this.props.getLang(RideLang, "batteryVoltage")) {
			return this.props.batteryVoltageArrayCurrent[time2];
		}
		if (name === this.props.getLang(RideLang, "batteryCurrent")) {
			return this.props.batteryCurrentArrayCurrent[time2];
		}
		if (name === this.props.getLang(RideLang, "minCellVoltage")) {
			return this.props.minCellVoltageArrayCurrent[time2];
		}
		if (name === this.props.getLang(RideLang, "batteryTemperature")) {
			return this.props.bmsTempArrayCurrent[time2];
		}
		if (name === this.props.getLang(RideLang, "gpsSpeed")) {
			var meterPerSecond = this.props.gpsSpeedArrayCurrent[time2]
			var knots = 1.9438452 * meterPerSecond;
			var kmPerHour = 3.6 * meterPerSecond;
			knots = knots.toFixed(2) + " knots";
			kmPerHour = kmPerHour.toFixed(2) + " km/h";
			return knots + ", " + kmPerHour;
		}
		if (name === this.props.getLang(RideLang, "whPerDistance")) {
			var whPerKm = this.props.whPerKmArrayCurrent[time2]
			var whPerNauticalMile = 1.852 * whPerKm;
			var whPerKmString = whPerKm.toFixed(2) + " Wh/km";
			var whPerNauticalMileString = whPerNauticalMile.toFixed(2) + " Wh/nautical mile";
			return whPerNauticalMileString + ", " + whPerKmString;
		}

		return 0;
	}
	
	renderOneLine(index, time, line) {
		//console.log("time = " + time);
		//console.log(line);
		var name = line.name;
		var color = line.color;
		var value = this.getValue(time, name);
		const divStyle = { color: color };
		return (<div key={index} style={divStyle}>{name}: {value}</div>);
	}
	
	renderAllLines(payload) {

		var i;
		var time;
		var line;
		var length = payload.length;
		var jsxArray = [];
		
		//console.log("length = " + length);
		//console.log(payload);
		
		time = payload[0].payload.time;
		var timeString = this.props.getTimeStringFromSeconds(time);

		jsxArray.push((<div key="time">{timeString}</div>));
		for(i=0; i<length; i++) {
			line = payload[i];
			jsxArray.push(this.renderOneLine(i, time, line));
		}

		return (<div>{jsxArray}</div>);
	}

	render() {

		var active = this.props.active;
		var payload = this.props.payload;
		
		if (!active) {
			return null;
		}

		if (payload === null) {
			return null;
		}
		
		return (
			<div className="RideChartCustomTooltip">
				{this.renderAllLines(payload)}
			</div>
		);
	}
}

export default RideChartCustomTooltip;

