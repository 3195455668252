import React  from 'react';
import './App.css';
import './Battery.css';
import BatteryLang from './BatteryLang.json';

import { useNavigate } from "react-router-dom";

class BatteryListToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(page) {
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderLink(linkTitle, linkValue) {

		var classNameString = "BatteryListToolbarLink";
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);
	}
	
	render() {

		var companyIDInit = this.props.companyIDInit;
		var companyName = this.props.companyName;
		var newBatteryText = this.props.getLang(BatteryLang, "newBattery");
		
		var showNewYN = false;
		if (companyIDInit === 1) {
			showNewYN = true;
		}

		return (
			<div className="BatteryListToolbarContainer1">
				<div className="BatteryListToolbarLeft">{companyName}</div>
				<div className="BatteryListToolbarRight">
					{showNewYN && (<div className="BatteryListToolbarContainer2">
						{this.renderLink(newBatteryText, "/batteries/new")} 
					</div>)}
				</div>
			</div>
		);
	}
}

export default function BatteryListToolbarFunction(props) {
	const navigate = useNavigate();
	return <BatteryListToolbar {...props} navigate={navigate} />;
}

