import React  from 'react';
import 'leaflet/dist/leaflet.css';
import './App.css';
import './Ride.css';
import './RideMap.css';
import RideLang from './RideLang.json';
import { MapContainer, TileLayer, useMap, LayerGroup, Marker, Popup, CircleMarker, Polyline } from 'react-leaflet'
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import L from 'leaflet';
import { useNavigate } from "react-router-dom";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const MyMarker = props => {

	const initMarker = ref => {
		if (ref) {
			if (props.popupOpenYN) {
				ref.openPopup();
			}
		}
	}

	return <Marker ref={initMarker} {...props}/>
}

class RideMap extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			index: 0,
			showAllPointsYN: false,
			popupOpenYN: true,
		}

		this.renderCurrentPointPopupOneLine = this.renderCurrentPointPopupOneLine.bind(this);
		this.renderCurrentPoint = this.renderCurrentPoint.bind(this);
		this.renderOnePoint = this.renderOnePoint.bind(this);
		this.renderAllPoints = this.renderAllPoints.bind(this);
		this.renderMap = this.renderMap.bind(this);
		this.onSliderChange = this.onSliderChange.bind(this);
		this.renderSlider = this.renderSlider.bind(this);
		this.handleCheckboxClick = this.handleCheckboxClick.bind(this);
		this.renderCheckbox = this.renderCheckbox.bind(this);
		this.handlePopupOpen = this.handlePopupOpen.bind(this);
		this.handlePopupClose = this.handlePopupClose.bind(this);
	}
	
	componentDidMount() {
	}

	renderCurrentPointPopupOneLine(renderYN, variable, value) {

		if (!renderYN) {
			return;
		}
		
		var variableText = this.props.getLang(RideLang, variable);
		
		return (
			<div>{variableText}: {value}</div>
		);
	}
	
	renderCurrentPoint() {
		
		var showAllPointsYN = this.state.showAllPointsYN;
		if (showAllPointsYN) {
			return;
		}

		var index = this.state.index;
		var gpsLng = this.props.gpsLngArrayCurrent[index];
		var gpsLat = this.props.gpsLatArrayCurrent[index];
		var position = [gpsLat, gpsLng];
		
		if ((Math.abs(gpsLng) < 0.00001) || (Math.abs(gpsLat) < 0.00001)) {
			return;
		}

		var time = this.props.timeArrayCurrent[index];
		var sensorThrottle = this.props.sensorThrottleArrayCurrent[index];
		var throttle = this.props.throttleArrayCurrent[index];
		var escTemp = this.props.escTempArrayCurrent[index];
		var escCurrent = this.props.escCurrentArrayCurrent[index];
		var batteryPercentage = this.props.batteryPercentageArrayCurrent[index];
		var batteryVoltage = this.props.batteryVoltageArrayCurrent[index];
		var batteryCurrent = this.props.batteryCurrentArrayCurrent[index];
		var minCellVoltage = this.props.minCellVoltageArrayCurrent[index];
		var bmsTemp = this.props.bmsTempArrayCurrent[index];
		var metersPerSecond = this.props.gpsSpeedArrayCurrent[index];
		var knots = 1.9438452 * metersPerSecond;
		var kmPerHour = 3.6 * metersPerSecond;
		var gpsSpeedString = knots.toFixed(0) + " knots, " + kmPerHour.toFixed(0) + " km/h";
		var whPerKm = this.props.whPerKmArrayCurrent[index];
		var whPerNauticalMile = 1.852 * whPerKm;
		var whPerDistanceString = whPerNauticalMile.toFixed(0) + " Wh/nautical mile, " + whPerKm.toFixed(0) + " Wh/km";

		var timeString =  this.props.getTimeStringFromSeconds(time);
		
		return (
			<MyMarker position={position} popupOpenYN={this.state.popupOpenYN}>
				<Popup onOpen={this.handlePopupClose} >
					<div><b>{timeString}</b></div>
					<div>{gpsLat}, {gpsLng}</div>
					{this.renderCurrentPointPopupOneLine(this.props.sensorThrottleYN, "sensorThrottle", sensorThrottle)}
					{this.renderCurrentPointPopupOneLine(this.props.throttleYN, "throttle", throttle)}
					{this.renderCurrentPointPopupOneLine(this.props.escTempYN, "escTemperature", escTemp)}
					{this.renderCurrentPointPopupOneLine(this.props.escCurrentYN, "escCurrent", escCurrent)}
					{this.renderCurrentPointPopupOneLine(this.props.batteryPercentageYN, "batteryPercentage", batteryPercentage)}
					{this.renderCurrentPointPopupOneLine(this.props.batteryVoltageYN, "batteryVoltage", batteryVoltage)}
					{this.renderCurrentPointPopupOneLine(this.props.batteryCurrentYN, "batteryCurrent", batteryCurrent)}
					{this.renderCurrentPointPopupOneLine(this.props.minCellVoltageYN, "minCellVoltage", minCellVoltage)}
					{this.renderCurrentPointPopupOneLine(this.props.bmsTempYN, "batteryTemperature", bmsTemp)}
					{this.renderCurrentPointPopupOneLine(this.props.gpsSpeedYN, "gpsSpeed", gpsSpeedString)}
					{this.renderCurrentPointPopupOneLine(this.props.whPerKmYN, "whPerKm", whPerDistanceString)}
				</Popup>
			</MyMarker>
		);
	}

	renderOnePoint(index) {

		var pointCount = this.props.dataCountCurrent;
		var gpsLng1 = this.props.gpsLngArrayCurrent[index];
		var gpsLat1 = this.props.gpsLatArrayCurrent[index];
		var gpsLng2;
		var gpsLat2;
		var position2;
		var polyline;
		var polylineOptions = { color: 'blue', weight: '1' }
		
		var position1 = [gpsLat1, gpsLng1];

		if (index >= pointCount-1) {
			gpsLng2 = this.props.gpsLngArrayCurrent[0];
			gpsLat2 = this.props.gpsLatArrayCurrent[0];
		} else {
			gpsLng2 = this.props.gpsLngArrayCurrent[index+1];
			gpsLat2 = this.props.gpsLatArrayCurrent[index+1];
		}

		if ((Math.abs(gpsLng1) < 0.00001) || (Math.abs(gpsLat1) < 0.00001)) {
			return;
		}

		if ((Math.abs(gpsLng2) < 0.00001) || (Math.abs(gpsLat2) < 0.00001)) {
			return;
		}

		position2 = [gpsLat2, gpsLng2];
		polyline = [position1, position2];

		return (
			<LayerGroup key={index}>
				<CircleMarker center={position1} fillColor="blue" radius={5} />
				<Polyline pathOptions={polylineOptions} positions={polyline} />
			</LayerGroup>
		);
	}

	renderAllPoints() {
		
		var showAllPointsYN = this.state.showAllPointsYN;
		if (!showAllPointsYN) {
			return;
		}

		var i;
		var gpsLng;
		var gpsLat;
		var dataCountCurrent = this.props.dataCountCurrent;
		var jsxCode;
		var jsxArray = [];

		for(i=0; i<dataCountCurrent; i++) {
			jsxCode = this.renderOnePoint(i);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	renderMap() {
		
		var i;
		var gpsLng;
		var gpsLat;
		var gpsLngSum = 0;
		var gpsLatSum = 0;
		var gpsLngAverage;
		var gpsLatAverage;
		var dataCountCurrent = this.props.dataCountCurrent;
		var count = 0;

		for(i=0; i<dataCountCurrent; i++) {
			gpsLng = this.props.gpsLngArrayCurrent[i];
			gpsLat = this.props.gpsLatArrayCurrent[i];
			if (Math.abs(gpsLng) > 0.01) {
				gpsLngSum = gpsLngSum + gpsLng;
				gpsLatSum = gpsLatSum + gpsLat;
				count = count + 1;
			}
		}
		
		if (count === 0) {
			gpsLngAverage = 0;
			gpsLatAverage = 0;
		} else {
			gpsLngAverage = gpsLngSum / count;
			gpsLatAverage = gpsLatSum / count;
		}

		var position = [gpsLatAverage, gpsLngAverage];
		
		return (
			<MapContainer 
				className="RideMapContainer1" 
				center={position} 
				zoom={15} 
				scrollWheelZoom={true}
				whenReady={(map) => {
					map.target.on("popupopen", e => this.handlePopupOpen(e));
					map.target.on("popupclose", e => this.handlePopupClose(e));
				}}>
			>
				<TileLayer
				  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				{this.renderAllPoints()}
				{this.renderCurrentPoint()}
			</MapContainer>
		);
	}
	
	onSliderChange(value) {

		var index = parseInt(value);

		var dataCountCurrent = this.props.dataCountCurrent;

		if (index < 0) {
			return;
		}
		if (index >= dataCountCurrent) {
			return;
		}
		
		this.setState({
			index: index,
		});
	}
	
	renderSlider() {

		var showAllPointsYN = this.state.showAllPointsYN;
		var dataCountCurrent = this.props.dataCountCurrent;
		var sliderMax = dataCountCurrent - 1;
		var index = this.state.index;
		
		if (showAllPointsYN) {
			return;
		}

		return (
			<div className="RideMapSliderContainer">
				<Slider 
					min={0} 
					max={sliderMax} 
					defaultValue={index}
					onChange={this.onSliderChange}
				/>
			</div>
		);
	}
	
	handleCheckboxClick(event) {
		var target = event.target;
		var value = target.type === 'checkbox' ? target.checked : target.value;
		this.setState({
			showAllPointsYN: value,
			popupOpenYN: true,
		});
	}

	handlePopupOpen(event) {
		this.setState({popupOpenYN: true});
	}

	handlePopupClose(event) {
		this.setState({popupOpenYN: false});
	}

	renderCheckbox() {
		
		var showAllPointsYN = this.state.showAllPointsYN;
		
		return (
			<div className="RideMapContainer2">
				<input 
					type="checkbox" 
					className="RideMapCheckbox" 
					id="showAllPoints" 
					name="showAllPoints"
					checked={showAllPointsYN}
					onChange={(event) => this.handleCheckboxClick(event)}
				/>
				<label htmlFor="showAllPoints" className="RideMapText">{this.props.getLang(RideLang, "showAllPoints")}</label>
			</div>
		)
	}

	render() {

		return (
			<div className="RideMapTopContainer">
				<div style={{height: "1px"}}></div>
				{this.renderMap()}
				<div style={{height: "10px"}}></div>
				{this.renderSlider()}
				{this.renderCheckbox()}
			</div>
		);
	}
}

// handleRender={sliderHandleRender} 

export default function RideMapFunction(props) {
	const navigate = useNavigate();
	return <RideMap {...props} navigate={navigate} />;
}

