import React  from 'react';
import './App.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BoardLang from './BoardLang.json';

import CompanyPicker from './CompanyPicker.js';
import UserPicker from './UserPicker.js';

import { useNavigate } from "react-router-dom";

class BoardEdit extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			boardID: 0,
			boardNumber: "",
			boardMacAddress: "",
			companyID: 0,
			directCool: 0,
			multiLog: 0,
			escSoftwareVersion: "",
			microSoftwareVersion: "",
			lastServiceUnixTimeSec: 0,
			notes: "",
			testedByUserID: 0,
			testedByUserName: "",
			boardMadeUnixTimeSec: 0,
			boardSoldUnixTimeSec: 0,
			boardColor: "",
			evaMatColor: "",
			screwType: 0,
			electronics: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onBoardNumberChanged = this.onBoardNumberChanged.bind(this);
		this.onBoardMacAddressChanged = this.onBoardMacAddressChanged.bind(this);
		this.onCompanyIDChanged = this.onCompanyIDChanged.bind(this);
		this.onTestedByUserIDChanged = this.onTestedByUserIDChanged.bind(this);
		this.lastServiceSetDate = this.lastServiceSetDate.bind(this);
		this.boardMadeSetDate = this.boardMadeSetDate.bind(this);
		this.boardSoldSetDate = this.boardSoldSetDate.bind(this);
		this.handleDirectCoolClick = this.handleDirectCoolClick.bind(this);
		this.handleMultiLogClick = this.handleMultiLogClick.bind(this);
		this.onNotesChanged = this.onNotesChanged.bind(this);
		this.serverSetBoardData = this.serverSetBoardData.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
		this.getDateObjFromUnixTimeSec = this.getDateObjFromUnixTimeSec.bind(this);
		this.handleScrewTypeClick = this.handleScrewTypeClick.bind(this);
		this.onEvaMatColorChanged = this.onEvaMatColorChanged.bind(this);
		this.onElectronicsChanged = this.onElectronicsChanged.bind(this);
		this.onBoardColorChanged = this.onBoardColorChanged.bind(this);
	}
	
	componentDidMount() {
		this.setState({
			boardID: this.props.boardID,
			boardNumber: this.props.boardNumber,
			boardMacAddress: this.props.boardMacAddress,
			companyID: this.props.companyID,
			directCool: this.props.directCool,
			multiLog: this.props.multiLog,
			escSoftwareVersion: this.props.escSoftwareVersion,
			microSoftwareVersion: this.props.microSoftwareVersion,
			lastServiceUnixTimeSec: this.props.lastServiceUnixTimeSec,
			notes: this.props.notes,
			testedByUserID: this.props.testedByUserID,
			testedByUserName: this.props.testedByUserName,
			boardMadeUnixTimeSec: this.props.boardMadeUnixTimeSec,
			boardSoldUnixTimeSec: this.props.boardSoldUnixTimeSec,
			boardColor: this.props.boardColor,
			evaMatColor: this.props.evaMatColor,
			screwType: this.props.screwType,
			electronics: this.props.electronics,
		});
	}

	// do we need this?
	componentDidUpdate(prevProps) {

		var boardID1 = this.state.boardID;
		var boardID2 = this.props.boardID;
		
		if (boardID1 === boardID2) {
			return;
		}

		this.setState({
			boardID: this.props.boardID,
		});
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onBoardNumberChanged(value) {
		var boardNumber = parseInt(value);
		this.setState({boardNumber: boardNumber});
	}
	
	onBoardMacAddressChanged(value) {
		this.setState({boardMacAddress: value});
	}
	
	onNotesChanged(value) {
		this.setState({notes: value});
	}
	
	onCompanyIDChanged(companyID) {
		this.setState({companyID: companyID});
	}
	
	onTestedByUserIDChanged(userID, userName) {
		this.setState({
			testedByUserID: userID,
			testedByUserName: userName,
		});
	}

	lastServiceSetDate(dateObj) {
		var lastServiceUnixTimeSec = 0;		
		if (dateObj === null) {
			lastServiceUnixTimeSec = null;
		} else {
			lastServiceUnixTimeSec = dateObj.getTime() / 1000;
		}
		this.setState({lastServiceUnixTimeSec: lastServiceUnixTimeSec});
	}
		
	boardMadeSetDate(dateObj) {
		var boardMadeUnixTimeSec = 0;		
		if (dateObj === null) {
			boardMadeUnixTimeSec = null;
		} else {
			boardMadeUnixTimeSec = dateObj.getTime() / 1000;
		}
		this.setState({boardMadeUnixTimeSec: boardMadeUnixTimeSec});
	}
	
	boardSoldSetDate(dateObj) {
		var boardSoldUnixTimeSec = 0;		
		if (dateObj === null) {
			boardSoldUnixTimeSec = null;
		} else {
			boardSoldUnixTimeSec = dateObj.getTime() / 1000;
		}
		this.setState({boardSoldUnixTimeSec: boardSoldUnixTimeSec});
	}	
	
	handleDirectCoolClick(event) {
		var value = event.target.checked;
		this.setState({directCool: value});		
	}
	
	handleMultiLogClick(event) {
		var value = event.target.checked;
		this.setState({multiLog: value});		
	}
	
	async serverSetBoardData(boardID, boardNumber, boardMacAddress, companyID, directCool, multiLog, lastServiceUnixTimeSec, notes, testedByUserID, testedByUserName, boardMadeUnixTimeSec, boardSoldUnixTimeSec, evaMatColor, screwType, electronics, boardColor) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/setboarddata?boardid=" + boardID;
		url = url + "&boardnumber=" + boardNumber;
		url = url + "&boardmacaddress=" + encodeURIComponent(boardMacAddress);
		url = url + "&companyid=" + companyID;
		url = url + "&directcool=" + directCool;
		url = url + "&multilog=" + multiLog;
		url = url + "&lastserviceunixtimesec=" + lastServiceUnixTimeSec;
		url = url + "&notes=" + encodeURIComponent(notes);
		url = url + "&testedbyuserid=" + testedByUserID;
		url = url + "&testedbyusername=" + encodeURIComponent(testedByUserName);
		url = url + "&boardmadeunixtimesec=" + boardMadeUnixTimeSec;
		url = url + "&boardsoldunixtimesec=" + boardSoldUnixTimeSec;
		url = url + "&evamatcolor=" + encodeURIComponent(evaMatColor);
		url = url + "&screwtype=" + screwType;
		url = url + "&electronics=" + encodeURIComponent(electronics);
		url = url + "&boardcolor=" + encodeURIComponent(boardColor);
		
		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(BoardLang, "boardDataSaved"));
		this.props.setSelectedTab("info")	;
		this.props.serverGetBoardData(boardID);	
	}
		
	onClickSaveButton() {
		
		var boardNumber = this.state.boardNumber;
		if (boardNumber === "") {
			boardNumber = 0;
		}
		if (boardNumber === 0) {
			this.props.showToast(this.props.getLang(BoardLang, "boardNumberEmpty"));
			return;
		}

		var boardID = this.state.boardID;
		var boardMacAddress = this.state.boardMacAddress;
		var companyID = this.state.companyID;
		var lastServiceUnixTimeSec = this.state.lastServiceUnixTimeSec;
		var notes = this.state.notes;
		var testedByUserID = this.state.testedByUserID;
		var testedByUserName = this.state.testedByUserName;
		var boardMadeUnixTimeSec = this.state.boardMadeUnixTimeSec;
		var boardSoldUnixTimeSec = this.state.boardSoldUnixTimeSec;
		var evaMatColor = this.state.evaMatColor;
		var screwType = this.state.screwType;
		var electronics = this.state.electronics;
		var boardColor = this.state.boardColor;

		var directCoolValue = 0;
		if (this.state.directCool) {
			directCoolValue = 1;
		}

		var multiLogValue = 0;
		if (this.state.multiLog) {
			multiLogValue = 1;
		}

		this.serverSetBoardData(boardID, boardNumber, boardMacAddress, companyID, directCoolValue, multiLogValue, lastServiceUnixTimeSec, notes, testedByUserID, testedByUserName, boardMadeUnixTimeSec, boardSoldUnixTimeSec, evaMatColor, screwType, electronics, boardColor);
	}
	
	onClickCancelButton() {
		this.props.setSelectedTab("info");
	}
	
	getDateObjFromUnixTimeSec(unixTimeSec) {

		var dateObj;
		
		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			//dateObj = new Date();
			dateObj = null;
		} else {
			dateObj = new Date(unixTimeSec * 1000);
		}
		return dateObj;
	}

	handleScrewTypeClick(value) {
		this.setState({screwType: value});		
	}

	onEvaMatColorChanged(value) {
		this.setState({evaMatColor: value});
	}
	
	onElectronicsChanged(value) {
		this.setState({electronics: value});
	}
	
	onBoardColorChanged(value) {
		this.setState({boardColor: value});
	}

	render() {		
	
		if (this.state.boardID === 0) {
			return null;
		}

		var lastServiceDateObj = this.getDateObjFromUnixTimeSec(this.state.lastServiceUnixTimeSec);
		var boardMadeDateObj = this.getDateObjFromUnixTimeSec(this.state.boardMadeUnixTimeSec);
		var boardSoldDateObj = this.getDateObjFromUnixTimeSec(this.state.boardSoldUnixTimeSec);		
		
		return (
		<div>
			<div className="ViewContainer1">
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(BoardLang, "editBoardInfo")}</div>
				<div className="ViewTopText">{this.props.getLang(BoardLang, "editBoardInfoText")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(BoardLang, "boardInfo")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewContainer2">
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "boardID")}</legend> 
							<input
								className="ViewItemInput" 
								id="boardID" 
								type="text" 
								defaultValue={this.state.boardID}
								disabled={true}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "boardNumber")}</legend> 
							<input
								className="ViewItemInput" 
								id="boardNumber" 
								type="number" 
								defaultValue={this.state.boardNumber}
								onChange={(event) => this.onBoardNumberChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
								autoFocus={true}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "macAddress")}</legend> 
							<input
								className="ViewItemInput" 
								id="boardMacAddress" 
								type="text"
								defaultValue={this.state.boardMacAddress}
								onChange={(event) => this.onBoardMacAddressChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "company")}</legend> 
							<CompanyPicker
								className="ViewItemCompany" 
								managementBaseURLAPI={this.props.managementBaseURLAPI}
								companyID={this.state.companyID}
								onChange={this.onCompanyIDChanged}
								getLang={this.props.getLang}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "technicalDetails")}</legend> 
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="directCool"
								name="directCool" 
								checked={this.state.directCool}
								onChange={(event) => this.handleDirectCoolClick(event)}
							/> 
							<label htmlFor="directCool" className="ViewItemCheckboxLabel">{this.props.getLang(BoardLang, "directCool")}</label>
							<div style={{width: "1px"}}></div>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="multiLog"
								name="multiLog" 
								checked={this.state.multiLog}
								onChange={(event) => this.handleMultiLogClick(event)}
							/> 
							<label htmlFor="multiLog" className="ViewItemCheckboxLabel">{this.props.getLang(BoardLang, "multiLog")}</label>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "boardColor")}</legend> 
							<input
								className="ViewItemInput" 
								id="boardColor" 
								type="text"
								defaultValue={this.state.boardColor}
								onChange={(event) => this.onBoardColorChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "evaMatColor")}</legend> 
							<input
								className="ViewItemInput" 
								id="evaMatColor" 
								type="text"
								defaultValue={this.state.evaMatColor}
								onChange={(event) => this.onEvaMatColorChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "screwType")}</legend> 
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="screwType0"
								name="screwType0" 
								checked={this.state.screwType===0}
								onChange={(event) => this.handleScrewTypeClick(0)}
							/>
							<label htmlFor="screwType0" className="ViewItemCheckboxLabel">{this.props.getLang(BoardLang, "screwType0")}</label>
							<br/>
							<input 
								type="checkbox" 
								className="ViewItemCheckbox"
								id="screwType1"
								name="screwType1" 
								checked={this.state.screwType===1}
								onChange={(event) => this.handleScrewTypeClick(1)}
							/> 
							<label htmlFor="screwType1" className="ViewItemCheckboxLabel">{this.props.getLang(BoardLang, "screwType1")}</label>
						</fieldset>						
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "electronics")}</legend> 
							<input
								className="ViewItemInput" 
								id="electronics" 
								type="text"
								defaultValue={this.state.electronics}
								onChange={(event) => this.onElectronicsChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "lastService")}</legend> 
							<DatePicker 
								className="ViewItemDateString" 
								selected={lastServiceDateObj} onChange={(date) => this.lastServiceSetDate(date)} 
								dateFormat="dd.MM.yyyy."
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "testedBy")}</legend> 
							<UserPicker
								className="ViewItemUser" 
								managementBaseURLAPI={this.props.managementBaseURLAPI}
								companyID={1}
								userID={this.state.testedByUserID}
								userName={this.state.testedByUserName}
								onChange={this.onTestedByUserIDChanged}
								getLang={this.props.getLang}
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "boardMade")}</legend> 
							<DatePicker 
								className="ViewItemDateString" 
								selected={boardMadeDateObj} onChange={(date) => this.boardMadeSetDate(date)} 
								dateFormat="dd.MM.yyyy."
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "boardSold")}</legend> 
							<DatePicker 
								className="ViewItemDateString" 
								selected={boardSoldDateObj} onChange={(date) => this.boardSoldSetDate(date)} 
								dateFormat="dd.MM.yyyy."
							/>
						</fieldset>
						<div style={{height: "10px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(BoardLang, "notes")}</legend> 
							<textarea
								className="ViewItemInput" 
								id="notes" 
								defaultValue={this.state.notes}
								rows={15}
								onChange={(event) => this.onNotesChanged(event.target.value)}
						/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(BoardLang, "cancel")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton}>{this.props.getLang(BoardLang, "save")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		</div>
		);
	}
}

export default function BoardEditFunction(props) {
	const navigate = useNavigate();
	return <BoardEdit {...props} navigate={navigate} />;
}

