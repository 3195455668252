import React from 'react';
import './App.css';
import './Navbar.css';
import { AiOutlineSelect, AiOutlineDashboard, AiOutlineLogout } from "react-icons/ai"
import { MdSurfing } from "react-icons/md"
import { BsBatteryFull, BsBatteryCharging } from "react-icons/bs"
import { HiOutlineDocumentReport } from "react-icons/hi"
import { FaInfo } from "react-icons/fa"
import NavbarLang from './NavbarLang.json';

import { useNavigate } from "react-router-dom";

class Navbar extends React.Component {

	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	
	handleClick(page) {

		const navigate = this.props.navigate;

		if (page === "/logout") {
			this.props.logoutClearVariables();
			navigate("/login");
		} else {
			navigate(page);
		}

		this.props.closeNavbar();
	}
	
	render() {

		var navbarOpenYN = this.props.navbarOpenYN;
		if (!navbarOpenYN) {
			return (<div></div>);
		}

		var sailfinAppUserType = this.props.sailfinAppUserType;
		
		if (sailfinAppUserType >= 100) {
		}

		var companyIDInit = this.props.companyIDInit;
		
		// plurato
		var showBatteryTesterYN = false;
		var showSelectCompanyYN = false;
		if (companyIDInit === 1) {
			showBatteryTesterYN = true;
			showSelectCompanyYN = true;
		}

		return (
			<div className="Navbar">
				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/dashboard")}
				>
						<div className="NavbarLinkColumn1">
							<AiOutlineDashboard style={{ color: "white", width: "25px", height: "25px" }} />
						</div>
						<div className="NavbarLinkColumn2">{this.props.getLang(NavbarLang, "dashboard")}</div>					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/boards")}
				>
					<div className="NavbarLinkColumn1">
						<MdSurfing style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(NavbarLang, "boards")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/batteries")}
				>
					<div className="NavbarLinkColumn1">
						<BsBatteryFull style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(NavbarLang, "batteries")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/batterylogall")}
				>
					<div className="NavbarLinkColumn1">
						<HiOutlineDocumentReport style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(NavbarLang, "batteryLogs")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/geofences")}
				>
					<div className="NavbarLinkColumn1">
						<BsBatteryFull style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(NavbarLang, "geofences")}</div>				  					  
				</div>

				{showBatteryTesterYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/batterytester")}
				>
					<div className="NavbarLinkColumn1">
						<BsBatteryCharging style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(NavbarLang, "batteryTester")}</div>
				</div>)}

				{showBatteryTesterYN && (<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/selectcompany")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineSelect style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(NavbarLang, "selectCompany")}</div>
				</div>)}

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/about")}
				>
					<div className="NavbarLinkColumn1">
						<FaInfo style={{ color: "white", width: "25px", height: "20px" }} />
					</div>
					<div className="NavbarLinkColumn2">{this.props.getLang(NavbarLang, "about")}</div>				  					  
				</div>

				<div
				  role="menuitem"
				  className="NavbarLink"
				  onClick={() => this.handleClick("/logout")}
				>
					<div className="NavbarLinkColumn1">
						<AiOutlineLogout style={{ color: "white", width: "25px", height: "25px" }} />
					</div>
					<div className="NavbarLinkColumn2">Logout</div>
				</div>
				<div
				  role="menuitem"
				  className="NavbarBottom"
				>
				</div>
			</div>
		);
	}
}

export default function NavbarFunction(props) {
	const navigate = useNavigate();
	return <Navbar {...props} navigate={navigate} />;
}

