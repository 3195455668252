import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';
import { CSVLink } from "react-csv";
import moment from 'moment';

class BatteryLogDownload extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			filename: "",
			csvData: [],
		};

		this.getFilenameDateTimeStringFromUnixTimeSec=this.getFilenameDateTimeStringFromUnixTimeSec.bind(this);
		this.getFilename=this.getFilename.bind(this);
		this.saveCSVFile=this.saveCSVFile.bind(this);
	}
	
	componentDidMount() {
		this.saveCSVFile();
	}

	getFilenameDateTimeStringFromUnixTimeSec(unixTimeSec) {

		if (unixTimeSec === null) {
			unixTimeSec = 0;
		}
		
		if (unixTimeSec === 0) {
			return "";
		}

		var dateObj = new Date(unixTimeSec * 1000);
		
		var dateTimeString = moment(dateObj).format('YYYY-MM-DD HH-mm');

		return dateTimeString;
	}

	getFilename(batteryName, uploadedUnixTimeSec) {

		var dateTimeString = this.getFilenameDateTimeStringFromUnixTimeSec(uploadedUnixTimeSec);

		var filename = batteryName + " " + dateTimeString + ".csv";

		return filename;
	}

	saveCSVFile() {
		
		//var batteryLogID = this.props.batteryLogID;
		var batteryName = this.props.batteryName;
		var uploadedUnixTimeSec = this.props.uploadedUnixTimeSec;
		var dataCount = this.props.dataCount;
		var timeArray = this.props.timeArray;
		var totalVoltageArray = this.props.totalVoltageArray;
		var currentArray = this.props.currentArray;
		var residualCapacityArray = this.props.residualCapacityArray;
		var socArray = this.props.socArray;
		var cycleTimesArray = this.props.cycleTimesArray;
		var minCellVoltageValueArray = this.props.minCellVoltageValueArray;
		var minCellVoltageIndexArray = this.props.minCellVoltageIndexArray;
		var temp1Array = this.props.temp1Array;
		var temp2Array = this.props.temp2Array;
		var temp3Array = this.props.temp3Array;
		var temp4Array = this.props.temp4Array;
		var temp5Array = this.props.temp5Array;
		var temp6Array = this.props.temp6Array;
		var tempMaxArray = this.props.tempMaxArray;

		var filename = this.getFilename(batteryName, uploadedUnixTimeSec);
				
		var i;
		var oneRow;
		var csvData = [];
		
		oneRow = [];
		oneRow.push("time");
		oneRow.push("totalVoltage");
		oneRow.push("current");
		oneRow.push("residualCapacity");
		oneRow.push("soc");
		oneRow.push("cycleTimes");
		oneRow.push("minCellVoltageValue");
		oneRow.push("minCellVoltageIndex");
		oneRow.push("temp1");
		oneRow.push("temp2");
		oneRow.push("temp3");
		oneRow.push("temp4");
		oneRow.push("temp5");
		oneRow.push("temp6");
		oneRow.push("tempMax");
		csvData.push(oneRow);
		
		for(i=0; i<dataCount; i++) {
		
			oneRow = [];
			oneRow.push(timeArray[i]);
			oneRow.push(totalVoltageArray[i]);
			oneRow.push(currentArray[i]);
			oneRow.push(residualCapacityArray[i]);
			oneRow.push(socArray[i]);
			oneRow.push(cycleTimesArray[i]);
			oneRow.push(minCellVoltageValueArray[i]);
			oneRow.push(minCellVoltageIndexArray[i]);
			oneRow.push(temp1Array[i]);
			oneRow.push(temp2Array[i]);
			oneRow.push(temp3Array[i]);
			oneRow.push(temp4Array[i]);
			oneRow.push(temp5Array[i]);
			oneRow.push(temp6Array[i]);
			oneRow.push(tempMaxArray[i]);

			csvData.push(oneRow);
		}

		this.setState({
			filename: filename,
			csvData: csvData,
		});
	}
	
	render() {

		return (
			<div>
				<p className="BatteryLogDownloadText">{this.props.getLang(BatteryLang, "downloadCSVFile")}:</p>
				<p>&nbsp;</p>
				<CSVLink
					data={this.state.csvData}
					filename={this.state.filename}
  					className="BatteryLogDownloadLink"
				>
					{this.state.filename}
				</CSVLink>
			</div>
		);
	}
}

export default BatteryLogDownload;


