import React  from 'react';
import './App.css';
import BatteryLang from './BatteryLang.json';
import { LineChart, Line, XAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import BatteryLogChartCustomTooltip from './BatteryLogChartCustomTooltip.js';

class BatteryLogChart extends React.Component {

	constructor(props) {
		
		super(props);
		
		this.state = {
			width: 0,
			height: 0,
		}

		this.updateDimensions = this.updateDimensions.bind(this);
		this.getMaxValue = this.getMaxValue.bind(this);
		this.getData = this.getData.bind(this);
		this.renderLine = this.renderLine.bind(this);
	}
	
	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};
	
	getMaxValue(dataArray, count) {

		var i;
		var value;
		var maxValue = 0;

		for(i=0; i<count; i++) {
			value = dataArray[i];
			if (value > maxValue) {
				maxValue = value;
			}
		}

		return maxValue;
	}

	getData() {
		
		var i;
		var operation;
		var dataCountCurrent;
		var data = [];
		var oneRow;
		var totalVoltageMax;
		var current;
		var currentMax;
		var residualCapacityMax;
		var socMax;
		var minCellVoltageValueMax;
		var temp1Max;
		var temp2Max;
		var temp3Max;
		var temp4Max;
		var temp5Max;
		var temp6Max;
		var tempMaxMax;
		var labelText;

		operation = this.props.operation;
		dataCountCurrent = this.props.dataCountCurrent;

		totalVoltageMax = this.getMaxValue(this.props.totalVoltageArrayCurrent, dataCountCurrent);
		currentMax = this.getMaxValue(this.props.currentArrayCurrent, dataCountCurrent);
		residualCapacityMax = this.getMaxValue(this.props.residualCapacityArrayCurrent, dataCountCurrent);
		socMax = this.getMaxValue(this.props.socArrayCurrent, dataCountCurrent);
		minCellVoltageValueMax = this.getMaxValue(this.props.minCellVoltageValueArrayCurrent, dataCountCurrent);
		temp1Max = this.getMaxValue(this.props.temp1ArrayCurrent, dataCountCurrent);
		temp2Max = this.getMaxValue(this.props.temp2ArrayCurrent, dataCountCurrent);
		temp3Max = this.getMaxValue(this.props.temp3ArrayCurrent, dataCountCurrent);
		temp4Max = this.getMaxValue(this.props.temp4ArrayCurrent, dataCountCurrent);
		temp5Max = this.getMaxValue(this.props.temp5ArrayCurrent, dataCountCurrent);
		temp6Max = this.getMaxValue(this.props.temp6ArrayCurrent, dataCountCurrent);
		tempMaxMax = this.getMaxValue(this.props.tempMaxArrayCurrent, dataCountCurrent);
		
		if (operation === "charge") {
			currentMax = Math.abs(currentMax);
		}

		for(i=0; i<dataCountCurrent; i++) {

			oneRow = {};

			// time
			oneRow.time = this.props.timeArrayCurrent[i];

			if (this.props.batteryTotalVoltageYN) {
				labelText = this.props.getLang(BatteryLang, "totalVoltage");
				oneRow[labelText] = 100 * this.props.totalVoltageArrayCurrent[i] / totalVoltageMax;
			}

			if (this.props.batteryCurrentYN) {
				labelText = this.props.getLang(BatteryLang, "current");
				current = this.props.currentArrayCurrent[i];
				if (operation === "charge") {
					current = Math.abs(current);
				}
				oneRow[labelText] = 100 * current / currentMax;
			}

			if (this.props.batterySocYN) {
				labelText = this.props.getLang(BatteryLang, "socStateOfCharge");
				oneRow[labelText] = 100 * this.props.socArrayCurrent[i] / socMax;
			}

			if (this.props.batteryResidualCapacityYN) {
				labelText = this.props.getLang(BatteryLang, "residualCapacity");
				oneRow[labelText] = 100 * this.props.residualCapacityArrayCurrent[i] / residualCapacityMax;
			}

			if (this.props.batteryMinCellVoltageValueYN) {
				labelText = this.props.getLang(BatteryLang, "minCellVoltage");
				oneRow[labelText] = 100 * this.props.minCellVoltageValueArrayCurrent[i] / minCellVoltageValueMax;
			}

			if (this.props.batteryTemp1YN) {
				labelText = this.props.getLang(BatteryLang, "temp1");
				oneRow[labelText] = 100 * this.props.temp1ArrayCurrent[i] / temp1Max;
			}
		
			if (this.props.batteryTemp2YN) {
				labelText = this.props.getLang(BatteryLang, "temp2");
				oneRow[labelText] = 100 * this.props.temp2ArrayCurrent[i] / temp2Max;
			}
		
			if (this.props.batteryTemp3YN) {
				labelText = this.props.getLang(BatteryLang, "temp3");
				oneRow[labelText] = 100 * this.props.temp3ArrayCurrent[i] / temp3Max;
			}
		
			if (this.props.batteryTemp4YN) {
				labelText = this.props.getLang(BatteryLang, "temp4");
				oneRow[labelText] = 100 * this.props.temp4ArrayCurrent[i] / temp4Max;
			}
		
			if (this.props.batteryTemp5YN) {
				labelText = this.props.getLang(BatteryLang, "temp5");
				oneRow[labelText] = 100 * this.props.temp5ArrayCurrent[i] / temp5Max;
			}
		
			if (this.props.batteryTemp6YN) {
				labelText = this.props.getLang(BatteryLang, "temp6");
				oneRow[labelText] = 100 * this.props.temp6ArrayCurrent[i] / temp6Max;
			}
		
			if (this.props.batteryTempMaxYN) {
				labelText = this.props.getLang(BatteryLang, "tempMax");
				oneRow[labelText] = 100 * this.props.tempMaxArrayCurrent[i] / tempMaxMax;
			}
		
			data.push(oneRow);
		}
		
		return data;
	}

	renderLine(renderYN, variableName, color) {
		if (!renderYN) {
			return null;
		}
		var variableName2 = this.props.getLang(BatteryLang, variableName);
		return (
			<Line type="monotone" dataKey={variableName2} stroke={color}/>
		);
	}

	render() {
		
		var width1 = window.innerWidth - 10;
		var height1 = window.innerHeight - 100;
		
		var data = this.getData();
		
		return (
			<LineChart
				width={width1}
				height={height1}
				data={data}
				margin={{
					top: 5,
					right: 30,
					left: 20,
					bottom: 5,
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="time" />
				<Tooltip content={
						<BatteryLogChartCustomTooltip
							operation={this.props.operation}
							dataInterval={this.props.dataInterval}
							totalVoltageArrayCurrent={this.props.totalVoltageArrayCurrent}
							currentArrayCurrent={this.props.currentArrayCurrent}
							residualCapacityArrayCurrent={this.props.residualCapacityArrayCurrent}
							socArrayCurrent={this.props.socArrayCurrent}
							cycleTimesArrayCurrent={this.props.cycleTimesArrayCurrent}
							minCellVoltageValueArrayCurrent={this.props.minCellVoltageValueArrayCurrent}
							minCellVoltageIndexArrayCurrent={this.props.minCellVoltageIndexArrayCurrent}
							temp1ArrayCurrent={this.props.temp1ArrayCurrent}
							temp2ArrayCurrent={this.props.temp2ArrayCurrent}
							temp3ArrayCurrent={this.props.temp3ArrayCurrent}
							temp4ArrayCurrent={this.props.temp4ArrayCurrent}
							temp5ArrayCurrent={this.props.temp5ArrayCurrent}
							temp6ArrayCurrent={this.props.temp6ArrayCurrent}
							tempMaxArrayCurrent={this.props.tempMaxArrayCurrent}

							totalVoltageArray={this.props.totalVoltageArray}
							currentArray={this.props.currentArray}
							residualCapacityArray={this.props.residualCapacityArray}
							socArray={this.props.socArray}
							cycleTimesArray={this.props.cycleTimesArray}
							minCellVoltageValueArray={this.props.minCellVoltageValueArray}
							minCellVoltageIndexArray={this.props.minCellVoltageIndexArray}
							temp1Array={this.props.temp1Array}
							temp2Array={this.props.temp2Array}
							temp3Array={this.props.temp3Array}
							temp4Array={this.props.temp4Array}
							temp5Array={this.props.temp5Array}
							temp6Array={this.props.temp6Array}
							tempMaxArray={this.props.tempMaxArray}
							
							getLang={this.props.getLang}
						/>} 
						cursor={false} 
				/>
				<Legend />

				{this.renderLine(this.props.batteryTotalVoltageYN, "totalVoltage", "red")}
				{this.renderLine(this.props.batteryCurrentYN, "current", "green")}
				{this.renderLine(this.props.batterySocYN, "socStateOfCharge", "blue")}
				{this.renderLine(this.props.batteryResidualCapacityYN, "residualCapacity", "orange")}
				{this.renderLine(this.props.batteryMinCellVoltageValueYN, "minCellVoltage", "grey")}
				{this.renderLine(this.props.batteryTemp1YN, "temp1", "#fff000")}
				{this.renderLine(this.props.batteryTemp2YN, "temp2", "#30ff30")}
				{this.renderLine(this.props.batteryTemp3YN, "temp3", "#3090ff")}
				{this.renderLine(this.props.batteryTemp4YN, "temp4", "#82ca9d")}
				{this.renderLine(this.props.batteryTemp5YN, "temp5", "#ca9d82")}
				{this.renderLine(this.props.batteryTemp6YN, "temp6", "#829d82")}
				{this.renderLine(this.props.batteryTempMaxYN, "tempMax", "#ca829d")}
			</LineChart>
		);
	}
}

export default BatteryLogChart;

