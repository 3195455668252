import React  from 'react';
import './App.css';

class BatteryTester extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			intervalID: null,
			deviceID: "",
			batteryName: "",
			operation: "",
			operationSeconds: "",
			voltage: "",
			current: "",
			temperature1: "",
			temperature2: "",
			cellVoltageMin: "",
			cellVoltageMax: "",
			cycleTimes: "",
			percentage: "",
			capacityRemain: "",
			power: "",
			energy: "",
		};

		this.getDataFromServer = this.getDataFromServer.bind(this);
		this.getTimeStringFromSeconds = this.getTimeStringFromSeconds.bind(this);
	}
	
	componentDidMount() {
		setTimeout(this.getDataFromServer, 200);
		var intervalID = setInterval(this.getDataFromServer, 2000);
		this.setState({
			intervalID: intervalID,
		});
	}

	componentWillUnmount() {
		clearInterval(this.state.intervalID);
		this.setState({
			intervalID: null,
		});
	}

	async getDataFromServer() {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbatterytesterstate";

		const res = await fetch(url);
		const jsonObj = await res.json();
		//console.log(jsonObj);

		var deviceID = jsonObj.deviceID;
		var batteryName = jsonObj.batteryName;
		var operation = jsonObj.operation;
		var operationSeconds = jsonObj.operationSeconds;
		var voltage = jsonObj.voltage;
		var current = jsonObj.current;
		var temperature1 = jsonObj.temperature1;
		var temperature2 = jsonObj.temperature2;
		var cellVoltageMin = jsonObj.cellVoltageMin;
		var cellVoltageMax = jsonObj.cellVoltageMax;
		var cycleTimes = jsonObj.cycleTimes;
		var percentage= jsonObj.percentage;
		var capacityRemain = jsonObj.capacityRemain;
		var power = jsonObj.power;
		var energy = jsonObj.energy;

		this.setState({
			deviceID: deviceID,
			batteryName: batteryName,
			operation: operation,
			operationSeconds: operationSeconds,
			voltage: voltage,
			current: current,
			temperature1: temperature1,
			temperature2: temperature2,
			cellVoltageMin: cellVoltageMin,
			cellVoltageMax: cellVoltageMax,
			cycleTimes: cycleTimes,
			percentage: percentage,
			capacityRemain: capacityRemain,
			power: power,
			energy: energy,
		});
	}

	getTimeStringFromSeconds(seconds) {

		var timeString;

		var date1 = new Date(null);
		date1.setSeconds(seconds);
		timeString = date1.toISOString().substr(11, 8);

		return timeString;
	}
	
	render() {

		var deviceID = this.state.deviceID;
		var batteryName = this.state.batteryName;
		var operation = this.state.operation;
		var operationSeconds = this.state.operationSeconds;
		var voltage = this.state.voltage;
		var current = this.state.current;
		var temperature1 = this.state.temperature1;
		var temperature2 = this.state.temperature2;
		var cellVoltageMin = this.state.cellVoltageMin;
		var cellVoltageMax = this.state.cellVoltageMax;
		var cycleTimes = this.state.cycleTimes;
		var percentage = this.state.percentage;
		var capacityRemain = this.state.capacityRemain;
		var power = this.state.power;
		var energy = this.state.energy;

		var operationSecondsString = "";
		if (operationSeconds !== "") {
			operationSecondsString = this.getTimeStringFromSeconds(operationSeconds);
		}

		var voltageString = "";
		if (voltage !== "") {
			voltageString = voltage + " V";
		}

		var currentString = "";
		if (current !== "") {
			currentString = current + " A";
		}

		var temperatureString = "";
		if (temperature1 !== "") {
			temperatureString = temperature1 + " / " + temperature2 + " \u00b0C";
		}

		var cellVoltageString = "";
		if (cellVoltageMin !== "") {
			cellVoltageString = cellVoltageMin + " / " + cellVoltageMax + " V";
		}

		var cycleTimesString = "";
		if (cycleTimes !== "") {
			cycleTimesString = cycleTimes + " times";
		}

		var percentageString = "";
		if (percentage !== "") {
			percentageString = percentage + " % / " + capacityRemain + " Ah";
		}

		var powerString = "";
		if (power !== "") {
			powerString = power + " W / " + energy + " Wh";
		}

		return (
			<div>
				<p className="BatteryTesterHeader">Battery Tester State</p>
				<p className="BatteryTesterHeader">&nbsp;</p>
				<p className="BatteryTesterText">{deviceID}</p>
				<p className="BatteryTesterText">{batteryName}</p>
				<p className="BatteryTesterText">{operation}</p>
				<p className="BatteryTesterText">{operationSecondsString}</p>
				<p className="BatteryTesterText">{voltageString}</p>
				<p className="BatteryTesterText">{currentString}</p>
				<p className="BatteryTesterText">{powerString}</p>
				<p className="BatteryTesterText">{temperatureString}</p>
				<p className="BatteryTesterText">{cellVoltageString}</p>
				<p className="BatteryTesterText">{percentageString}</p>
				<p className="BatteryTesterText">{cycleTimesString}</p>
			</div>
		);
	}
}

export default BatteryTester;


