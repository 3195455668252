import React  from 'react';
import './App.css';
import './Ride.css';

import RideToolbar from './RideToolbar.js';
import RideStats from './RideStats.js';
import RideChart from './RideChart.js';
import RideMap from './RideMap.js';
import RideTable from './RideTable.js';
import RideDownload from './RideDownload.js';
import RideSettings from './RideSettings.js';

class Ride extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			selectedTab: "stats",
			boardID: 0,
			uploadedUnixTimeSec: "",
			softwareVersionESP: "",
			softwareVersionMicro: "",
			unixTimeSec: 0,
			durationSec: 0,
			batteryName: "",
			batteryNominalCapacity: 0,
			batteryCycleTimes: 0,
			boardNumber: 0,
			logID: 0,
			logFormat: 0,
			dataCount: 0,
			timeArray: [],
			sensorThrottleArray: [],
			throttleArray: [],
			escTempArray: [],
			escCurrentArray: [],
			batteryPercentageArray: [],
			batteryVoltageArray: [],
			batteryCurrentArray: [],
			minCellVoltageArray: [],
			bmsTempArray: [],
			batteryConnectedArray: [],
			residualCapacityArray: [],
			minCellVoltageIndexArray: [],
			gpsLngArray: [],
			gpsLatArray: [],
			gpsSpeedArray: [],
			whPerKmArray: [],
			temp1Array: [],
			temp2Array: [],
			temp3Array: [],
			temp4Array: [],
			temp5Array: [],
			temp6Array: [],
			dataCount10: 0,
			timeArray10: [],
			sensorThrottleArray10: [],
			throttleArray10: [],
			escTempArray10: [],
			escCurrentArray10: [],
			batteryPercentageArray10: [],
			batteryVoltageArray10: [],
			batteryCurrentArray10: [],
			minCellVoltageArray10: [],
			gpsLngArray10: [],
			gpsLatArray10: [],
			gpsSpeedArray10: [],
			whPerKmArray10: [],
			bmsTempArray10: [],
			dataCount30: 0,
			timeArray30: [],
			sensorThrottleArray30: [],
			throttleArray30: [],
			escTempArray30: [],
			escCurrentArray30: [],
			batteryPercentageArray30: [],
			batteryVoltageArray30: [],
			batteryCurrentArray30: [],
			minCellVoltageArray30: [],
			gpsLngArray30: [],
			gpsLatArray30: [],
			gpsSpeedArray30: [],
			whPerKmArray30: [],			
			bmsTempArray30: [],
			dataCount60: 0,
			timeArray60: [],
			sensorThrottleArray60: [],
			throttleArray60: [],
			escTempArray60: [],
			escCurrentArray60: [],
			batteryPercentageArray60: [],
			batteryVoltageArray60: [],
			batteryCurrentArray60: [],
			minCellVoltageArray60: [],
			bmsTempArray60: [],
			gpsLngArray60: [],
			gpsLatArray60: [],
			gpsSpeedArray60: [],
			whPerKmArray60: [],
		};

		this.getRideData = this.getRideData.bind(this);
		this.downloadRideFile1 = this.downloadRideFile1.bind(this);
		this.downloadRideFile2 = this.downloadRideFile2.bind(this);
		this.getLineFromText = this.getLineFromText.bind(this);
		this.parseOneDataLine = this.parseOneDataLine.bind(this);
		this.parseRideText1 = this.parseRideText1.bind(this);
		this.parseRideText2 = this.parseRideText2.bind(this);
		this.calcDataForInterval = this.calcDataForInterval.bind(this);
		this.setSelectedTab = this.setSelectedTab.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}
	
	componentDidMount() {
		var rideID = this.props.rideID;
		if (rideID > 0) {
			this.getRideData(rideID);
		}
	}
	
	async getBoardData(boardID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getboarddata?boardid=" + boardID;

		const res = await fetch(url);
		const jsonObj = await res.json();
		var boardNumber = jsonObj.boardNumber;

		this.setState({
			boardNumber: boardNumber,
		});
	}

	async getRideData(rideID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getridedata?rideid=" + rideID;

		const res = await fetch(url);
		const jsonObj = await res.json();
		var boardID = jsonObj.boardID;
		var uploadedUnixTimeSec = jsonObj.uploadedUnixTimeSec;
		var durationSec = jsonObj.durationSec;
		var batteryName = jsonObj.batteryName;
		var batteryNominalCapacity = jsonObj.batteryNominalCapacity;
		var batteryCycleTimes = jsonObj.batteryCycleTimes;
		var logID = jsonObj.logID;

		var logFormat = 0;
		if ((logID === null) || (logID === 0)) {
			logFormat = 1;
		} else {
			logFormat = 2;
		}

		this.setState({
			boardID: boardID,
			uploadedUnixTimeSec: uploadedUnixTimeSec,
			durationSec: durationSec,
			batteryName: batteryName,
			batteryNominalCapacity: batteryNominalCapacity,
			batteryCycleTimes: batteryCycleTimes,
			logID: logID,
			logFormat: logFormat,
		});
		
		this.getBoardData(boardID);
		
		console.log("logFormat = " + logFormat);

		if (logFormat === 1) {
			this.downloadRideFile1(rideID);
		}

		if (logFormat === 2) {
			this.downloadRideFile2(rideID);
		}
	}
	
	getLineFromText(fileText, startIndex) {

		var i;
		var ch;
		var oneLine = "";

		var length = fileText.length;

		for(i=startIndex; i<length; i++) {
			ch = fileText.charAt(i);
			if (ch === "\n") {
				return oneLine;
			}
			oneLine = oneLine + ch;
		}
		
		return oneLine;
	}

	parseOneDataLine(lineString, valueStringArray) {

		var i;
		var ch;
		var valueString = "";
		var valueCount = 0;

		var length = lineString.length;

		for(i=0; i<length; i++) {
			ch = lineString.charAt(i);
			if (ch === " ") {
				valueStringArray.push(valueString);
				valueCount = valueCount + 1;
				valueString = "";
			} else {
				valueString = valueString + ch;
			}
		}
		
		if (valueString !== "") {
			valueStringArray.push(valueString);
			valueCount = valueCount + 1;
		}
		
		return valueCount;
	}

	parseRideText1(fileText) {

		var oneLine;
		var startIndex = 0;
		var length = fileText.length;

		oneLine = this.getLineFromText(fileText, startIndex);
		//var headerString = oneLine; // not looking into header for now
		startIndex = startIndex + oneLine.length + 1;

		var timeArray = [];
		var sensorThrottleArray = [];
		var throttleArray = [];
		var escTempArray = [];
		var escCurrentArray = [];
		var batteryPercentageArray = [];
		var batteryVoltageArray = [];
		var batteryCurrentArray = [];
		var minCellVoltageArray = [];
		var bmsTempArray = [];
		var batteryConnectedArray = [];
		var residualCapacityArray = [];
		var minCellVoltageIndexArray = [];
		var temp1Array = [];
		var temp2Array = [];
		var temp3Array = [];
		var temp4Array = [];
		var temp5Array = [];
		var temp6Array = [];
		var gpsLngArray = [];
		var gpsLatArray = [];
		var gpsSpeedArray = [];
		var whPerKmArray = [];

		var dataCount = 0;

		for(; startIndex<length; ) {
			
			oneLine = this.getLineFromText(fileText, startIndex);
			startIndex = startIndex + oneLine.length + 1;
			
			var valueStringArray = [];
			var valueCount = this.parseOneDataLine(oneLine, valueStringArray);

			if (valueCount === 9) {

				var sensorThrottle = parseInt(valueStringArray[0]);
				var throttle = parseInt(valueStringArray[1]);
				var escTemp = parseFloat(valueStringArray[2], 1);
				var escCurrent = parseInt(valueStringArray[3]);;
				var batteryVoltage = parseFloat(valueStringArray[4], 1);
				var batteryPercentage = parseInt(valueStringArray[5]);
				var batteryCurrent = parseInt(valueStringArray[6]);
				var minCellVoltage = parseFloat(valueStringArray[7], 3);
				var bmsTemp = parseFloat(valueStringArray[8], 1);

				timeArray.push(dataCount);
				sensorThrottleArray.push(sensorThrottle);
				throttleArray.push(throttle);
				escTempArray.push(escTemp);
				escCurrentArray.push(escCurrent);
				batteryVoltageArray.push(batteryVoltage);
				batteryPercentageArray.push(batteryPercentage);
				batteryCurrentArray.push(batteryCurrent);
				minCellVoltageArray.push(minCellVoltage);
				bmsTempArray.push(bmsTemp);
				batteryConnectedArray.push(-1);
				residualCapacityArray.push(-1);
				minCellVoltageIndexArray.push(-1);
				temp1Array.push(-1);
				temp2Array.push(-1);
				temp3Array.push(-1);
				temp4Array.push(-1);
				temp5Array.push(-1);
				temp6Array.push(-1);
				gpsLngArray.push(0);
				gpsLatArray.push(0);
				gpsSpeedArray.push(0);
				whPerKmArray.push(0);

				dataCount = dataCount + 1;
			}
		}

		this.setState({
			dataCount: dataCount,
			timeArray: timeArray,
			sensorThrottleArray: sensorThrottleArray,
			throttleArray: throttleArray,
			escTempArray: escTempArray,
			escCurrentArray: escCurrentArray,
			batteryPercentageArray: batteryPercentageArray,
			batteryVoltageArray: batteryVoltageArray,
			batteryCurrentArray: batteryCurrentArray,
			minCellVoltageArray: minCellVoltageArray,
			bmsTempArray: bmsTempArray,
			batteryConnectedArray: batteryConnectedArray,
			residualCapacityArray: residualCapacityArray,
			minCellVoltageIndexArray: minCellVoltageIndexArray,
			temp1Array: temp1Array,
			temp2Array: temp2Array,
			temp3Array: temp3Array,
			temp4Array: temp4Array,
			temp5Array: temp5Array,
			temp6Array: temp6Array,
			gpsLngArray: gpsLngArray,
			gpsLatArray: gpsLatArray,
			gpsSpeedArray: gpsSpeedArray,
			whPerKmArray: whPerKmArray,
		});
		
		this.loadData(dataCount, 1)
	}

	getClosestValidGpsPoint(gpsLngArray, gpsLatArray, index, count) {
		
		var i;
		var gpsPointValidYN = false;
		var gpsLng;
		var gpsLat;

		for(i=index+1; i<count; i++) {
			gpsLng = gpsLngArray[i];
			gpsLat = gpsLatArray[i];
			gpsPointValidYN = false;
			if ((Math.abs(gpsLng) > 0.001) && (Math.abs(gpsLat) > 0.001)) {
				gpsPointValidYN = true;
			}
			if (gpsPointValidYN) {
				return [gpsLng, gpsLat];
			}
		}

		for(i=index-1; i>=0; i--) {
			gpsLng = gpsLngArray[i];
			gpsLat = gpsLatArray[i];
			gpsPointValidYN = false;
			if ((Math.abs(gpsLng) > 0.001) && (Math.abs(gpsLat) > 0.001)) {
				gpsPointValidYN = true;
			}
			if (gpsPointValidYN) {
				return [gpsLng, gpsLat];
			}
		}

		gpsLng = 0;
		gpsLat = 0;
		return [gpsLng, gpsLat];
	}

	parseRideText2(fileText) {

		var oneLine;
		var startIndex = 0;
		var fileTextLength = fileText.length;

		// PARSE HEADER

		var unixTimeSec = 0;
		var boardNumber = "";
		var batteryName = "";
		var softwareVersionESP = "";
		var softwareVersionMicro = "";
		var cellCount = "";
		var nominalCapacity = "";
		var cycleTimes = "";
		var tempCount = "";

		// unix time
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		unixTimeSec = parseInt(oneLine);

		// board number
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		boardNumber = oneLine;

		// battery name
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		batteryName = oneLine;

		// software version esp
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		softwareVersionESP = oneLine;

		// software version micro
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		softwareVersionMicro = oneLine;

		// cell count
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		cellCount = oneLine;

		// nominal capacity
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		nominalCapacity = oneLine;

		// cycle times
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		cycleTimes = oneLine;

		// temp count
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		tempCount = oneLine;

		/*console.log("unixTimeSec = " + unixTimeSec);
		console.log("boardNumber = " + boardNumber);
		console.log("batteryName = " + batteryName);
		console.log("softwareVersionESP = " + softwareVersionESP);
		console.log("softwareVersionMicro = " + softwareVersionMicro);
		console.log("cellCount = " + cellCount);
		console.log("nominalCapacity = " + nominalCapacity);
		console.log("cycleTimes = " + cycleTimes);
		console.log("tempCount = " + tempCount);
		console.log("");*/
		
		this.setState({
			batteryName: batteryName,
			batteryNominalCapacity: nominalCapacity,
			batteryCycleTimes: cycleTimes,
			softwareVersionESP: softwareVersionESP,
			softwareVersionMicro: softwareVersionMicro,
			unixTimeSec: unixTimeSec,
		});

		// end of header
		oneLine = this.getLineFromText(fileText, startIndex);
		startIndex = startIndex + oneLine.length + 1;
		console.log("end of header = " + oneLine);
		
		if (oneLine !== "BOARDLOGHEADEREND") {
			return;
		}

		// PARSE DATA

		oneLine = this.getLineFromText(fileText, startIndex);
		var headerString = oneLine;
		startIndex = startIndex + oneLine.length + 1;

		var timeArray = [];
		var sensorThrottleArray = [];
		var throttleArray = [];
		var escTempArray = [];
		var escCurrentArray = [];
		var batteryPercentageArray = [];
		var batteryVoltageArray = [];
		var batteryCurrentArray = [];
		var minCellVoltageArray = [];
		var bmsTempArray = [];
		var batteryConnectedArray = [];
		var residualCapacityArray = [];
		var minCellVoltageIndexArray = [];
		var gpsLngArray = [];
		var gpsLatArray = [];
		var gpsSpeedArray = [];
		var whPerKmArray = [];
		var temp1Array = [];
		var temp2Array = [];
		var temp3Array = [];
		var temp4Array = [];
		var temp5Array = [];
		var temp6Array = [];
		var dataCount = 0;

		// seconds sensor_throttle throttle esc_current esc_temp battery_connected total_voltage battery_current residual_capacity soc min_cell_voltage_value min_cell_voltage_index gps_lng gps_lat gps_speed wh_per_km temp1 temp2 temp3 temp4 temp5 temp6

		var whPerKmYN = headerString.includes("wh_per_km");
		console.log("whPerKmYN = " + whPerKmYN);

		for(; startIndex<fileTextLength; ) {
			
			oneLine = this.getLineFromText(fileText, startIndex);
			startIndex = startIndex + oneLine.length + 1;
			
			var valueStringArray = [];
			var valueCount = this.parseOneDataLine(oneLine, valueStringArray);
			console.log("valueCount = " + valueCount);
			
			var len = 0;

			if (valueCount >= 17) {

				var seconds = parseInt(valueStringArray[len]);							len++;
				var sensorThrottle = parseInt(valueStringArray[len]);					len++;
				var throttle = parseInt(valueStringArray[len]);							len++;
				var escCurrent = parseInt(valueStringArray[len]);						len++;
				var escTemp = parseFloat(valueStringArray[len], 1);						len++;
				var batteryConnected = parseInt(valueStringArray[len]);					len++;
				var batteryVoltage = parseFloat(valueStringArray[len], 1);				len++;
				var batteryCurrent = parseInt(valueStringArray[len]);					len++;
				var residualCapacity = parseInt(valueStringArray[len]);					len++;
				var batteryPercentage = parseInt(valueStringArray[len]);				len++;
				var minCellVoltage = parseFloat(valueStringArray[len]/1000.0, 3);		len++;
				var minCellVoltageIndex = valueStringArray[len];						len++;
				var gpsLng = parseFloat(valueStringArray[len]);							len++;
				var gpsLat = parseFloat(valueStringArray[len]);							len++;
				var gpsSpeed  = parseFloat(valueStringArray[len]);						len++;
				var whPerKm = 0;
				if (whPerKmYN) {
					whPerKm = parseFloat(valueStringArray[len]);						len++;
				}
				var temp1 = parseFloat(valueStringArray[len], 2);						len++;
				var temp2 = parseFloat(valueStringArray[len], 2);						len++;
				
				var temp3 = 0;
				if (tempCount >= 3) {
					temp3 = parseFloat(valueStringArray[len], 2);						len++;
				}
				var temp4 = 0;
				if (tempCount >= 4) {
					temp4 = parseFloat(valueStringArray[len], 2);						len++;
				}
				var temp5 = 0;
				if (tempCount >= 5) {
					temp5 = parseFloat(valueStringArray[len], 2);						len++;
				}

				var temp6 = 0;
				if (tempCount >= 5) {
					temp6 = parseFloat(valueStringArray[len], 2);						len++;
				}

				var bmsTemp = temp1;
				if (temp2 > bmsTemp) {
					bmsTemp = temp2;
				}
				if (temp3 > bmsTemp) {
					bmsTemp = temp3;
				}
				if (temp4 > bmsTemp) {
					bmsTemp = temp4;
				}
				if (temp5 > bmsTemp) {
					bmsTemp = temp5;
				}

				if (temp6 > bmsTemp) {
					bmsTemp = temp6;
				}
				
				timeArray.push(seconds);
				sensorThrottleArray.push(sensorThrottle);
				throttleArray.push(throttle);
				escTempArray.push(escTemp);
				escCurrentArray.push(escCurrent);
				batteryVoltageArray.push(batteryVoltage);
				batteryPercentageArray.push(batteryPercentage);
				batteryCurrentArray.push(batteryCurrent);
				minCellVoltageArray.push(minCellVoltage);
				bmsTempArray.push(bmsTemp);
				batteryConnectedArray.push(batteryConnected);
				residualCapacityArray.push(residualCapacity);
				minCellVoltageIndexArray.push(minCellVoltageIndex);
				gpsLngArray.push(gpsLng);
				gpsLatArray.push(gpsLat);
				gpsSpeedArray.push(gpsSpeed);
				whPerKmArray.push(whPerKm);
				temp1Array.push(temp1);
				temp2Array.push(temp2);
				temp3Array.push(temp3);
				temp4Array.push(temp4);
				temp5Array.push(temp5);
				temp6Array.push(temp6);

				dataCount = dataCount + 1;
			}
		}
		
		// check if gps point is invalid
		{
			var i;
			for(i=0; i<gpsLngArray.length; i++) {
				gpsLng = gpsLngArray[i];
				gpsLat = gpsLatArray[i];
				if ((Math.abs(gpsLng) < 0.001) || (Math.abs(gpsLat) < 0.001)) {
					[gpsLng, gpsLat] = this.getClosestValidGpsPoint(gpsLngArray, gpsLatArray, i, gpsLngArray.length);
					gpsLngArray[i] = gpsLng;
					gpsLatArray[i] = gpsLat;
				}
			}
		}

		// average gps speed
		{
			var i;
			var gpsSpeed2;
			var gpsSpeedArray2 = [];
			var gpsSpeedPrev;
			var gpsSpeedNext;
			
			if (gpsLngArray.length < 3) {

				gpsSpeedArray2 = gpsSpeedArray;

			} else {

				gpsSpeed2 = gpsSpeedArray[0];
				gpsSpeedArray2.push(gpsSpeed2);

				for(i=1; i<gpsLngArray.length-1; i++) {
					gpsSpeedPrev = gpsSpeedArray[i-1];
					gpsSpeed = gpsSpeedArray[i];
					gpsSpeedNext = gpsSpeedArray[i+1];
					gpsSpeed2 = (gpsSpeedPrev + gpsSpeedNext) / 2;
					gpsSpeedArray2.push(gpsSpeed2);
				}

				gpsSpeed2 = gpsSpeedArray[gpsLngArray.length-1];
				gpsSpeedArray2.push(gpsSpeed2);
			}
		}
		
		this.setState({
			dataCount: dataCount,
			timeArray: timeArray,
			sensorThrottleArray: sensorThrottleArray,
			throttleArray: throttleArray,
			escTempArray: escTempArray,
			escCurrentArray: escCurrentArray,
			batteryPercentageArray: batteryPercentageArray,
			batteryVoltageArray: batteryVoltageArray,
			batteryCurrentArray: batteryCurrentArray,
			minCellVoltageArray: minCellVoltageArray,
			bmsTempArray: bmsTempArray,
			batteryConnectedArray: batteryConnectedArray,
			residualCapacityArray: residualCapacityArray,
			minCellVoltageIndexArray: minCellVoltageIndexArray,
			gpsLngArray: gpsLngArray,
			gpsLatArray: gpsLatArray,
			gpsSpeedArray: gpsSpeedArray2,
			whPerKmArray: whPerKmArray,
			temp1Array: temp1Array,
			temp2Array: temp2Array,
			temp3Array: temp3Array,
			temp4Array: temp4Array,
			temp5Array: temp5Array,
			temp6Array: temp6Array,
		});
		
		this.loadData(dataCount, 2)
 	}

	async downloadRideFile1(rideID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/rides/" + rideID + ".txt";

		const res = await fetch(url);
		const fileText = await res.text();
		this.parseRideText1(fileText);
	}
	
	async downloadRideFile2(rideID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/rides/" + rideID + ".txt";

		var res = await fetch(url);
		var fileText = await res.text();
		this.parseRideText2(fileText);
	}
	
	calcDataForInterval(intervalValueInput, logFormat) {

		var dataCount = this.state.dataCount;
		var dataCountInterval;
		var dataIndex;
		
		var timeArrayInterval = [];

		var sensorThrottle = 0;
		var sensorThrottleSum = 0;
		var sensorThrottleAverage = 0;
		var sensorThrottleArrayInterval = [];

		var throttle = 0;
		var throttleSum = 0;
		var throttleAverage = 0;
		var throttleArrayInterval = [];

		var escTemp = 0;
		var escTempSum = 0;
		var escTempAverage = 0;
		var escTempArrayInterval = [];

		var escCurrent = 0;
		var escCurrentSum = 0;
		var escCurrentAverage = 0;
		var escCurrentArrayInterval = [];

		var batteryPercentage = 0;
		var batteryPercentageSum = 0;
		var batteryPercentageAverage = 0;
		var batteryPercentageArrayInterval = [];

		var batteryVoltage = 0;
		var batteryVoltageSum = 0;
		var batteryVoltageAverage = 0;
		var batteryVoltageArrayInterval = [];

		var batteryCurrent = 0;
		var batteryCurrentSum = 0;
		var batteryCurrentAverage = 0;
		var batteryCurrentArrayInterval = [];

		var minCellVoltage = 0;
		var minCellVoltageSum = 0;
		var minCellVoltageAverage = 0;
		var minCellVoltageArrayInterval = [];

		var bmsTemp = 0;
		var bmsTempSum = 0;
		var bmsTempAverage = 0;
		var bmsTempArrayInterval = [];

		var gpsLng = 0;
		var gpsLngFirst = 0;
		var gpsLngArrayInterval = [];

		var gpsLat = 0;
		var gpsLatFirst = 0;
		var gpsLatArrayInterval = [];
		
		var gpsSpeed = 0;
		var gpsSpeedSum = 0;
		var gpsSpeedAverage = 0;
		var gpsSpeedArrayInterval = [];
		
		var whPerKm = 0;
		var whPerKmSum = 0;
		var whPerKmAverage = 0;
		var whPerKmArrayInterval = [];
		
		var intervalValue = 0;

		if (logFormat === 1) {
			intervalValue = intervalValueInput;
		}

		if (logFormat === 2) {
			intervalValue = intervalValueInput / 10;
		}

		var dataValidYN = false;

		dataCountInterval = Math.floor(dataCount / intervalValue);
		
		for(var i=0; i<dataCountInterval; i++) {

			var batchCount = 0;

			var time = intervalValueInput*i;
			timeArrayInterval.push(time);
			
			sensorThrottleSum = 0;
			throttleSum = 0;
			escTempSum = 0;
			escCurrentSum = 0;
			batteryPercentageSum = 0;
			batteryVoltageSum = 0;
			batteryCurrentSum = 0;
			minCellVoltageSum = 0;
			bmsTempSum = 0;
			gpsLngFirst = 0;
			gpsLatFirst = 0;
			gpsSpeedSum = 0;
			whPerKmSum = 0;

			if (intervalValue === 1) {
				//timeArrayInterval.push(this.state.timeArray[i]);
				sensorThrottleArrayInterval.push(this.state.sensorThrottleArray[i]); 
				throttleArrayInterval.push(this.state.throttleArray[i]);
				escTempArrayInterval.push(this.state.escTempArray[i]);
				escCurrentArrayInterval.push(this.state.escCurrentArray[i]);
				batteryPercentageArrayInterval.push(this.state.batteryPercentageArray[i]);
				batteryVoltageArrayInterval.push(this.state.batteryVoltageArray[i]);
				batteryCurrentArrayInterval.push(this.state.batteryCurrentArray[i]);
				minCellVoltageArrayInterval.push(this.state.minCellVoltageArray[i]);
				bmsTempArrayInterval.push(this.state.bmsTempArray[i]);
				gpsLngArrayInterval.push(this.state.gpsLngArray[i]);
				gpsLatArrayInterval.push(this.state.gpsLatArray[i]);
				gpsSpeedArrayInterval.push(this.state.gpsSpeedArray[i]);
				whPerKmArrayInterval.push(this.state.whPerKmArray[i]);
			}

			if (intervalValue > 1) {

				for(var j=0; j<intervalValue; j++) {

					dataIndex = intervalValue*i + j;
					
					if (dataIndex < dataCount) {

						throttle = this.state.throttleArray[dataIndex];
						minCellVoltage = this.state.minCellVoltageArray[dataIndex];

						dataValidYN = true;
						if (throttle < 900) {
							//dataValidYN = false;
						}
						if (minCellVoltage < 1.0) {
							//dataValidYN = false;
						}

						if (!dataValidYN) {
							/*console.log("DATA POINT IS INVALID");
							console.log("time = " + dataIndex);
							console.log("throttle = " + throttle);
							console.log("minCellVoltage = " + minCellVoltage);
							console.log("");*/
						}

						if (dataValidYN) {

							sensorThrottle = this.state.sensorThrottleArray[dataIndex];
							sensorThrottleSum = sensorThrottleSum + sensorThrottle;

							throttle = this.state.throttleArray[dataIndex];
							throttleSum = throttleSum + throttle;

							escTemp = this.state.escTempArray[dataIndex];
							escTempSum = escTempSum + escTemp;

							escCurrent = this.state.escCurrentArray[dataIndex];
							escCurrentSum = escCurrentSum + escCurrent;

							batteryPercentage = this.state.batteryPercentageArray[dataIndex];
							batteryPercentageSum = batteryPercentageSum + batteryPercentage;

							batteryVoltage = this.state.batteryVoltageArray[dataIndex];
							batteryVoltageSum = batteryVoltageSum + batteryVoltage;

							batteryCurrent = this.state.batteryCurrentArray[dataIndex];
							batteryCurrentSum = batteryCurrentSum + batteryCurrent;

							minCellVoltage = this.state.minCellVoltageArray[dataIndex];
							minCellVoltageSum = minCellVoltageSum + minCellVoltage;

							bmsTemp = this.state.bmsTempArray[dataIndex];
							bmsTempSum = bmsTempSum + bmsTemp;
						
							gpsLng = this.state.gpsLngArray[dataIndex];
							if (gpsLngFirst === 0) {
								gpsLngFirst = gpsLng;
							}

							gpsLat = this.state.gpsLatArray[dataIndex];
							if (gpsLatFirst === 0) {
								gpsLatFirst = gpsLat;
							}
						
							gpsSpeed = this.state.gpsSpeedArray[dataIndex];
							gpsSpeedSum = gpsSpeedSum + gpsSpeed;
							
							whPerKm = this.state.whPerKmArray[dataIndex];
							whPerKmSum = whPerKmSum + gpsSpeed;
						
							batchCount = batchCount + 1;
						}
					}
				}

				if (batchCount === 0) {
					sensorThrottleAverage = 0;
				} else {
					sensorThrottleAverage = sensorThrottleSum / batchCount;
				}
				sensorThrottleAverage = Math.round(sensorThrottleAverage);
				sensorThrottleArrayInterval.push(sensorThrottleAverage);
				
				if (batchCount === 0) {
					throttleAverage = 0;
				} else {
					throttleAverage = throttleSum / batchCount;
				}			
				throttleAverage = Math.round(throttleAverage);
				throttleArrayInterval.push(throttleAverage);
				
				if (batchCount === 0) {
					escTempAverage = 0;
				} else {
					escTempAverage = escTempSum / batchCount;
				}
				escTempAverage = Math.round(escTempAverage * 10) / 10;
				escTempArrayInterval.push(escTempAverage);
				
				if (batchCount === 0) {
					escCurrentAverage = 0;
				} else {
					escCurrentAverage = escCurrentSum / batchCount;
				}
				escCurrentAverage = Math.round(escCurrentAverage * 10) / 10;
				escCurrentArrayInterval.push(escCurrentAverage);
				
				if (batchCount === 0) {
					batteryPercentageAverage = 0;
				} else {
					batteryPercentageAverage = batteryPercentageSum / batchCount;
				}
				batteryPercentageAverage = Math.round(batteryPercentageAverage);
				batteryPercentageArrayInterval.push(batteryPercentageAverage);
				
				if (batchCount === 0) {
					batteryVoltageAverage = 0;
				} else {
					batteryVoltageAverage = batteryVoltageSum / batchCount;
				}
				batteryVoltageAverage = Math.round(batteryVoltageAverage * 10) / 10;
				batteryVoltageArrayInterval.push(batteryVoltageAverage);
				
				if (batchCount === 0) {
					batteryCurrentAverage = 0;
				} else {
					batteryCurrentAverage = batteryCurrentSum / batchCount;
				}
				batteryCurrentAverage = Math.round(batteryCurrentAverage * 10) / 10;
				batteryCurrentArrayInterval.push(batteryCurrentAverage);
				
				if (batchCount === 0) {
					minCellVoltageAverage = 0;
				} else {
					minCellVoltageAverage = minCellVoltageSum / batchCount;
				}
				minCellVoltageAverage = Math.round(minCellVoltageAverage * 1000) / 1000;
				minCellVoltageArrayInterval.push(minCellVoltageAverage);
				
				if (batchCount === 0) {
					bmsTempAverage = 0;
				} else {
					bmsTempAverage = bmsTempSum / batchCount;
				}
				bmsTempAverage = Math.round(bmsTempAverage * 10) / 10;
				bmsTempArrayInterval.push(bmsTempAverage);

				gpsLngArrayInterval.push(gpsLngFirst);
				gpsLatArrayInterval.push(gpsLatFirst);

				if (batchCount === 0) {
					gpsSpeedAverage = 0;
				} else {
					gpsSpeedAverage = gpsSpeedSum / batchCount;
				}
				gpsSpeedAverage = Math.round(gpsSpeedAverage * 10) / 10;
				gpsSpeedArrayInterval.push(gpsSpeedAverage);

				if (batchCount === 0) {
					whPerKmAverage = 0;
				} else {
					whPerKmAverage = whPerKmSum / batchCount;
				}
				whPerKmAverage = Math.round(whPerKmAverage * 10) / 10;
				whPerKmArrayInterval.push(whPerKmAverage);				
			}
		}

		if (intervalValueInput === 10) {
			this.setState({
				dataCount10: dataCountInterval,
				timeArray10: timeArrayInterval,
				sensorThrottleArray10: sensorThrottleArrayInterval, 
				throttleArray10: throttleArrayInterval, 
				escTempArray10: escTempArrayInterval, 
				escCurrentArray10: escCurrentArrayInterval, 
				batteryPercentageArray10: batteryPercentageArrayInterval, 
				batteryVoltageArray10: batteryVoltageArrayInterval, 
				batteryCurrentArray10: batteryCurrentArrayInterval, 
				minCellVoltageArray10: minCellVoltageArrayInterval, 
				bmsTempArray10: bmsTempArrayInterval, 
				gpsLngArray10: gpsLngArrayInterval,
				gpsLatArray10: gpsLatArrayInterval,
				gpsSpeedArray10: gpsSpeedArrayInterval,
				whPerKmArray10: whPerKmArrayInterval,
			});
		}

		if (intervalValueInput === 30) {
			this.setState({
				dataCount30: dataCountInterval,
				timeArray30: timeArrayInterval,
				sensorThrottleArray30: sensorThrottleArrayInterval, 
				throttleArray30: throttleArrayInterval, 
				escTempArray30: escTempArrayInterval, 
				escCurrentArray30: escCurrentArrayInterval, 
				batteryPercentageArray30: batteryPercentageArrayInterval, 
				batteryVoltageArray30: batteryVoltageArrayInterval, 
				batteryCurrentArray30: batteryCurrentArrayInterval, 
				minCellVoltageArray30: minCellVoltageArrayInterval, 
				bmsTempArray30: bmsTempArrayInterval, 
				gpsLngArray30: gpsLngArrayInterval,
				gpsLatArray30: gpsLatArrayInterval,
				gpsSpeedArray30: gpsSpeedArrayInterval,
				whPerKmArray30: whPerKmArrayInterval,
			});
		}

		if (intervalValueInput === 60) {
			this.setState({
				dataCount60: dataCountInterval,
				timeArray60: timeArrayInterval,
				sensorThrottleArray60: sensorThrottleArrayInterval, 
				throttleArray60: throttleArrayInterval, 
				escTempArray60: escTempArrayInterval, 
				escCurrentArray60: escCurrentArrayInterval, 
				batteryPercentageArray60: batteryPercentageArrayInterval, 
				batteryVoltageArray60: batteryVoltageArrayInterval, 
				batteryCurrentArray60: batteryCurrentArrayInterval, 
				minCellVoltageArray60: minCellVoltageArrayInterval, 
				bmsTempArray60: bmsTempArrayInterval, 
				gpsLngArray60: gpsLngArrayInterval,
				gpsLatArray60: gpsLatArrayInterval,
				gpsSpeedArray60: gpsSpeedArrayInterval,
				whPerKmArray60: whPerKmArrayInterval,
			});
		}

	}

	loadData(dataCount, logFormat) {
		this.calcDataForInterval(10, logFormat);
		this.calcDataForInterval(30, logFormat);
		this.calcDataForInterval(60, logFormat);
	}
	
	setSelectedTab(tab) {
		this.setState({
			selectedTab: tab,
		});
	}
	
	renderTab() {

		var rideID = this.props.rideID;
		var selectedTab = this.state.selectedTab;
		var dataCount = this.state.dataCount;
		var dataInterval = this.props.dataInterval;
		
		if (rideID <= 0) {
			return null;
		}

		if (dataCount === 0) {
			return null;
		}
		
		var dataCountCurrent;
		var timeArrayCurrent;
		var sensorThrottleArrayCurrent;
		var throttleArrayCurrent;
		var escTempArrayCurrent;
		var escCurrentArrayCurrent;
		var batteryPercentageArrayCurrent;
		var batteryVoltageArrayCurrent;
		var batteryCurrentArrayCurrent;
		var minCellVoltageArrayCurrent;
		var bmsTempArrayCurrent;
		var gpsLngArrayCurrent;
		var gpsLatArrayCurrent;
		var gpsSpeedArrayCurrent;
		var whPerKmArrayCurrent;

		if (dataInterval === 1) {
			dataCountCurrent = this.state.dataCount;
			timeArrayCurrent = this.state.timeArray;
			sensorThrottleArrayCurrent = this.state.sensorThrottleArray;
			throttleArrayCurrent = this.state.throttleArray;
			escTempArrayCurrent = this.state.escTempArray;
			escCurrentArrayCurrent = this.state.escCurrentArray;
			batteryPercentageArrayCurrent = this.state.batteryPercentageArray;
			batteryVoltageArrayCurrent = this.state.batteryVoltageArray;
			batteryCurrentArrayCurrent = this.state.batteryCurrentArray;
			minCellVoltageArrayCurrent = this.state.minCellVoltageArray;
			bmsTempArrayCurrent = this.state.bmsTempArray;
			gpsLngArrayCurrent = this.state.gpsLngArray;
			gpsLatArrayCurrent = this.state.gpsLatArray;
			gpsSpeedArrayCurrent = this.state.gpsSpeedArray;
			whPerKmArrayCurrent = this.state.whPerKmArray;
		}
		
		if (dataInterval === 10) {
			dataCountCurrent = this.state.dataCount10;
			timeArrayCurrent = this.state.timeArray10;
			sensorThrottleArrayCurrent = this.state.sensorThrottleArray10;
			throttleArrayCurrent = this.state.throttleArray10;
			escTempArrayCurrent = this.state.escTempArray10;
			escCurrentArrayCurrent = this.state.escCurrentArray10;
			batteryPercentageArrayCurrent = this.state.batteryPercentageArray10;
			batteryVoltageArrayCurrent = this.state.batteryVoltageArray10;
			batteryCurrentArrayCurrent = this.state.batteryCurrentArray10;
			minCellVoltageArrayCurrent = this.state.minCellVoltageArray10;
			bmsTempArrayCurrent = this.state.bmsTempArray10;
			gpsLngArrayCurrent = this.state.gpsLngArray10;
			gpsLatArrayCurrent = this.state.gpsLatArray10;
			gpsSpeedArrayCurrent = this.state.gpsSpeedArray10;
			whPerKmArrayCurrent = this.state.whPerKmArray10;
		}
		
		if (dataInterval === 30) {
			dataCountCurrent = this.state.dataCount30;
			timeArrayCurrent = this.state.timeArray30;
			sensorThrottleArrayCurrent = this.state.sensorThrottleArray30;
			throttleArrayCurrent = this.state.throttleArray30;
			escTempArrayCurrent = this.state.escTempArray30;
			escCurrentArrayCurrent = this.state.escCurrentArray30;
			batteryPercentageArrayCurrent = this.state.batteryPercentageArray30;
			batteryVoltageArrayCurrent = this.state.batteryVoltageArray30;
			batteryCurrentArrayCurrent = this.state.batteryCurrentArray30;
			minCellVoltageArrayCurrent = this.state.minCellVoltageArray30;
			bmsTempArrayCurrent = this.state.bmsTempArray30;
			gpsLngArrayCurrent = this.state.gpsLngArray30;
			gpsLatArrayCurrent = this.state.gpsLatArray30;
			gpsSpeedArrayCurrent = this.state.gpsSpeedArray30;
			whPerKmArrayCurrent = this.state.whPerKmArray30;
		}
		
		if (dataInterval === 60) {
			dataCountCurrent = this.state.dataCount60;
			timeArrayCurrent = this.state.timeArray60;
			sensorThrottleArrayCurrent = this.state.sensorThrottleArray60;
			throttleArrayCurrent = this.state.throttleArray60;
			escTempArrayCurrent = this.state.escTempArray60;
			escCurrentArrayCurrent = this.state.escCurrentArray60;
			batteryPercentageArrayCurrent = this.state.batteryPercentageArray60;
			batteryVoltageArrayCurrent = this.state.batteryVoltageArray60;
			batteryCurrentArrayCurrent = this.state.batteryCurrentArray60;
			minCellVoltageArrayCurrent = this.state.minCellVoltageArray60;
			bmsTempArrayCurrent = this.state.bmsTempArray60;
			gpsLngArrayCurrent = this.state.gpsLngArray60;
			gpsLatArrayCurrent = this.state.gpsLatArray60;
			gpsSpeedArrayCurrent = this.state.gpsSpeedArray60;
			whPerKmArrayCurrent = this.state.whPerKmArray60;
		}

		if (selectedTab === "stats") {
			return (
				<RideStats
					baseURLAPI={this.props.baseURLAPI}
					sailfinAppUserType={this.props.sailfinAppUserType}
					rideID={this.props.rideID}
					boardID={this.state.boardID}
					uploadedUnixTimeSec={this.state.uploadedUnixTimeSec}
					durationSec={this.state.durationSec}
					softwareVersionESP={this.state.softwareVersionESP}
					softwareVersionMicro={this.state.softwareVersionMicro}
					unixTimeSec={this.state.unixTimeSec}
					batteryName={this.state.batteryName}
					batteryNominalCapacity={this.state.batteryNominalCapacity}
					batteryCycleTimes={this.state.batteryCycleTimes}
					boardNumber={this.state.boardNumber}
					dataCount={this.state.dataCount}
					sensorThrottleArray={this.state.sensorThrottleArray}
					throttleArray={this.state.throttleArray}
					escTempArray={this.state.escTempArray}
					escCurrentArray={this.state.escCurrentArray}
					batteryPercentageArray={this.state.batteryPercentageArray}
					batteryVoltageArray={this.state.batteryVoltageArray}
					batteryCurrentArray={this.state.batteryCurrentArray}
					minCellVoltageArray={this.state.minCellVoltageArray}
					bmsTempArray={this.state.bmsTempArray}
					gpsSpeedArray={this.state.gpsSpeedArray}
					whPerKmArray={this.state.whPerKmArray}
					getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "chart") {
			return (
				<RideChart
					dataInterval={this.props.dataInterval}
					sensorThrottleYN={this.props.sensorThrottleYN}
					throttleYN={this.props.throttleYN}
					escTempYN={this.props.escTempYN}
					escCurrentYN={this.props.escCurrentYN}
					batteryPercentageYN={this.props.batteryPercentageYN}
					batteryVoltageYN={this.props.batteryVoltageYN}
					batteryCurrentYN={this.props.batteryCurrentYN}
					minCellVoltageYN={this.props.minCellVoltageYN}
					bmsTempYN={this.props.bmsTempYN}
					gpsSpeedYN={this.props.gpsSpeedYN}
					whPerKmYN={this.props.whPerKmYN}
					dataCountCurrent={dataCountCurrent}
					timeArrayCurrent={timeArrayCurrent}
					sensorThrottleArrayCurrent={sensorThrottleArrayCurrent}
					throttleArrayCurrent={throttleArrayCurrent}
					escTempArrayCurrent={escTempArrayCurrent}
					escCurrentArrayCurrent={escCurrentArrayCurrent}
					batteryPercentageArrayCurrent={batteryPercentageArrayCurrent}
					batteryVoltageArrayCurrent={batteryVoltageArrayCurrent}
					batteryCurrentArrayCurrent={batteryCurrentArrayCurrent}
					minCellVoltageArrayCurrent={minCellVoltageArrayCurrent}
					bmsTempArrayCurrent={bmsTempArrayCurrent}
					gpsLngArrayCurrent={gpsLngArrayCurrent}
					gpsLatArrayCurrent={gpsLatArrayCurrent}
					gpsSpeedArrayCurrent={gpsSpeedArrayCurrent}
					whPerKmArrayCurrent={whPerKmArrayCurrent}
					sensorThrottleArray={this.state.sensorThrottleArray}
					throttleArray={this.state.throttleArray}
					escTempArray={this.state.escTempArray}
					escCurrentArray={this.state.escCurrentArray}
					batteryPercentageArray={this.state.batteryPercentageArray}
					batteryVoltageArray={this.state.batteryVoltageArray}
					batteryCurrentArray={this.state.batteryCurrentArray}
					minCellVoltageArray={this.state.minCellVoltageArray}
					bmsTempArray={this.state.bmsTempArray}
					gpsLngArray={this.state.gpsLngArray}
					gpsLatArray={this.state.gpsLatArray}
					gpsSpeedArray={this.state.gpsSpeedArray}
					whPerKmArray={this.state.whPerKmArray}
					getTimeStringFromSeconds={this.props.getTimeStringFromSeconds}
					getLang={this.props.getLang}
				/>
			);
		}
		
		if (selectedTab === "map") {
			return (
				<RideMap
					dataInterval={this.props.dataInterval}
					sensorThrottleYN={this.props.sensorThrottleYN}
					throttleYN={this.props.throttleYN}
					escTempYN={this.props.escTempYN}
					escCurrentYN={this.props.escCurrentYN}
					batteryPercentageYN={this.props.batteryPercentageYN}
					batteryVoltageYN={this.props.batteryVoltageYN}
					batteryCurrentYN={this.props.batteryCurrentYN}
					minCellVoltageYN={this.props.minCellVoltageYN}
					bmsTempYN={this.props.bmsTempYN}
					gpsSpeedYN={this.props.gpsSpeedYN}
					whPerKmYN={this.props.whPerKmYN}
					dataCountCurrent={dataCountCurrent}
					timeArrayCurrent={timeArrayCurrent}
					sensorThrottleArrayCurrent={sensorThrottleArrayCurrent}
					throttleArrayCurrent={throttleArrayCurrent}
					escTempArrayCurrent={escTempArrayCurrent}
					escCurrentArrayCurrent={escCurrentArrayCurrent}
					batteryPercentageArrayCurrent={batteryPercentageArrayCurrent}
					batteryVoltageArrayCurrent={batteryVoltageArrayCurrent}
					batteryCurrentArrayCurrent={batteryCurrentArrayCurrent}
					minCellVoltageArrayCurrent={minCellVoltageArrayCurrent}
					bmsTempArrayCurrent={bmsTempArrayCurrent}
					gpsLngArrayCurrent={gpsLngArrayCurrent}
					gpsLatArrayCurrent={gpsLatArrayCurrent}
					gpsSpeedArrayCurrent={gpsSpeedArrayCurrent}
					whPerKmArrayCurrent={whPerKmArrayCurrent}
					sensorThrottleArray={this.state.sensorThrottleArray}
					throttleArray={this.state.throttleArray}
					escTempArray={this.state.escTempArray}
					escCurrentArray={this.state.escCurrentArray}
					batteryPercentageArray={this.state.batteryPercentageArray}
					batteryVoltageArray={this.state.batteryVoltageArray}
					batteryCurrentArray={this.state.batteryCurrentArray}
					minCellVoltageArray={this.state.minCellVoltageArray}
					bmsTempArray={this.state.bmsTempArray}
					gpsLngArray={this.state.gpsLngArray}
					gpsLatArray={this.state.gpsLatArray}
					gpsSpeedArray={this.state.gpsSpeedArray}
					whPerKmArray={this.state.whPerKmArray}
					getTimeStringFromSeconds={this.props.getTimeStringFromSeconds}
					getLang={this.props.getLang}
				/>
			);
		}
		
		if (selectedTab === "table") {
			return (
				<RideTable
					sensorThrottleYN={this.props.sensorThrottleYN}
					throttleYN={this.props.throttleYN}
					escTempYN={this.props.escTempYN}
					escCurrentYN={this.props.escCurrentYN}
					batteryPercentageYN={this.props.batteryPercentageYN}
					batteryVoltageYN={this.props.batteryVoltageYN}
					batteryCurrentYN={this.props.batteryCurrentYN}
					minCellVoltageYN={this.props.minCellVoltageYN}
					bmsTempYN={this.props.bmsTempYN}
					gpsSpeedYN={this.props.gpsSpeedYN}
					whPerKmYN={this.props.whPerKmYN}
					dataCountCurrent={dataCountCurrent}
					timeArrayCurrent={timeArrayCurrent}
					sensorThrottleArrayCurrent={sensorThrottleArrayCurrent}
					throttleArrayCurrent={throttleArrayCurrent}
					escTempArrayCurrent={escTempArrayCurrent}
					escCurrentArrayCurrent={escCurrentArrayCurrent}
					batteryPercentageArrayCurrent={batteryPercentageArrayCurrent}
					batteryVoltageArrayCurrent={batteryVoltageArrayCurrent}
					batteryCurrentArrayCurrent={batteryCurrentArrayCurrent}
					minCellVoltageArrayCurrent={minCellVoltageArrayCurrent}
					bmsTempArrayCurrent={bmsTempArrayCurrent}
					gpsLngArrayCurrent={gpsLngArrayCurrent}
					gpsLatArrayCurrent={gpsLatArrayCurrent}
					gpsSpeedArrayCurrent={gpsSpeedArrayCurrent}
					whPerKmArrayCurrent={whPerKmArrayCurrent}
					getTimeStringFromSeconds={this.props.getTimeStringFromSeconds}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "download") {
			return (
				<RideDownload
					baseURLServer={this.props.baseURLServer}
					rideID={this.props.rideID}
					boardNumber={this.state.boardNumber}
					unixTimeSec={this.state.unixTimeSec}
					uploadedUnixTimeSec={this.state.uploadedUnixTimeSec}
					dataCount={this.state.dataCount}
					timeArray={this.state.timeArray}
					sensorThrottleArray={this.state.sensorThrottleArray}
					throttleArray={this.state.throttleArray}
					escTempArray={this.state.escTempArray}
					escCurrentArray={this.state.escCurrentArray}
					batteryPercentageArray={this.state.batteryPercentageArray}
					batteryVoltageArray={this.state.batteryVoltageArray}
					batteryCurrentArray={this.state.batteryCurrentArray}
					minCellVoltageArray={this.state.minCellVoltageArray}
					bmsTempArray={this.state.bmsTempArray}
					batteryConnectedArray={this.state.batteryConnectedArray}
					residualCapacityArray={this.state.residualCapacityArray}
					minCellVoltageIndexArray={this.state.minCellVoltageIndexArray}
					gpsLngArray={this.state.gpsLngArray}
					gpsLatArray={this.state.gpsLatArray}
					gpsSpeedArray={this.state.gpsSpeedArray}
					whPerKmArray={this.state.whPerKmArray}
					temp1Array={this.state.temp1Array}
					temp2Array={this.state.temp2Array}
					temp3Array={this.state.temp3Array}
					temp4Array={this.state.temp4Array}
					temp5Array={this.state.temp5Array}
					temp6Array={this.state.temp6Array}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "settings") {
			return (
				<RideSettings
					dataInterval={this.props.dataInterval}
					sensorThrottleYN={this.props.sensorThrottleYN}
					throttleYN={this.props.throttleYN}
					escTempYN={this.props.escTempYN}
					escCurrentYN={this.props.escCurrentYN}
					batteryPercentageYN={this.props.batteryPercentageYN}
					batteryVoltageYN={this.props.batteryVoltageYN}
					batteryCurrentYN={this.props.batteryCurrentYN}
					minCellVoltageYN={this.props.minCellVoltageYN}
					bmsTempYN={this.props.bmsTempYN}
					gpsSpeedYN={this.props.gpsSpeedYN}
					whPerKmYN={this.props.whPerKmYN}
					setSelectedTab={this.setSelectedTab}
					setRideSettingsValue={this.props.setRideSettingsValue}
					getLang={this.props.getLang}
				/>
			);
		}
	}

	render() {
		return (
			<div>
				<RideToolbar
					rideID={this.props.rideID}
					logFormat={this.state.logFormat}
					dataCount={this.state.dataCount}
					selectedTab={this.state.selectedTab}
					setSelectedTab={this.setSelectedTab}
					getLang={this.props.getLang}
				/>
				{this.renderTab()}
			</div>
		);
	}
}

export default Ride;



