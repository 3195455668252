import React  from 'react';
import './App.css';
import AppLang from './AppLang.json';

class About extends React.Component {

	constructor(props) {

		super(props);

		this.renderOneLine = this.renderOneLine.bind(this);
	}
	
	renderOneLine(dateString, infoText) {
		
		return (
			<div>
				<div className="AboutDateString">{dateString}</div>
				<div className="AboutInfoText">{infoText}</div>
				<div style={{height: "15px"}}></div>
			</div>
		);
	}

	render() {
		return (
			<div>
				<div style={{height: "20px"}}></div>
				<div className="AboutTitle">{this.props.getLang(AppLang, "about")}</div>
				<div style={{height: "20px"}}></div>
				{this.renderOneLine("Sailfin android mob app, boards with ESP32", "https://storageapi.plurato.com/apk/sailfinboard.apk")}
				{this.renderOneLine("Sailfin android mob app, older boards (without ESP32)", "https://storageapi.plurato.com/apk/sailfin.apk")}
				{this.renderOneLine("Battery android mob app", "https://storageapi.plurato.com/apk/battery.apk")}
				{this.renderOneLine("22.5.2022.", "Select company - dodana sekcija")}
				{this.renderOneLine("11.12.2022.", "Translated web inteface to HRV")}
				{this.renderOneLine("08.12.2022.", "Added section \"Battery Logs\"")}
			</div>
		);
	}
}

export default About;

