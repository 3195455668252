import React  from 'react';
import './App.css';
import './Battery.css';
import BatteryLang from './BatteryLang.json';

import { useNavigate } from "react-router-dom";

class BatteryMainToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(tab) {
		this.props.setSelectedTab(tab);
	}
	
	renderLink(linkTitle, linkValue, renderYN) {

		if (!renderYN) {
			return;
		}

		var selectedTab = this.props.selectedTab;
		var classNameString = "BatteryMainToolbarLink";
		
		if (selectedTab === linkValue) {
			classNameString = "BatteryMainToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);		
	}
	
	render() {
		
		var batteryName = this.props.batteryName;
		
		var infoText = this.props.getLang(BatteryLang, "info");
		var logsText = this.props.getLang(BatteryLang, "logs");
		var newCommentText = this.props.getLang(BatteryLang, "newComment");
		var editText = this.props.getLang(BatteryLang, "edit");
		
		var companyIDInit = this.props.companyIDInit;
		var showEditYN = false;
		if (companyIDInit === 1) {
			showEditYN = true;
		}
		
		return (
			<div className="BatteryMainToolbarContainer1">
				<div className="BatteryMainToolbarLeft">Battery: {batteryName}</div>
				<div className="BatteryMainToolbarRight">
					<div className="BatteryMainToolbarContainer2">
						{this.renderLink(infoText, "info", true)} <div className="BatteryMainToolbarText">|</div>  
						{this.renderLink(logsText, "logs", true)} <div className="BatteryMainToolbarText">|</div> 
						{this.renderLink(newCommentText, "newcomment", true)}
						{showEditYN && (<div className="BoardMainToolbarText">|</div>)}
						{this.renderLink(editText, "edit", showEditYN)} 
					</div>
				</div>
			</div>
		);
	}
}

export default function BatteryMainToolbarFunction(props) {
	const navigate = useNavigate();
	return <BatteryMainToolbar {...props} navigate={navigate} />;
}

