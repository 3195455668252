import React  from 'react';
import './App.css';
import './SelectCompany.css';
import { useNavigate } from "react-router-dom";

class SelectCompany extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			companyListJsonObj: null,
		};

		this.serverGetCompanyList = this.serverGetCompanyList.bind(this);
		this.handleSelectCompany = this.handleSelectCompany.bind(this);
		this.renderOneCompany = this.renderOneCompany.bind(this);
		this.renderAllCompanies = this.renderAllCompanies.bind(this);
	}
	
	componentDidMount() {
		this.serverGetCompanyList();
	}

	async serverGetCompanyList() {

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getcompanylist?sortby=companyName&sortasc=1&index=0&count=1000";
		
		const res = await fetch(url);
		const companyListJsonObj = await res.json();
		
		this.setState({
			companyListJsonObj: companyListJsonObj,
		});
	}
	
	handleSelectCompany(companyID, companyName) {
		this.props.setCompany(companyID, companyName);
		const navigate = this.props.navigate;
  		navigate("/dashboard");
	}

	renderOneCompany(index, itemObj) {
		
		var companyID = itemObj.companyID;
		var companyName = itemObj.companyName;
		
		return (
			<div key={index}>
				<div className="SelectCompanyName" onClick={() => this.handleSelectCompany(companyID, companyName)}>
					{companyName}
				</div>
				<div style={{height:"10px"}}></div>
			</div>
		);
	}

	renderAllCompanies() {
		
		var companyListJsonObj = this.state.companyListJsonObj;
		var i;
		var itemObj;
		var jsxCode;
		var jsxArray = [];

		for(i=0; i<companyListJsonObj.length; i++) {
			itemObj = companyListJsonObj[i];
			jsxCode = this.renderOneCompany(i, itemObj);
			jsxArray.push(jsxCode);
		}
		
		return jsxArray;
	}
	
	render() {

		var companyIDInit = this.props.companyIDInit;
		if (companyIDInit !== 1) {
			return null;
		}
		
		var companyListJsonObj = this.state.companyListJsonObj;
		if (companyListJsonObj === null) {
			return null;
		}
		
		return (
			<div>
				<div style={{height:"20px"}}></div>
				<div className="SelectCompanyTitle">Click on company name to select company</div>
				<div style={{height:"10px"}}></div>
				{this.renderAllCompanies()}				
			</div>
		);
	}
}

export default function SelectCompanyFunct(props) {
	const navigate = useNavigate();
	return <SelectCompany {...props} navigate={navigate} />;
}

