import React  from 'react';
import './App.css';
import './Ride.css';
import RideLang from './RideLang.json';
import MaterialTable from 'material-table';

class RideTable extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			width: 0,
			height: 0,
		}

		this.updateDimensions = this.updateDimensions.bind(this);
		this.loadHeader = this.loadHeader.bind(this);
		this.getOneRow = this.getOneRow.bind(this);
		this.getRows = this.getRows.bind(this);
	}

	componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	};

	loadHeader() {
		
		var oneHeaderCell;
		var columns = [];

		// time
		oneHeaderCell = {};
		oneHeaderCell.field = "time";
		oneHeaderCell.title = this.props.getLang(RideLang, "time");
		oneHeaderCell.type = 'numeric';
		columns.push(oneHeaderCell);

		if (this.props.sensorThrottleYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "sensorThrottle";
			oneHeaderCell.title = this.props.getLang(RideLang, "sensorThrottle");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.throttleYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "throttle";
			oneHeaderCell.title = this.props.getLang(RideLang, "throttle");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.escTempYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "escTemp";
			oneHeaderCell.title = this.props.getLang(RideLang, "escTemperature");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.escCurrentYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "escCurrent";
			oneHeaderCell.title = this.props.getLang(RideLang, "escCurrent");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryPercentageYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "batteryPercentage";
			oneHeaderCell.title = this.props.getLang(RideLang, "batteryPercentage");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryVoltageYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "batteryVoltage";
			oneHeaderCell.title = this.props.getLang(RideLang, "batteryVoltage");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.batteryCurrentYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "batteryCurrent";
			oneHeaderCell.title = this.props.getLang(RideLang, "batteryCurrent");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.minCellVoltageYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "minCellVoltage";
			oneHeaderCell.title = this.props.getLang(RideLang, "minCellVoltage");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.bmsTempYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "bmsTemp";
			oneHeaderCell.title = this.props.getLang(RideLang, "batteryTemperature");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.gpsSpeedYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "gpsSpeed";
			oneHeaderCell.title = this.props.getLang(RideLang, "gpsSpeedKnots");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		if (this.props.whPerKmYN) {
			oneHeaderCell = {};
			oneHeaderCell.field = "whPerNauticalMile";
			oneHeaderCell.title = this.props.getLang(RideLang, "whPerNauticalMile");
			oneHeaderCell.type = 'numeric';
			columns.push(oneHeaderCell);
		}

		return columns;
	}

	getOneRow(i) {

		var oneRow = {};
		
		var time = this.props.timeArrayCurrent[i];
		var timeString =  this.props.getTimeStringFromSeconds(time);

		oneRow.time = timeString;
		oneRow.sensorThrottle = this.props.sensorThrottleArrayCurrent[i];
		oneRow.throttle = this.props.throttleArrayCurrent[i];
		oneRow.escTemp = this.props.escTempArrayCurrent[i];
		oneRow.escCurrent = this.props.escCurrentArrayCurrent[i];
		oneRow.batteryPercentage = this.props.batteryPercentageArrayCurrent[i];
		oneRow.batteryVoltage = this.props.batteryVoltageArrayCurrent[i];
		oneRow.batteryCurrent = this.props.batteryCurrentArrayCurrent[i];
		oneRow.minCellVoltage = this.props.minCellVoltageArrayCurrent[i];
		oneRow.bmsTemp = this.props.bmsTempArrayCurrent[i];
		var meterPerSecond = this.props.gpsSpeedArrayCurrent[i];
		var knots = 1.9438452 * meterPerSecond;
		oneRow.gpsSpeed = knots.toFixed(2);
		var whPerKm = this.props.whPerKmArrayCurrent[i];
		var whPerNauticalMile = 1.852 * whPerKm;
		oneRow.whPerNauticalMile = whPerNauticalMile.toFixed(2);

		return oneRow;
	}
	
	getRows() {
		
		var i;
		var rows = [];
		var oneRow;
		
		for(i=0; i<this.props.dataCountCurrent; i++) {
			oneRow = this.getOneRow(i);
			rows.push(oneRow);
		}

		return rows;		
	}

	render() {
		
		var columns = this.loadHeader();
		//var height = window.innerHeight - 300;
		var rows = this.getRows();
	
		return (
			<div className="RideTableContainer">
				<MaterialTable
					options={{
						showTitle: false,
						search: false,
						paging: false,
						sorting: false,
						exportButton: false,
						maxBodyHeight: "85vh",
						headerStyle: {
							fontWeight: 'bold',
							zIndex: 1,
						},
						rowStyle: {
							borderBottom: '2px solid white',
							margin: "0px 0px 0px 0px"
						},
					}}
					columns={columns}
					data={rows}
					components={{
						Toolbar: () => null,
					}}						
				/>
			</div>
		);
	}
}

export default RideTable;

/*
					headerStyle: {
						fontWeight: 'bold',
					},
				className="RideTableContainer"
*/