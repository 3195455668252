import React  from 'react';
import './App.css';
import './Ride.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import RideLang from './RideLang.json';

import { useNavigate } from "react-router-dom";

class RideStats extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			throttleSensorAverage: 0,
			throttleSensorMax: 0,
			throttleAverage: 0,
			throttleMax: 0,
			escTempAverage: 0,
			escTempMax: 0,
			escCurrentAverage: 0,
			escCurrentMax: 0,
			batteryPercentageMin: 0,
			batteryPercentageMax: 0,
			batteryVoltageAverage: 0,
			batteryVoltageMin: 0,
			batteryVoltageMax: 0,
			batteryCurrentAverage: 0,
			batteryCurrentMax: 0,
			minCellVoltageAverage: 0,
			minCellVoltageMin: 0,
			minCellVoltageMax: 0,
			bmsTempAverage: 0,
			bmsTempMax: 0,
			gpsSpeedAverage: 0,
			gpsSpeedMax: 0,
			whPerKmAverage: 0,
			whPerKmMax: 0,
		}

		this.loadData = this.loadData.bind(this);
		this.twoDigits = this.twoDigits.bind(this);
		this.getTimeStringFromSeconds = this.getTimeStringFromSeconds.bind(this);
		this.handleClickDeleteButton = this.handleClickDeleteButton.bind(this);
		this.serverDeleteRideRecord = this.serverDeleteRideRecord.bind(this);
	}
	
	componentDidMount() {
		this.loadData();
	}

	loadData() {

		var i;
		var dataCount = this.props.dataCount;
		var sensorThrottle = 0;
		var sensorThrottleSum = 0;
		var sensorThrottleCountForAverage = 0;
		var sensorThrottleAverage = 0;
		var sensorThrottleMax = 0;
		var throttle = 0;
		var throttleSum = 0;
		var throttleCountForAverage = 0;
		var throttleAverage = 0;
		var throttleMax = 0;
		var escTemp = 0;
		var escTempSum = 0;
		var escTempAverage = 0;
		var escTempMax = 0;
		var escCurrent = 0;
		var escCurrentSum = 0;
		var escCurrentCountForAverage = 0;
		var escCurrentAverage = 0;
		var escCurrentMax = 0;
		var batteryPercentage = 0;
		var batteryPercentageMin = 1000;
		var batteryPercentageMax = 0;
		var batteryVoltage = 0;
		var batteryVoltageSum = 0;
		var batteryVoltageAverage = 0;
		var batteryVoltageMin = 1000;
		var batteryVoltageMax = 0;
		var batteryCurrent = 0;
		var batteryCurrentSum = 0;
		var batteryCurrentCountForAverage = 0;
		var batteryCurrentAverage = 0;
		var batteryCurrentMax = 0;
		var minCellVoltage = 0;
		var minCellVoltageSum = 0;
		var minCellVoltageCountForAverage = 0;
		var minCellVoltageAverage = 0;
		var minCellVoltageMin = 1000;
		var minCellVoltageMax = 0;
		var bmsTemp = 0;
		var bmsTempSum = 0;
		var bmsTempAverage = 0;
		var bmsTempMax = 0;
		var gpsSpeed = 0;
		var gpsSpeedSum = 0;
		var gpsSpeedCount = 0;
		var gpsSpeedAverage = 0;
		var gpsSpeedMax = 0;
		var whPerKm = 0;
		var whPerKmSum = 0;
		var whPerKmCount = 0;
		var whPerKmAverage = 0;
		var whPerKmMax = 0;					

		for(i=0; i<dataCount; i++) {
			
			sensorThrottle = this.props.sensorThrottleArray[i];
			throttle = this.props.throttleArray[i];
			escTemp = this.props.escTempArray[i];
			escCurrent = this.props.escCurrentArray[i];
			batteryPercentage = this.props.batteryPercentageArray[i];
			batteryVoltage = this.props.batteryVoltageArray[i];
			batteryCurrent = this.props.batteryCurrentArray[i];
			minCellVoltage = this.props.minCellVoltageArray[i];
			bmsTemp = this.props.bmsTempArray[i];
			gpsSpeed = this.props.gpsSpeedArray[i];
			whPerKm = this.props.whPerKmArray[i];
			
			if (sensorThrottle > 10) {
				sensorThrottleCountForAverage = sensorThrottleCountForAverage + 1;
				sensorThrottleSum += sensorThrottle;
			}
			if (sensorThrottle > sensorThrottleMax) {
				sensorThrottleMax = sensorThrottle;
			}
			
			if (throttle > 950) {
				throttleCountForAverage = throttleCountForAverage + 1;
				throttleSum += throttle;
			}
			if (throttle > throttleMax) {
				throttleMax = throttle;
			}
			
			escTempSum += escTemp;
			if (escTemp > escTempMax) {
				escTempMax = escTemp;
			}
			
			if (escCurrent > 3.0) {
				escCurrentCountForAverage = escCurrentCountForAverage + 1;
				escCurrentSum += escCurrent;
			}
			if (escCurrent > escCurrentMax) {
				escCurrentMax = escCurrent;
			}
			
			if (batteryPercentage < batteryPercentageMin) {
				batteryPercentageMin = batteryPercentage;
			}
			if (batteryPercentage > batteryPercentageMax) {
				batteryPercentageMax = batteryPercentage;
			}
			
			batteryVoltageSum += batteryVoltage;
			if (batteryVoltage < batteryVoltageMin) {
				if (batteryVoltage > 0) {
					batteryVoltageMin = batteryVoltage;
				}
			}
			if (batteryVoltage > batteryVoltageMax) {
				batteryVoltageMax = batteryVoltage;
			}			
			
			if (batteryCurrent > 3.0) {
				batteryCurrentCountForAverage = batteryCurrentCountForAverage + 1;
				batteryCurrentSum += batteryCurrent;
			}
			if (batteryCurrent > batteryCurrentMax) {
				batteryCurrentMax = batteryCurrent;
			}
			
			if (minCellVoltage > 1.0) {
				minCellVoltageSum += minCellVoltage;
				minCellVoltageCountForAverage = minCellVoltageCountForAverage + 1;
				if (minCellVoltage < minCellVoltageMin) {
					if (minCellVoltage > 0) {
						minCellVoltageMin = minCellVoltage;
					}
				}
				if (minCellVoltage > minCellVoltageMax) {
					minCellVoltageMax = minCellVoltage;
				}
			}
			
			bmsTempSum += bmsTemp;
			if (bmsTemp > bmsTempMax) {
				bmsTempMax = bmsTemp;
			}

			if (gpsSpeed > 1) {
				gpsSpeedSum += gpsSpeed;
				gpsSpeedCount ++;
			}

			if (gpsSpeed > gpsSpeedMax) {
				gpsSpeedMax = gpsSpeed;
			}
			
			if (whPerKm > 1) {
				whPerKmSum += whPerKm;
				whPerKmCount ++;
			}

			if (whPerKm > whPerKmMax) {
				whPerKmMax = whPerKm;
			}
		}
		
		if (sensorThrottleCountForAverage === 0) {
			sensorThrottleAverage = 0;
		} else {
			sensorThrottleAverage = sensorThrottleSum / sensorThrottleCountForAverage;
		}
		sensorThrottleAverage = Math.round(sensorThrottleAverage);

		if (throttleCountForAverage === 0) {
			throttleAverage = 0;
		} else {
			throttleAverage = throttleSum / throttleCountForAverage;
		}
		throttleAverage = Math.round(throttleAverage);

		escTempAverage = escTempSum / dataCount;
		escTempAverage = Math.round(escTempAverage* 10) / 10;

		if (escCurrentCountForAverage === 0) {
			escCurrentAverage = 0;
		} else {
			escCurrentAverage = escCurrentSum / escCurrentCountForAverage;
		}
		escCurrentAverage = Math.round(escCurrentAverage * 10) / 10;

		batteryVoltageAverage = batteryVoltageSum / dataCount;
		batteryVoltageAverage = Math.round(batteryVoltageAverage* 10) / 10;

		if (batteryCurrentCountForAverage === 0) {
			batteryCurrentAverage = 0;
		} else {
			batteryCurrentAverage = batteryCurrentSum / batteryCurrentCountForAverage;
		}
		batteryCurrentAverage = Math.round(batteryCurrentAverage * 10) / 10;

		minCellVoltageAverage = minCellVoltageSum / minCellVoltageCountForAverage;
		minCellVoltageAverage = Math.round(minCellVoltageAverage * 1000) / 1000;

		bmsTempAverage = bmsTempSum / dataCount;
		bmsTempAverage = Math.round(bmsTempAverage * 10) / 10;

		if (gpsSpeedCount === 0) {
			gpsSpeedAverage = 0;
		} else {
			gpsSpeedAverage = gpsSpeedSum / gpsSpeedCount;
		}
		
		if (whPerKmCount === 0) {
			whPerKmAverage = 0;
		} else {
			whPerKmAverage = whPerKmSum / whPerKmCount;
		}
		
		this.setState({
				sensorThrottleAverage: sensorThrottleAverage, 
				sensorThrottleMax: sensorThrottleMax,
				throttleAverage: throttleAverage,
				throttleMax: throttleMax,
				escTempAverage: escTempAverage,
				escTempMax: escTempMax,
				escCurrentAverage: escCurrentAverage,
				escCurrentMax: escCurrentMax,
				batteryPercentageMin: batteryPercentageMin,
				batteryPercentageMax: batteryPercentageMax,
				batteryVoltageAverage: batteryVoltageAverage,
				batteryVoltageMin: batteryVoltageMin,
				batteryVoltageMax: batteryVoltageMax,
				batteryCurrentAverage: batteryCurrentAverage,
				batteryCurrentMax: batteryCurrentMax,
				minCellVoltageAverage: minCellVoltageAverage,
				minCellVoltageMin: minCellVoltageMin,
				minCellVoltageMax: minCellVoltageMax,
				bmsTempAverage: bmsTempAverage,
				bmsTempMax: bmsTempMax,
				gpsSpeedAverage: gpsSpeedAverage,
				gpsSpeedMax: gpsSpeedMax,
				whPerKmAverage: whPerKmAverage,
				whPerKmMax: whPerKmMax,
		});
	}

	twoDigits(value1) {
		if (value1 < 10) {
			return "0" + value1;
		} else {
			return value1;
		}
	}

	getTimeStringFromSeconds(seconds) {

		var hours;
		var minutes;
		var secondsLeft;
		var timeString = "";
	
		hours = Math.floor(seconds / 3600);
		secondsLeft = seconds - hours * 3600;

		minutes = Math.floor(secondsLeft / 60);
		seconds = secondsLeft - minutes * 60;
	
		if (hours > 0) {
			timeString = hours + ":";
		}
	
		timeString = timeString + this.twoDigits(minutes) + ":" + this.twoDigits(seconds);
		return timeString;
	}
	
	handleClickDeleteButton() {
		
		var rideID = this.props.rideID;

		confirmAlert({
			title: this.props.getLang(RideLang, "deleteRide"),
			message: this.props.getLang(RideLang, "deleteRideAreYouSure"),
			buttons: [
				{
					label: this.props.getLang(RideLang, "yes"),
					onClick: () => this.serverDeleteRideRecord(rideID)
				},
				{
					label: this.props.getLang(RideLang, "no"),
				}
			]
		});
		
	}

	async serverDeleteRideRecord(rideID) {

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/deleteriderecord?rideid=" + rideID;

		const res = await fetch(url);
		await res.text();

		this.props.showToast(this.props.getLang(RideLang, "rideDeleted") + ": \"" + rideID + "\"");
		const navigate = this.props.navigate;
  		navigate("/dashboard");
	}

	render() {

		var rideID = this.props.rideID;
		var uploadedUnixTimeSec = this.props.uploadedUnixTimeSec;
		var boardNumber = this.props.boardNumber;
		var durationSec = this.props.durationSec;
		var softwareVersionESP = this.props.softwareVersionESP;
		var softwareVersionMicro = this.props.softwareVersionMicro;
		var unixTimeSec = this.props.unixTimeSec;
		var batteryName = this.props.batteryName;
		var batteryNominalCapacity = this.props.batteryNominalCapacity;
		var batteryCycleTimes = this.props.batteryCycleTimes;
		var sailfinAppUserType = this.props.sailfinAppUserType;

		var durationString = this.getTimeStringFromSeconds(durationSec);
		var softwareString = softwareVersionESP + "/" + softwareVersionMicro;

		var showDeleteButtonYN = false;
		if (sailfinAppUserType >= 100) {
			showDeleteButtonYN = true;
		}
		
		var uploadedTimeString = this.props.getDateTimeStringFromUnixTimeSec(uploadedUnixTimeSec);
		var unixTimeString = this.props.getDateTimeStringFromUnixTimeSec(unixTimeSec);

		var metersPerSecond = this.state.gpsSpeedAverage;
		var knots = 1.9438452 * metersPerSecond;
		var kmPerHour = 3.6 * metersPerSecond;
		var gpsSpeedAverageString = knots.toFixed(2) + " knots, " + kmPerHour.toFixed(2) + " km/h";
		
		metersPerSecond = this.state.gpsSpeedMax;
		knots = 1.9438452 * metersPerSecond;
		kmPerHour = 3.6 * metersPerSecond;
		var gpsSpeedMaxString = knots.toFixed(2) + " knots, " + kmPerHour.toFixed(2) + " km/h";
		
		var whPerKmAverage = this.state.whPerKmAverage;
		var whPerNauticalMileAverage = 1.852 * whPerKmAverage;
		var whPerDistanceAverageString = whPerNauticalMileAverage.toFixed(2) + " Wh/naut mile, " + whPerKmAverage.toFixed(2) + " Wh/km";

		var whPerKmMax = this.state.whPerKmMax;
		var whPerNauticalMileMax = 1.852 * whPerKmMax;
		var whPerDistanceMaxString = whPerNauticalMileMax.toFixed(2) + " Wh/naut mile, " + whPerKmMax.toFixed(2) + " Wh/km";

		return (
			<div>
				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "rideInfo")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "rideID")}: {rideID}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "rideStarted")}: {unixTimeString}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "uploaded")}: {uploadedTimeString}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "boardNumber")}: {boardNumber}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "swVersion")}: {softwareString}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "duration")}: {durationSec} sec - {durationString}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "batteryName")}: {batteryName}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "batteryNominalCapacity")}: {batteryNominalCapacity}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "batteryCycleTimes")}: {batteryCycleTimes}</p>
				
				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "sensorThrottle")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "average")}: {this.state.sensorThrottleAverage}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "max")}: {this.state.sensorThrottleMax}</p>
				
				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "throttle")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "average")}: {this.state.throttleAverage}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "max")}: {this.state.throttleMax}</p>

				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "escTemperature")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "average")}: {this.state.escTempAverage} °C</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "max")}: {this.state.escTempMax} °C</p>

				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "escCurrent")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "average")}: {this.state.escCurrentAverage} A</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "max")}: {this.state.escCurrentMax} A</p>

				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "batteryPercentage")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "min")}: {this.state.batteryPercentageMin} %</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "max")}: {this.state.batteryPercentageMax} %</p>

				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "batteryVoltage")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "average")}: {this.state.batteryVoltageAverage} V</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "min")}: {this.state.batteryVoltageMin} V</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "max")}: {this.state.batteryVoltageMax} V</p>

				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "batteryCurrent")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "average")}: {this.state.batteryCurrentAverage} A</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "max")}: {this.state.batteryCurrentMax} A</p>

				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "minCellVoltage")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "average")}: {this.state.minCellVoltageAverage} V</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "min")}: {this.state.minCellVoltageMin} V</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "max")}: {this.state.minCellVoltageMax} V</p>

				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "batteryTemperature")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "average")}: {this.state.bmsTempAverage} °C</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "max")}: {this.state.bmsTempMax} °C</p>

				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "gpsSpeed")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "average")}: {gpsSpeedAverageString}</p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "max")}: {gpsSpeedMaxString}</p>
				
				<p><b className="RideStatsTitle">{this.props.getLang(RideLang, "whPerDistance")}</b></p>
				<p className="RideStatsItem">{this.props.getLang(RideLang, "average")}: {whPerDistanceAverageString}</p>
				
				{showDeleteButtonYN && (
					<div>
						<div style={{height: "30px"}}></div>
						<div className="RideStatsContainerDeleteButton">
							<button className="ViewSaveButton" onClick={this.handleClickDeleteButton}>{this.props.getLang(RideLang, "deleteThisRide")}</button>
						</div>
					</div>)}
				
				<div style={{height: "40px"}}></div>
			</div>
		);
	}
}

export default function RideStatsFunction(props) {
	const navigate = useNavigate();
	return <RideStats {...props} navigate={navigate} />;
}

