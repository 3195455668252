import React  from 'react';
import './App.css';
import './Board.css';
import BoardLang from './BoardLang.json';

import { useNavigate } from "react-router-dom";

class BoardMainToolbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.handleSelectLink = this.handleSelectLink.bind(this);
		this.renderLink = this.renderLink.bind(this);
	}
	
	handleSelectLink(tab) {
		this.props.setSelectedTab(tab);
	}
	
	renderLink(linkTitle, linkValue, renderYN) {
		
		if (!renderYN) {
			return;
		}

		var selectedTab = this.props.selectedTab;
		var classNameString = "BoardMainToolbarLink";
		
		if (selectedTab === linkValue) {
			classNameString = "BoardMainToolbarSelectedLink";
		}
		
		return (
			<div 
				className={classNameString}
				onClick={() => this.handleSelectLink(linkValue)}
			>
				{linkTitle}
			</div>
		);		
	}
	
	render() {
		
		var boardNumber = this.props.boardNumber;
		
		var infoText = this.props.getLang(BoardLang, "info");
		var ridesText = this.props.getLang(BoardLang, "rides");
		var newCommentText = this.props.getLang(BoardLang, "newComment");
		var editText = this.props.getLang(BoardLang, "edit");
		
		var companyIDInit = this.props.companyIDInit;
		var showEditYN = false;
		if (companyIDInit === 1) {
			showEditYN = true;
		}
		
		return (
			<div className="BoardMainToolbarContainer1">
				<div className="BoardMainToolbarLeft">Board: {boardNumber}</div>
				<div className="BoardMainToolbarRight">
					<div className="BoardMainToolbarContainer2">
						{this.renderLink(infoText, "info", true)} <div className="BoardMainToolbarText">|</div>  
						{this.renderLink(ridesText, "rides", true)} <div className="BoardMainToolbarText">|</div> 
						{this.renderLink(newCommentText, "newcomment", true)} 
						{showEditYN && (<div className="BoardMainToolbarText">|</div>)}
						{this.renderLink(editText, "edit", showEditYN)}
					</div>
				</div>
			</div>
		);
	}
}

export default function BoardMainToolbarFunction(props) {
	const navigate = useNavigate();
	return <BoardMainToolbar {...props} navigate={navigate} />;
}

