import React  from 'react';
import './App.css';
import './Board.css';

import BoardMainToolbar from './BoardMainToolbar.js';
import BoardInfo from './BoardInfo.js';
import BoardRideList from './BoardRideList.js';
import BoardNewComment from './BoardNewComment.js';
import BoardEdit from './BoardEdit.js';

import { useNavigate } from "react-router-dom";

class BoardMain extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			selectedTab: "info",
			boardID: 0,
			boardNumber: "",
			boardMacAddress: "",
			companyID: 0,
			companyName: "",
			directCool: 0,
			multiLog: 0,
			escSoftwareVersion: "",
			microSoftwareVersion: "",
			lastServiceUnixTimeSec: 0,
			notes: "",
			testedByUserID: 0,
			testedByUserName: "",
			boardMadeUnixTimeSec: 0,
			boardSoldUnixTimeSec: 0,
			boardColor: "",
			evaMatColor: "",
			screwType: 0,
			electronics: "",
			dataLoadedYN: false,
		};

		this.serverGetBoardData = this.serverGetBoardData.bind(this);
		this.serverGetCompanyData = this.serverGetCompanyData.bind(this);
		this.setSelectedTab = this.setSelectedTab.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}
	
	componentDidMount() {
		var selectedBoardID = this.props.selectedBoardID;
		this.setState({boardID: selectedBoardID});
		this.serverGetBoardData(selectedBoardID);
	}
	
	componentDidUpdate(prevProps) {

		var boardID = this.state.boardID;
		var selectedBoardID = this.props.selectedBoardID;
		
		if (boardID === selectedBoardID) {
			return;
		}

		this.setState({
			boardID: selectedBoardID,
			dataLoadedYN: false,
		});
		this.serverGetBoardData(selectedBoardID);
	}

	async serverGetBoardData(boardID) {

		if (boardID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getboarddata?boardid=" + boardID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var boardNumber = jsonObj.boardNumber;
		var boardMacAddress = jsonObj.boardMacAddress;
		var companyID = jsonObj.companyID;
		var directCool = jsonObj.directCool;
		var multiLog = jsonObj.multiLog;
		var escSoftwareVersion = jsonObj.escSoftwareVersion;
		var microSoftwareVersion = jsonObj.microSoftwareVersion;
		var lastServiceUnixTimeSec = jsonObj.lastServiceUnixTimeSec;
		var notes = jsonObj.notes;
		var testedByUserID = jsonObj.testedByUserID;
		var testedByUserName = jsonObj.testedByUserName;
		var boardMadeUnixTimeSec = jsonObj.boardMadeUnixTimeSec;
		var boardSoldUnixTimeSec = jsonObj.boardSoldUnixTimeSec;
		var boardColor = jsonObj.boardColor;
		var evaMatColor = jsonObj.evaMatColor;
		var screwType = jsonObj.screwType;
		var electronics = jsonObj.electronics;
		
		this.setState({
			boardNumber: boardNumber,
			boardMacAddress: boardMacAddress,
			companyID: companyID,
			directCool: directCool,
			multiLog: multiLog,
			escSoftwareVersion: escSoftwareVersion,
			microSoftwareVersion: microSoftwareVersion,
			lastServiceUnixTimeSec: lastServiceUnixTimeSec,
			notes: notes,
			testedByUserID: testedByUserID,
			testedByUserName: testedByUserName,
			boardMadeUnixTimeSec: boardMadeUnixTimeSec,
			boardSoldUnixTimeSec: boardSoldUnixTimeSec,
			boardColor: boardColor,
			evaMatColor: evaMatColor,
			screwType: screwType,
			electronics: electronics,
			dataLoadedYN: true,
		});
		
		this.serverGetCompanyData(companyID);
	}
	
	async serverGetCompanyData(companyID) {

		if (companyID <= 0) {
			this.setState({
				companyName: "n/a",
			});			
			return;
		}

		var companyName = "n/a";

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getcompanydata?companyid=" + companyID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		companyName = jsonObj.companyName;

		this.setState({
			companyName: companyName,
		});			
	}
	
	setSelectedTab(tab) {
		this.setState({
			selectedTab: tab,
		});
	}
	
	renderTab() {		

		if (!this.state.dataLoadedYN) {
			return null;
		}

		var selectedTab = this.state.selectedTab;
		
		if (selectedTab === "info") {
			return (
				<BoardInfo
					baseURLAPI={this.props.baseURLAPI}
					sailfinAppUserType={this.props.sailfinAppUserType}
					boardID={this.state.boardID}
					boardNumber={this.state.boardNumber}
					boardMacAddress={this.state.boardMacAddress}
					companyID={this.state.companyID}
					companyName={this.state.companyName}
					directCool={this.state.directCool}
					multiLog={this.state.multiLog}
					escSoftwareVersion={this.state.escSoftwareVersion}
					microSoftwareVersion={this.state.microSoftwareVersion}
					lastServiceUnixTimeSec={this.state.lastServiceUnixTimeSec}
					notes={this.state.notes}
					testedByUserID={this.state.testedByUserID}
					testedByUserName={this.state.testedByUserName}
					boardMadeUnixTimeSec={this.state.boardMadeUnixTimeSec}
					boardSoldUnixTimeSec={this.state.boardSoldUnixTimeSec}
					boardColor={this.state.boardColor}
					evaMatColor={this.state.evaMatColor}
					screwType={this.state.screwType}
					electronics={this.state.electronics}
					showToast={this.props.showToast}
					convertTextNewlineToBr={this.props.convertTextNewlineToBr}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "rides") {
			return (
				<BoardRideList
					baseURLAPI={this.props.baseURLAPI}
					boardID={this.state.boardID}
					boardNumber={this.state.boardNumber}
					setRideID={this.props.setRideID}
					getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
					getLang={this.props.getLang}
				/>
			);
		}
		
		if (selectedTab === "edit") {
			return (
				<BoardEdit
					baseURLAPI={this.props.baseURLAPI}
					managementBaseURLAPI={this.props.managementBaseURLAPI}
					sailfinAppUserType={this.props.sailfinAppUserType}
					boardID={this.state.boardID}
					boardNumber={this.state.boardNumber}
					boardMacAddress={this.state.boardMacAddress}
					companyID={this.state.companyID}
					directCool={this.state.directCool}
					multiLog={this.state.multiLog}
					escSoftwareVersion={this.state.escSoftwareVersion}
					microSoftwareVersion={this.state.microSoftwareVersion}
					lastServiceUnixTimeSec={this.state.lastServiceUnixTimeSec}
					notes={this.state.notes}
					testedByUserID={this.state.testedByUserID}
					testedByUserName={this.state.testedByUserName}
					boardMadeUnixTimeSec={this.state.boardMadeUnixTimeSec}
					boardSoldUnixTimeSec={this.state.boardSoldUnixTimeSec}
					boardColor={this.state.boardColor}
					evaMatColor={this.state.evaMatColor}
					screwType={this.state.screwType}
					electronics={this.state.electronics}
					setSelectedTab={this.setSelectedTab}
					serverGetBoardData={this.serverGetBoardData}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "newcomment") {
			return(
				<BoardNewComment
					baseURLAPI={this.props.baseURLAPI}
					boardID={this.state.boardID}
					boardNumber={this.state.boardNumber}
					userID={this.props.userID}
					firstName={this.props.firstName}
					lastName={this.props.lastName}
					email={this.props.email}
					setSelectedTab={this.setSelectedTab}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
				/>
			);
		}		
	}

	render() {
		return (
			<div>
				<BoardMainToolbar
					sailfinAppUserType={this.props.sailfinAppUserType}
					boardID={this.state.boardID}
					boardNumber={this.state.boardNumber}
					companyIDInit={this.props.companyIDInit}
					selectedTab={this.state.selectedTab}
					setSelectedTab={this.setSelectedTab}
					getLang={this.props.getLang}
				/>
				{this.renderTab()}
			</div>
		);
	}
}

export default function BoardMainFunction(props) {
	const navigate = useNavigate();
	return <BoardMain {...props} navigate={navigate} />;
}

