import React  from 'react';
import './App.css';
import './Battery.css';

import BatteryMainToolbar from './BatteryMainToolbar.js';
import BatteryInfo from './BatteryInfo.js';
import BatteryLogList from './BatteryLogList.js';
import BatteryNewComment from './BatteryNewComment.js';
import BatteryEdit from './BatteryEdit.js';

class BatteryMain extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			selectedTab: "info",
			batteryID: 0,
			batteryName: "",
			batteryMacAddress: "",
			companyID: 0,
			companyName: "",
			directCool: 0,
			multiLog: 0,
			softwareVersion: "",
			lastServiceUnixTimeSec: 0,
			notes: "",
			testedByUserID: 0,
			testedByFullName: "",
			batteryMadeUnixTimeSec: 0,
			batterySoldUnixTimeSec: 0,
			batteryMadeByUserID: 0,
			batteryMadeByFullName: "",
			nfcID: "",
			screwType: 0,
			firestop: 0,
			bmsVersion: 0,
			esp: 0,
			diff: 0,
			bimetal: 0,
			testHighCurrent: 0,
			testCage: 0,
			testTemperature: 0,
			testPool: 0,
			testPressure: 0,
			rent: 0,
			dataLoadedYN: false,
		};

		this.serverGetBatteryData = this.serverGetBatteryData.bind(this);
		this.serverGetCompanyData = this.serverGetCompanyData.bind(this);
		this.serverGetBatteryIDFromNfcID = this.serverGetBatteryIDFromNfcID.bind(this);
		this.setSelectedTab = this.setSelectedTab.bind(this);
		this.renderTab = this.renderTab.bind(this);
	}
	
	componentDidMount() {

		var selectedBatteryID = "";

		var directLinkYN = this.props.directLinkYN;
		if (directLinkYN) {
			var searchParams = document.location.search;
			var queryParams = new URLSearchParams(searchParams)
			var nfcID = queryParams.get("nfc")
			this.setState({nfcIDFromUrl: nfcID});
			this.serverGetBatteryIDFromNfcID(nfcID);
		} else {
			selectedBatteryID = this.props.selectedBatteryID;
			this.setState({batteryID: selectedBatteryID});
			this.serverGetBatteryData(selectedBatteryID);
		}
	}
	
	componentDidUpdate(prevProps) {

		var batteryID = this.state.batteryID;
		var selectedBatteryID = this.props.selectedBatteryID;
		
		var directLinkYN = this.props.directLinkYN;
		if (directLinkYN) {
			return;
		}

		if (batteryID === selectedBatteryID) {
			return;
		}

		this.setState({
			batteryID: selectedBatteryID,
			dataLoadedYN: false,
		});
		this.serverGetBatteryData(selectedBatteryID);
	}

	async serverGetBatteryData(batteryID) {

		if (batteryID <= 0) {
			return;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbatterydata?batteryid=" + batteryID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var batteryName = jsonObj.batteryName;
		var batteryMacAddress = jsonObj.batteryMacAddress;
		var companyID = jsonObj.companyID;
		var directCool = jsonObj.directCool;
		var multiLog = jsonObj.multiLog;
		var softwareVersion = jsonObj.softwareVersion;
		var lastServiceUnixTimeSec = jsonObj.lastServiceUnixTimeSec;
		var notes = jsonObj.notes;
		var testedByUserID = jsonObj.testedByUserID;
		var testedByFullName = jsonObj.testedByFullName;
		var batteryMadeUnixTimeSec = jsonObj.batteryMadeUnixTimeSec;
		var batterySoldUnixTimeSec = jsonObj.batterySoldUnixTimeSec;
		var batteryMadeByUserID = jsonObj.batteryMadeByUserID;
		var batteryMadeByFullName = jsonObj.batteryMadeByFullName;
		var nfcID = jsonObj.nfcID;
		var screwType = jsonObj.screwType;
		var firestop = jsonObj.firestop;
		var bmsVersion = jsonObj.bmsVersion;
		var esp = jsonObj.esp;
		var diff = jsonObj.diff;
		var bimetal = jsonObj.bimetal;
		var testHighCurrent = jsonObj.testHighCurrent;
		var testCage = jsonObj.testCage;
		var testTemperature = jsonObj.testTemperature;
		var testPool = jsonObj.testPool;
		var testPressure = jsonObj.testPressure;
		var rent = jsonObj.rent;
		
		this.setState({
			batteryName: batteryName,
			batteryMacAddress: batteryMacAddress,
			companyID: companyID,
			directCool: directCool,
			multiLog: multiLog,
			softwareVersion: softwareVersion,
			lastServiceUnixTimeSec: lastServiceUnixTimeSec,
			notes: notes,
			testedByUserID: testedByUserID,
			testedByFullName: testedByFullName,
			batteryMadeUnixTimeSec: batteryMadeUnixTimeSec,
			batterySoldUnixTimeSec: batterySoldUnixTimeSec,
			batteryMadeByUserID: batteryMadeByUserID,
			batteryMadeByFullName: batteryMadeByFullName,
			nfcID: nfcID,
			screwType: screwType,
			firestop: firestop,
			bmsVersion: bmsVersion,
			esp: esp,
			diff: diff,
			bimetal: bimetal,
			testHighCurrent: testHighCurrent,
			testCage: testCage,
			testTemperature: testTemperature,
			testPool: testPool,
			testPressure: testPressure,
			rent: rent,
			dataLoadedYN: true,
		});

		this.serverGetCompanyData(companyID);
	}
	
	async serverGetCompanyData(companyID) {

		if (companyID <= 0) {
			this.setState({
				companyName: "n/a",
			});			
			return;
		}

		var companyName = "n/a";

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getcompanydata?companyid=" + companyID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		companyName = jsonObj.companyName;

		this.setState({
			companyName: companyName,
		});			
	}

	async serverGetBatteryIDFromNfcID(nfcID) {

		if (nfcID === "") {
			return 0;
		}

		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getbatteryidfromnfcid?nfcid=" + encodeURIComponent(nfcID);
		
		const res = await fetch(url);
		const responseText = await res.text();

		var batteryID = parseInt(responseText);

		this.setState({batteryID: batteryID});
		this.serverGetBatteryData(batteryID);
	}

	setSelectedTab(tab) {
		this.setState({
			selectedTab: tab,
		});
	}
	
	renderTab() {		

		if (this.state.batteryID === 0) {
			if (this.props.directLinkYN) {
				return (<div><br/>Battery with nfc <b>{this.state.nfcIDFromUrl}</b> not found</div>);
			}
			return;
		}

		if (!this.state.dataLoadedYN) {
			return;
		}

		var selectedTab = this.state.selectedTab;
		
		if (selectedTab === "info") {
			return (
				<BatteryInfo
					baseURLAPI={this.props.baseURLAPI}
					sailfinAppUserType={this.props.sailfinAppUserType}
					batteryID={this.state.batteryID}
					batteryName={this.state.batteryName}
					batteryMacAddress={this.state.batteryMacAddress}
					companyID={this.state.companyID}
					companyName={this.state.companyName}
					directCool={this.state.directCool}
					multiLog={this.state.multiLog}
					softwareVersion={this.state.softwareVersion}
					lastServiceUnixTimeSec={this.state.lastServiceUnixTimeSec}
					notes={this.state.notes}
					testedByUserID={this.state.testedByUserID}
					testedByFullName={this.state.testedByFullName}
					batteryMadeUnixTimeSec={this.state.batteryMadeUnixTimeSec}
					batterySoldUnixTimeSec={this.state.batterySoldUnixTimeSec}
					batteryMadeByUserID={this.state.batteryMadeByUserID}
					batteryMadeByFullName={this.state.batteryMadeByFullName}
					nfcID={this.state.nfcID}
					screwType={this.state.screwType}
					firestop={this.state.firestop}
					bmsVersion={this.state.bmsVersion}
					esp={this.state.esp}
					diff={this.state.diff}
					bimetal={this.state.bimetal}
					testHighCurrent={this.state.testHighCurrent}
					testCage={this.state.testCage}
					testTemperature={this.state.testTemperature}
					testPool={this.state.testPool}
					testPressure={this.state.testPressure}
					rent={this.state.rent}
					showToast={this.props.showToast}
					convertTextNewlineToBr={this.props.convertTextNewlineToBr}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "logs") {
			return (
				<BatteryLogList
					baseURLAPI={this.props.baseURLAPI}
					batteryID={this.state.batteryID}
					batteryName={this.state.batteryName}
					setBatteryLogID={this.props.setBatteryLogID}
					getDateTimeStringFromUnixTimeSec={this.props.getDateTimeStringFromUnixTimeSec}
					getTimeStringFromSeconds={this.props.getTimeStringFromSeconds}
					getLang={this.props.getLang}
				/>
			);
		}
		
		if (selectedTab === "edit") {
			return (
				<BatteryEdit
					baseURLAPI={this.props.baseURLAPI}
					managementBaseURLAPI={this.props.managementBaseURLAPI}
					sailfinAppUserType={this.props.sailfinAppUserType}
					batteryID={this.state.batteryID}
					batteryName={this.state.batteryName}
					batteryMacAddress={this.state.batteryMacAddress}
					companyID={this.state.companyID}
					directCool={this.state.directCool}
					multiLog={this.state.multiLog}
					escSoftwareVersion={this.state.escSoftwareVersion}
					microSoftwareVersion={this.state.microSoftwareVersion}
					lastServiceUnixTimeSec={this.state.lastServiceUnixTimeSec}
					notes={this.state.notes}
					testedByUserID={this.state.testedByUserID}
					testedByFullName={this.state.testedByFullName}
					batteryMadeUnixTimeSec={this.state.batteryMadeUnixTimeSec}
					batterySoldUnixTimeSec={this.state.batterySoldUnixTimeSec}
					batteryMadeByUserID={this.state.batteryMadeByUserID}
					batteryMadeByFullName={this.state.batteryMadeByFullName}
					nfcID={this.state.nfcID}
					screwType={this.state.screwType}
					firestop={this.state.firestop}
					bmsVersion={this.state.bmsVersion}
					esp={this.state.esp}
					diff={this.state.diff}
					bimetal={this.state.bimetal}
					testHighCurrent={this.state.testHighCurrent}
					testCage={this.state.testCage}
					testTemperature={this.state.testTemperature}
					testPool={this.state.testPool}
					testPressure={this.state.testPressure}
					rent={this.state.rent}
					setSelectedTab={this.setSelectedTab}
					serverGetBatteryData={this.serverGetBatteryData}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
				/>
			);
		}

		if (selectedTab === "newcomment") {
			return(
				<BatteryNewComment
					baseURLAPI={this.props.baseURLAPI}
					batteryID={this.state.batteryID}
					batteryName={this.state.batteryName}
					userID={this.props.userID}
					firstName={this.props.firstName}
					lastName={this.props.lastName}
					email={this.props.email}
					setSelectedTab={this.setSelectedTab}
					showToast={this.props.showToast}
					getLang={this.props.getLang}
				/>
			);
		}		
	}

	render() {

		var directLinkYN = this.props.directLinkYN;

		return (
			<div>
				{!directLinkYN && (
					<BatteryMainToolbar
						sailfinAppUserType={this.props.sailfinAppUserType}
						batteryID={this.state.batteryID}
						batteryName={this.state.batteryName}
						companyIDInit={this.props.companyIDInit}
						selectedTab={this.state.selectedTab}
						setSelectedTab={this.setSelectedTab}
						getLang={this.props.getLang}
					/>
				)}
				{this.renderTab()}
			</div>
		);
	}
}

export default BatteryMain;
