import React  from 'react';
import './App.css';
import BoardLang from './BoardLang.json';

import { useNavigate } from "react-router-dom";

class BoardRideList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			rideListJsonObj: null,
			index: 0,
			count: 10,
		};

		this.twoDigits = this.twoDigits.bind(this);
		this.getTimeStringFromSeconds = this.getTimeStringFromSeconds.bind(this);
		this.serverGetRideList = this.serverGetRideList.bind(this);
		this.handleSelectRide = this.handleSelectRide.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);	
		this.renderOneRide = this.renderOneRide.bind(this);
		this.renderAllRides = this.renderAllRides.bind(this);
		this.renderResults = this.renderResults.bind(this);
	}
	
	componentDidMount() {
		var boardID = this.props.boardID;
		this.serverGetRideList(boardID, 0);
	}

	twoDigits(value1) {
		if (value1 < 10) {
			return "0" + value1;
		} else {
			return value1;
		}
	}

	getTimeStringFromSeconds(seconds) {

		var hours;
		var minutes;
		var secondsLeft;
		var timeString = "";
	
		hours = Math.floor(seconds / 3600);
		secondsLeft = seconds - hours * 3600;

		minutes = Math.floor(secondsLeft / 60);
		seconds = secondsLeft - minutes * 60;
	
		if (hours > 0) {
			timeString = hours + ":";
		}
	
		timeString = timeString + this.twoDigits(minutes) + ":" + this.twoDigits(seconds);
		return timeString;
	}
	
	async serverGetRideList(boardID, index) {
		
		if (boardID <= 0) {
			return;
		}

		var companyID = this.props.companyID;
		var baseURLAPI = this.props.baseURLAPI;
		var url = baseURLAPI + "/getridelist?boardid=" + boardID;
		url = url + "&company=" + companyID;
		url = url + "&sortby=rideID&sortasc=0";
		url = url + "&index=" + index + "&count=" + this.state.count;
		url = url + "&durationminsec=0";
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		this.setState({
			rideListJsonObj: jsonObj,
		});
	}
		
	handleSelectRide(rideID) {
		this.props.setRideID(rideID);
		const navigate = this.props.navigate;
  		navigate("/ride");
	}

	handlePrev() {
		var index = this.state.index - 10;
		this.setState({index: index});
		var boardID = this.props.boardID;
		this.serverGetRideList(boardID, index);
	}

	handleNext() {
		var index = this.state.index + 10;
		this.setState({index: index});
		var boardID = this.props.boardID;
		this.serverGetRideList(boardID, index);
	}

	renderOneRide(itemObj) {

		var rideID = itemObj.rideID;
		var uploadedUnixTimeSec = itemObj.uploadedUnixTimeSec;
		var durationSec = itemObj.durationSec;

		var uploadedTimeString = this.props.getDateTimeStringFromUnixTimeSec(uploadedUnixTimeSec);
		var durationSecString = this.getTimeStringFromSeconds(durationSec);

		return (
			<tr key={rideID}>
				<td
					className="BoardRideListItemRideID"
					onClick={() => this.handleSelectRide(rideID)}
				>
					{rideID}
				</td>
				<td
					className="BoardRideListItemUploadedTimestamp"
					onClick={() => this.handleSelectRide(rideID)}
				>
					{uploadedTimeString}
				</td>
				<td
					className="BoardRideListItemDuration"
					onClick={() => this.handleSelectRide(rideID)}
				>
					{durationSecString}
				</td>
			</tr>
		);
	}

	renderAllRides() {

		var i;
		var rideListJsonObj = this.state.rideListJsonObj;
		var itemObj;
		var jsxArray = [];

		for(i=0; i<rideListJsonObj.length; i++) {
			itemObj = rideListJsonObj[i];
			jsxArray.push(this.renderOneRide(itemObj));
		}

		return (
			<table className="ListContainer2">
				<tbody>
					<tr>
						<td className="BoardRideListHeaderRideID">{this.props.getLang(BoardLang, "rideID")}</td>
						<td className="BoardRideListHeaderUploadedTime">{this.props.getLang(BoardLang, "uploadedTime")}</td>
						<td className="BoardRideListHeaderDuration">{this.props.getLang(BoardLang, "duration")}</td>
					</tr>
					{jsxArray}
				</tbody>
			</table>
		);
	}
	
	renderResults() {

		var rideCount = 0;
		var rideListJsonObj = this.state.rideListJsonObj;
		
		if (rideListJsonObj !== null) {
			rideCount = rideListJsonObj.length;
		}
		if (rideCount === 0) {
			return (<div>No rides found</div>);
		}

		var prevYN = false;
		var nextYN = false;
		
		if (this.state.index > 0) {
			prevYN = true;
		}
		
		if (rideCount === this.state.count) {
			nextYN = true;
		}

		return (
			<div>
				{this.renderAllRides()}
				<div style={{height:"30px"}}></div>
				<div className="ListContainer3">
					{prevYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handlePrev()}
					>
						&lt;&lt; Previous
					</div>)}
					{!prevYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						&lt;&lt; Previous
					</div>)}
					<div className="ListPrevNextLinkDisabled">|</div>
					{nextYN && (<div 
						className="ListPrevNextLink"
						onClick={() => this.handleNext()}
					>
						Next &gt;&gt;
					</div>)}
					{!nextYN && (<div 
						className="ListPrevNextLinkDisabled"
					>
						Next &gt;&gt;
					</div>)}
				</div>
			</div>
		);
	}

	render() {		

		var boardNumber = this.props.boardNumber;
		
		return (
			<div>
				<p><b className="BoardInfoTitle">{boardNumber} - {this.props.getLang(BoardLang, "boardRides")}</b></p>
				<div style={{height: "20px"}}></div>
				{this.renderResults()}
			</div>
		);
	}
}

export default function BoardRideListFunction(props) {
	const navigate = useNavigate();
	return <BoardRideList {...props} navigate={navigate} />;
}

